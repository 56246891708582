import React, { useEffect, useRef, useState } from 'react';
import { MdPause, MdPlayArrow, MdAdd, MdRemove, MdFastRewind, MdFastForward, MdVolumeUp, MdVolumeDown, MdVolumeMute, MdVolumeOff, MdPictureInPictureAlt, MdFullscreenExit, MdFullscreen, MdErrorOutline, MdClose, MdFlipToBack, MdLoop, MdSettings, MdFileDownload } from 'react-icons/md'
import PropTypes from 'prop-types';
import './Video.css'
import moment from "moment"
import { addStartItemAPI } from '../../API/AddStartItemAPI'
import { addEndItemAPI } from '../../API/AddEndItemAPI'
import Tooltip from '@material-ui/core/Tooltip';
import Hls, { HlsConfig } from 'hls.js';
import useWindowSize from 'components/useWindowSize';
import { isMobile } from 'react-device-detect';


export default function ReactHlsPlayer({
  hlsConfig,
  playerRef = React.createRef(),
  src,
  idEpisode,
  idItem,
  user,
  autoPlay,
  start,
  ...props
}) {
  const [playing, setplaying] = useState(false)

  let userInactivityTimeOut = null;
  const [isActive, setActive] = useState(true);
  const div = useRef(null)
  const sect = useRef(null)
  const vdiv = useRef(null)
  const [error, seterror] = useState(false)
  const [y, sety] = useState(false)
  const [x, setx] = useState(false)
  const [on, seton] = useState(false)
  const [loaded, setloaded] = useState(true)
  const [fulls, setfulls] = useState(false)
  const [mute, setmute] = useState(false)
  const [more, setmore] = useState(false)
  const [ct, setcurrenttime] = useState('00:00')
  const [ctt, setctt] = useState('00:00')
  const timeRef = useRef();
  const { width, height } = useWindowSize();
  const [volume, setVolume] = useState(localStorage.getItem('volume') ? parseFloat(localStorage.getItem('volume')) : 1);
  const [timer, setTimer] = useState();
  const [idSession, setIdSession] = useState(0);
  const idRef = useRef();
  const mm = () => {
    setmore(!more)
  }

  timeRef.current = ct;
  idRef.current = idSession;

  function resetUserActivityTimeOut() {
    setActive(true);
    clearTimeout(userInactivityTimeOut);
    userInactivityTimeOut = setTimeout(() => {
      inactivityFunction();
    }, 3000)
  }

  function inactivityFunction() {
    setActive(false);
  }

  function activateActivityTracker() {
    window.addEventListener("mousemove", resetUserActivityTimeOut);
    window.addEventListener("click", resetUserActivityTimeOut);
    window.addEventListener("keydown", resetUserActivityTimeOut);
    window.addEventListener("resize", resetUserActivityTimeOut);
  }

  useEffect(() => {
    activateActivityTracker();
  }, [])

  function va(e) {
    const x = e.nativeEvent.layerX
    const { offsetWidth } = vdiv.current

    let time = (x / offsetWidth) * 1
    setVolume(time);
    playerRef.current.volume = time;
    localStorage.setItem('volume', time.toString());
  }
  function foward(e) {
    let x = 0.025 * playerRef.current.duration
    playerRef.current.currentTime += x
    if (props.onFoward) {
      props.onFoward()
    }

  }
  function rewind(e) {
    let x = 0.05 * playerRef.current.currentTime
    playerRef.current.currentTime -= x
    if (props.onRewind) {
      props.onRewind()
    }

  }

  function onSeek(e) {
    const x = e.nativeEvent.layerX
    const { offsetWidth } = div.current
    const { duration } = playerRef.current

    let time = (x / offsetWidth) * duration
    playerRef.current.currentTime = time

    if (props.onSeek) {
      props.onSeek()
    }

  }
  function onMove(e) {
    const x = e.nativeEvent.layerX
    const { offsetWidth } = div.current
    const { duration } = playerRef.current

    let time = (x / offsetWidth) * duration
    setctt(calcTime(time))

    if (props.onSeek) {
      props.onSeek()
    }

  }
  function addp() {
    if (playerRef.current.playbackRate < 16) {
      playerRef.current.playbackRate += 1
    }
  }
  function minusp() {
    if (playerRef.current.playbackRate > 0) {
      playerRef.current.playbackRate -= 1
    }
  }
  function TimeUpdate(e) {
    const { currentTime, duration } = playerRef.current
    setcurrenttime(calcTime(currentTime))
    if (props.onTimeUpdate) {
      props.onTimeUpdate(e, currentTime, duration)
    }
  }
  async function Mute(e) {
    setmute(!mute)
    if (playerRef.current.volume > 0) {
      playerRef.current.volume = 0
      setVolume(0)
    } else {
      if (localStorage.getItem('volume')) {
        const volume = parseFloat(localStorage.getItem('volume'));
        playerRef.current.volume = volume;
        setVolume(volume);
      } else {
        playerRef.current.volume = 1;
        setVolume(1);  
      }
    }
    if (props.onMute) {
      props.onMute(mute)
    }

  }

  function calcTime(d) {
    if (isNaN(d)) {
      return '00:00';
    }
    let hours = d / 3600;
    let hh = d % 3600;
    let time = hh / 60;
    let h = Math.floor(hours);
    let f = Math.floor(time);
    let seconds = d % 60;
    seconds = Math.floor(seconds);
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    if (f < 10) {
      f = `0${f}`;
    }
    if (h <= 0) {
      h = ``;
    } else if (h < 10 && h > 0) {
      h = `0${h}:`;
    } else {
      h = `${h}:`;
    }

    return `${h}${f}:${seconds}`;
  }
  function pp() {
    playerRef.current.requestPictureInPicture()
    if (props.onRequestPictureInPicture) {
      props.onRequestPictureInPicture()
    }
  }

  function setClipboard(text) {
    navigator.clipboard.writeText(text).then(function () {
    }, function () {
      // eslint-disable-next-line no-restricted-globals
      alert('failed to copy url')
    });
  }


  const play = (e) => {

    playerRef.current.play()
    setplaying(true)

    if (props.onPlay) {
      props.onPlay(e)
    }
  }
  const pause = (e) => {
    playerRef.current.pause()
    setplaying(false)
    if (props.onPause) {
      props.onPause(e)
    }

  }
  function contextMenu(e) {
    const { clientY, clientX } = e.nativeEvent
    setx(clientX)
    sety(clientY)
    seton(true)
  }
  const enterFullScreen = (e) => {
    const isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox) {
      sect.current.mozRequestFullScreen()
    } else {
      sect.current.webkitRequestFullscreen()
    }

   //sect.requestFullscreen();
    if (props.onEnterFullScreen) {
      props.onEnterFullScreen(e)
    }
    setfulls(true)

  }
  const exitFullScreen = () => {
    const isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox) {
      sect.current.ownerDocument.mozCancelFullScreen()
    } else {
      sect.current.ownerDocument.webkitExitFullscreen()
    }

  //sect.exitFullScreen();
    setfulls(false)

  }
  function handleClose() {
    seton(false)
  }

  const updateWatchTime = () => {
    setTimer(setTimeout(() => {
      const hour = moment().format('HH:mm:ss');
      let time = timeRef.current;
      const idS = idRef.current;
      if (time.length < 8) {
        time = "00:" + time;
      }
      const fetchData = async () => {
        if (idItem != 0 || idEpisode != 0) {
          const endTime = await addEndItemAPI(user.accessToken, idS, time, hour);
        }
        //   console.log("id session lecture : "+startTime.data.id)

      }
      fetchData().catch(
        function (error) {
          // console.log(error.response);
          if (error.response) {
            if (error.response.status == 401) {
              //   signOut()
            }
            if (error.response.status == 402) {
              //    signOut()
            }
            if (error.response.status == 403) {
              //   signOut()
            }
          } else {
            console.log('Error', error.message);
          }
        })
      updateWatchTime();
    }, 30000))
  }

  useEffect(() => {
    return () => clearTimeout(timer);
  }, [timer])

  useEffect(() => {
    let hls;

    function _initPlayer() {
      if (hls != null) {
        hls.destroy();
      }

      playerRef.current.controls = false;

      const newHls = new Hls({
        enableWorker: false,
        ...hlsConfig,
      });

      if (playerRef.current != null) {
        newHls.attachMedia(playerRef.current);
      }

      newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
        newHls.loadSource(src);

        newHls.on(Hls.Events.MANIFEST_PARSED, () => {
          if (autoPlay) {
            playerRef?.current
              ?.play()
              .catch(() =>
                console.log(
                  'Unable to autoplay prior to user interaction with the dom.'
                )
              );
          }
        });
      });
      if (localStorage.getItem('volume')) {
        const volume = parseFloat(localStorage.getItem('volume'));
        setVolume(volume);
        playerRef.current.volume = volume;
      } else {
        setVolume(playerRef.current.volume);
      }
      newHls.on(Hls.Events.ERROR, function (event, data) {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              newHls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              newHls.recoverMediaError();
              break;
            default:
              _initPlayer();
              break;
          }
        }
      });

      hls = newHls;
    }
    let timeStart = 0;
    if (start != 0) {
      setTimeout(() => {
        playerRef.current.currentTime = start;
        setcurrenttime(calcTime(start));  
      })
      timeStart = moment.unix(start).utc().format('HH:mm:ss');
    }
    var hour = moment().format('HH:mm:ss');
    const fetchData = async () => {
      if (idItem != 0 || idEpisode != 0) {
        const startTime = await addStartItemAPI(user.accessToken, user.id, idItem, idEpisode, timeStart, hour);
        setIdSession(startTime.data.id);
        updateWatchTime();
        //   console.log("id session lecture : "+startTime.data.id)
      }
    }
    fetchData().catch(
      function (error) {
        // console.log(error.response);
        if (error.response) {
          if (error.response.status == 401) {
            //   signOut()
          }
          if (error.response.status == 402) {
            //    signOut()
          }
          if (error.response.status == 403) {
            //   signOut()
          }
        } else {
          console.log('Error', error.message);
        }
      })
    // Check for Media Source support
    if (Hls.isSupported() && !isMobile) {
      _initPlayer();
    }

    return () => {
      //let endTime = calcTime(playerRef.current.currentTime);
      clearTimeout(timer);
      const hour = moment().format('HH:mm:ss');
      let time = timeRef.current;
      const idS = idRef.current;
      if (time.length < 8) {
        time = "00:" + time;
      }
      const fetchData = async () => {
        if (idItem != 0 || idEpisode != 0) {
          const endTime = await addEndItemAPI(user.accessToken, idS, time, hour);
        }
        //   console.log("id session lecture : "+startTime.data.id)

      }
      fetchData().catch(
        function (error) {
          // console.log(error.response);
          if (error.response) {
            if (error.response.status == 401) {
              //   signOut()
            }
            if (error.response.status == 402) {
              //    signOut()
            }
            if (error.response.status == 403) {
              //   signOut()
            }
          } else {
            console.log('Error', error.message);
          }
        })
      if (hls != null) {
        hls.destroy();
      }
    };
  }, [autoPlay, hlsConfig, playerRef, src]);

  // If Media Source is supported, use HLS.js to play video
  if (Hls.isSupported() && !isMobile) return (


    <div>
      <style jsx="true">{`
      .video-react-pause:hover,
      .video-react-play:hover,
      .video-react-volume:hover,
      .video-react-rewind:hover,
      .video-react-more:hover,
      .video-react-fullscreen:hover,
      .video-react-forward:hover {
          color: ${props.primaryColor};
      }
      .finnished {
          background-color: ${props.primaryColor} !important;
      }
      .point {
          background-color: ${props.primaryColor} !important;
      }
  `}</style>
      <section style={{ width: width, height: height, alignItems:'center', overflow: "hidden" }} onContextMenu={(e) => {
        e.preventDefault()
        contextMenu(e)
      }} onBlur={() => {
        handleClose()
      }} className={`one___flkjsjJJNJnn_nANN8hG_YG7GY7g7BH9 ${props.className}`} ref={sect} >

        <video style={{display: 'flex',  justifyContent:'center', alignItems:'center', position:'relative', zIndex:2}} onError={() => {
          seterror(true)
        }} ref={playerRef}  {...props} onPause={() => {
          setplaying(false)
        }} onPlay={() => {
          setplaying(true)
        }} className='video-react' onTimeUpdate={(e) => {
          TimeUpdate(e)
        }} controlsList="nodownload" >
          {/* <source src={props.src} type={props.type ? props.type : "video/mp4"} /> */}
        </video>
        {playerRef.current ? <>
          {playerRef.current.seeking ?
            <div className="video-react-loading"></div> : <></>}</> : <></>}
        {isActive ?
          <div className="video-react-lower-bar_dhhiahhbhhbhb3767d7637____u" style={{position:'absolute', marginBottom:30, zIndex:6}}>
            <Tooltip title={ctt} aria-label="add" placement="top" ><div className="hundred"><div className="progress-video-react" ref={div} onMouseMove={(e) => {
              onMove(e)
            }} onClick={onSeek} >
              <div
                className="finnished"
                style={playerRef.current ? { width: `${(playerRef.current.currentTime / playerRef.current.duration) * 100}%`, background: props.primaryColor ? props.primaryColor : '' } : { width: 0 }} ><div className="point"></div></div>

            </div></div></Tooltip>
            <div className="time-stamps">
              <div className="current">{ct}</div>
              <div className="fullstime">{playerRef.current ? calcTime(playerRef.current.duration) : <>--:--</>}</div>
            </div>
            <div className="video-react-controls" >
              <Tooltip title="Volume" aria-label="add" placement="top" ><div className="video-react-volume"><div className="volume-add">

                <div className="volume-div" ref={vdiv} onClick={va} >
                  <div className="finnished" style={playerRef.current ? { width: `${(volume / 1) * 100}%` } : { width: 0 }}><div className="point"></div></div>
                </div></div>{!playerRef.current ? <MdVolumeUp /> :
                  playerRef.current.volume === 0 ?
                  <MdVolumeOff onClick={Mute} /> : playerRef.current.volume < 0.3 ? 
                  <MdVolumeMute onClick={Mute} /> : playerRef.current.volume < 0.7 ? 
                  <MdVolumeDown onClick={Mute} /> : <MdVolumeUp onClick={Mute} />}</div></Tooltip>
              <div className="video-react-pro"></div>
              <Tooltip title="Reculer" aria-label="add" placement="top" ><div className="video-react-rewind" onClick={rewind}><MdFastRewind /></div></Tooltip>
              {playing ? <Tooltip title="Pause" aria-label="add" placement="top" ><div className="video-react-pause" onClick={pause}><MdPause /></div></Tooltip> :
                <Tooltip title="Lecture" aria-label="add" placement="top" ><div className="video-react-play" onClick={play}><MdPlayArrow /></div></Tooltip>
              }

              <Tooltip title="Avancer" aria-label="add" placement="top" ><div className="video-react-forward" onClick={foward}><MdFastForward /></div></Tooltip>
              <div className="video-react-pro"></div>


              <Tooltip title="Plein écran" aria-label="add" placement="top" >{fulls ? <div className="video-react-fullscreen" onClick={exitFullScreen}><MdFullscreenExit /></div> : <div className="video-react-fullscreen" onClick={enterFullScreen}><MdFullscreen /></div>}</Tooltip>
              {/* <Tooltip arrow title="Settings" aria-label="add" placement="left" ><div className="video-react-more" ><div style={more ? {
                  transform: 'scale(1)',
                  opacity: 1
              } : {}} className="video-react-menu">
                  <a download='video' href={props.src} className="list-" onClick={pp} >
                      <span className="icon"><MdFileDownload /></span>
                      <span className="text">Download</span>
                  </a>
                  <div className="list-" onClick={pp} >
                      <span className="icon"><MdPictureInPictureAlt /></span>
                      <span className="text">Picture In Picture</span>
                  </div>
                  <Tooltip arrow title="Playback speed" aria-label="add" placement="left" ><div className="list-1">
                      <span className="icon" onClick={minusp} style={video.current ? video.current.playbackRate === 0 ?
                          { cursor: 'not-allowed' } : {} : {}
                      }><MdRemove /></span>
                      <span className="text">{video.current ? video.current.playbackRate : 1}</span>
                      <span className="icon" onClick={addp}  ><MdAdd /></span>
                  </div></Tooltip>
              </div><MdSettings style={more ? { transform: 'rotate(40deg)', transition: 'all 0.2s' } : { transition: 'all 0.2s' }} onContextMenu={(e) => {
                  e.preventDefault()
              }} onClick={mm} /></div></Tooltip> */}

            </div>
          
          
          
          
          
          
          
          
          
          
          
          
          </div> : (
            <div></div>
          )}

        <div className="video-react-error_12ede3ws3" style={error ? { opacity: 1 } : {}}>
          <span><MdErrorOutline /></span> <span><b>Erreur:</b> Impossible de charger la vidéo</span>
          <span className="cancel" onClick={() => {
            seterror(false)
          }}>
            <MdClose />
          </span>
        </div>


        {on ?
          <div className="menu-c" onClick={() => {
            handleClose()
          }}>
            <div className="menu-contxt" onClick={() => {
              handleClose()
            }}>
              <div className="video-rect-context" style={{ top: y, left: x }}>
                <ul className="context-list">
                  {playing ?
                    <li className="play" onClick={pause} ><span className="i"><MdPause /></span><span className="t">Pause</span></li> : <li className="play" onClick={play} ><span className="i"><MdPlayArrow /></span><span className="t">Play</span></li>}

                  {playerRef.current ? <>{playerRef.current.loop ? <li onClick={() => {
                    playerRef.current.loop = false
                  }} ><span className="i"><MdLoop /></span><span className="t">Arrêt répétition</span></li> : <li onClick={() => {
                    playerRef.current.loop = true
                  }} ><span className="i"><MdLoop /></span><span className="t">Répéter</span></li>
                  }</> : <></>}
                </ul>
              </div></div></div> : <></>}
        {/* {playing === false && loaded === true ? <div className="poster">
          <div>
              <img src={props.poster} alt="video poster" />
              <div style={props.primaryColor ? { background: props.primaryColor } : {}} onClick={() => {
                  play()
                  setloaded(false)
              }}><MdPlayArrow /></div>
          </div>
      </div> : <></>} */}

        {props.childern}


      </section>

    </div>

  )


  // Fallback to using a regular video player if HLS is supported by default in the user's browser
  else return <video ref={playerRef} src={src} autoPlay controls={true} controlsList="nodownload" style={{ maxWidth: "100vw" }} />;
}
ReactHlsPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  type: PropTypes.string,
  poster: PropTypes.string,
  primaryColor: PropTypes.string,
  autoPlay: PropTypes.bool,
  className: PropTypes.string,
  onFoward: PropTypes.func,
  onRewind: PropTypes.func,
  onSeek: PropTypes.func,
  onMute: PropTypes.func,
  onRequestPictureInPicture: PropTypes.func,
  onTimeUpdate: PropTypes.func,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onEnterFullScreen: PropTypes.func
}