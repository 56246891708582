import axios from "axios";
import UrlAPI from "./UrlAPI";

export function getItemsByProductor(pi_token, idProductor) {
    const url = UrlAPI.sUrl+'/item/listByProductor/'+idProductor;
    return axios(url,{
        method: 'GET',
        headers:{
            'Content-Type': "application/json",
            'x-access-token': pi_token
        },
    })
}