import { container, title } from "assets/jss/material-kit-react.js";

const landingPageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    position: "relative",
    zIndex: 3,
    margin: 0
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  description: {
    color: "#FFF",
  },
  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "inherit",
      background: "rgba(200, 200, 200, 0.2)"
    }
  },
  copyButton: {
    color: "#ff6833",
    margin: "10px",
    "&:hover,&:focus": {
      color: "#ff9833"
    }
  },
  interactButton: {
    color: "#FFF",
    transition: "all 0.1s",
    "&:hover,&:focus": {
      color: "#ff600f"
    }
  },
  interactList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%"
  },
  infoButton: {
    color: "#FFF",
    transition: "all 0.1s",
    border: "solid 2px #FFF",
    borderRadius: "50%",
    "&:hover,&:focus": {
      color: "#ff600f",
      border: "solid 2px #ff600f"
    }
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor:'transparent',
    marginLeft: -27,
    marginRight: -15
  },
  mainBackground: {
    background: "linear-gradient(135deg,#141414, #462012,#141414)"
  },
  containerElement: {
    paddingTop: 100
  },
  gridList: {
    flexWrap: 'nowrap',
    overflow: "hidden",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    paddingLeft: 5

  },
  title: {
    color: "transparent",
    fontFamily: "Bubblebody Light !important",
    backgroundImage: "linear-gradient(to top, #FF6833 0%, #ff9833 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  },
  titleBar: {
    borderRadius:12,
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  carrouselElement: {
    padding: "0 32px"
  },
  carrouselElementM: {
    padding: 0,
    minHeight: '100%',
    width: "100%"
  },
  carrouselMainDiv: {
    display: "flex",
    justifyContent: "center",
    height: "-moz-fit-content",
    maxHeight: "fit-content"
  },
  carrouselMainDivM: {
    display: "flex",
    borderRadius: 24,
    overflow: "hidden",
    width: "100%",
    maxWidth: 1400,
    padding: 0
  },
  carrouselContainer: {
    display: "flex",
    borderRadius: 24,
    overflow: "hidden",
    width: "100%"
  },
  carrouselContainerM: {
    display: "flex",
    borderRadius: 24,
    overflow: "hidden",
    width: "100%",
    maxWidth: 1400,
    padding: 0
  },
  carrouselImg: {
    borderRadius: 24,
    zIndex: 9
  },
  carrouselDivM: {
    height: "100%"
  },
  carrouselBackground: {
    width: "55%",
    height: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    paddingLeft: 54,
    marginLeft: -24,
    paddingRight: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  },
  carrouselBackgroundM: {
    width: "100%",
    height: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    padding: "30px 20px 10px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  carrousel__link: {
    textDecoration: "none",
    width: '50%',
    borderRadius: 24,
    zIndex: 9
  },
  link: {
    textDecoration: "none"
  },
  logoDivCarrousel: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  titleCarrousel: {
    color: "#FFF",
    fontSize: 18
  },
  logoCarrousel: {
    marginTop: -30
  },
  carrouselInfoContainer: {
    display: "flex"
  },
  carrouselDesc: {
    width: "66%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  carrouselDescM: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  carrouselDescTextB: {
    color: "#FFF",
    textAlign: "justify",
    fontSize: 24,
    margin: 0
  },
  carrouselDescText: {
    color: "#FFF",
    textAlign: "justify",
    fontSize: 18,
    margin: 0
  },
  carrouselDescTextM: {
    color: "#FFF",
    textAlign: "justify",
    fontSize: 12,
    margin: 0
  },
  carrouselInfo: {
    width: '34%',
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  carrouselInfoM: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
    "& h6": {
      color: "white",
      backgroundColor: "#ff6833",
      padding: "5px 15px",
      borderRadius: 20,
      margin: 0,
      fontSize: 14,
      maxHeight: 33.2,
      whiteSpace: "nowrap",
      width: "fit-content",
      textTransform: "lowercase"
    },
    "& img": {
      height: "fit-content",
      marginLeft: 20
    }
  },
  categoryItem: {
    width: "125px !important",
    height: "125px !important",
    borderRadius: 12,
    marginTop: 10,
    marginLeft: 5,
    display: "flex"
  },
  categoryItemM: {
    width: "83px !important",
    height: "83px !important",
    borderRadius: 12,
    marginTop: 10,
    marginLeft: 5,
    display: "flex"
  },
  categoryImg: {
    borderRadius: 12
  },
  categoryLogoDiv: {
    height: 120,
    display: "grid",
    alignContent: "center",
    position: "relative",
    top: 0,
    right: 120
  },
  categoryLogoDivM: {
    height: 80,
    display: "grid",
    alignContent: "center",
    position: "relative",
    top: 0,
    right: 80
  },
  carrouselButtonDiv: {
    display: "flex",
    justifyContent: "space-between"
  },
  carrouselButtonDivM: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 20
  },
  linkButtonM: {
    textDecoration: "none",
    alignSelf: "center"
  },
  carrouselRightButton: {
    display: "flex",
    justifyContent: "space-between",
    width: "50%",
    maxWidth: 200,
    alignItems: "center"
  },
  carrouselRightButtonM: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%"
  },
  listButton: {
    display: "flex",
    marginTop: 15,
    alignItems: "center",
    "& div": {
      display: "flex",
      flexDirection: "column",
      marginRight: 10,
      marginTop: 5
    }
  },
  listButtonM: {
    display: "flex",
    flexDirection: "column",
    "& div": {
      display: "flex",
      flexDirection: "column",
      marginRight: 10
    }
  },
  listButtonDetail: {
    display: "flex",
    flexDirection: "column",
    marginTop: 10,
    "& p": {
      alignSelf: "center",
      whiteSpace: "nowrap",
      marginBottom: 0
    }
  },
  listButtonDetailM: {
    display: "flex",
    flexDirection: "column",
    "& p": {
      alignSelf: "center",
      whiteSpace: "nowrap",
      marginBottom: -3,
      fontSize: 14,
      marginTop: 7
    }
  },
  listIcon: {
    alignSelf: "center"
  },
  checkIcon: {
    alignSelf: "flex-end"
  },
  addIcon: {
    alignSelf: "flex-end",
    marginRight: -10,
    marginTop: 3
  },
  addIconM: {
    alignSelf: "flex-end",
    marginRight: -10
  },
  addListText: {
    alignSelf: "center",
    whiteSpace: "nowrap",
    marginBottom: 10,
    marginLeft: 5
  },
  interactText: {
    marginTop: 5,
    marginBottom: 0
  },
  info: {
    width: 35,
    height: 35
  },
  infoM: {
    width: 30
  },
  listItem: {
    padding: "16px 60px 20px 67px"
  },
  listItemM: {
    padding: "0 5px 20px 0"
  },
  bannerItem: {
    marginTop: 15
  },
  bannerContainer: {
    margin: "15px 0 0 12px",
    paddingBottom: 50
  },
  bannerContainerM: {
    margin: 0,
    paddingBottom: 20
  },
  departementImg: {
    borderRadius: "0 !important"
  },
  defaultImgPicture: {
    position: "relative"
  },
  defaultImgDiv: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    padding: "0 2.5%",
    display: "grid",
    alignItems: "center",
    justifyContent: "center"
  },
  defaultImgText: {
    color: "#FFF",
    fontSize: 20,
    fontFamily: "Bubblebody Light",
    textAlign: "center"
  },
  loading: {
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    height: '100vh',
    "& div": {
      color: "#ff600f"
    }
  },
  themeElement: {
    padding: "8px !important",
    "& a": {
      margin: 8,
      textDecoration: "none"
    }
  },
  departementElement: {
    padding: "12px !important",
    "& a": {
      textDecoration: "none"
    }
  },
  departementDiv: {
    width: 'fit-content',
    height: 'fit-content',
    padding: "0 20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  departmentDiv2: {
    width: 'fit-content',
    height: 'fit-content',
    padding: "0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  departementText: {
    color: '#FFF',
    margin: "15px 5px 0 0",
    textAlign: "center"
  },
  genreLib: {
    color: "#FFF",
    backgroundColor: "#ff6833",
    padding: "3px 7px",
    borderRadius: "0 12px 0 12px",
    marginRight: 10,
    fontSize: 15,
    position: "absolute",
    whiteSpace: "nowrap",
    bottom: 16,
    left: 0
  },
  nCGenreLib: {
    color: "#FFF",
    backgroundColor: "#ff6833",
    padding: "3px 7px",
    borderRadius: "0 12px 0 12px",
    marginRight: 10,
    fontSize: 15,
    position: "absolute",
    whiteSpace: "nowrap",
    marginBottom: 0,
    bottom: 0,
    left: 0
  },
  exclusif: {
    width: "100%",
    position: 'absolute',
    display: "flex",
    justifyContent: "flex-end",
    top: 24,
    borderRadius: "12px 12px 0 0",
    paddingBottom: 10,
    "& img": {
      borderRadius: "0 !important",
      margin: "5px 5px 0 0"
    }
  },
  nCExclusif: {
    width: "100%",
    position: 'absolute',
    display: "flex",
    justifyContent: "flex-end",
    top: 0,
    borderRadius: "12px 12px 0 0",
    paddingBottom: 10,
    "& img": {
      borderRadius: "0 !important",
      margin: "5px 5px 0 0"
    }
  },
  newEpisode: {
    height: 25,
    background: "rgba(185,185,185,0.7)",
    position: "absolute",
    top: 24,
    left: 0,
    display: "flex",
    alignItems: "center",
    borderRadius: "12px 0 12px 0",
    "& h6": {
      margin: "0 10px",
      color: "#FFF"
    }
  },
  nCNewEpisode: {
    height: 25,
    background: "rgba(185,185,185,0.7)",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    borderRadius: "12px 0 12px 0",
    "& h6": {
      margin: "0 10px",
      color: "#FFF"
    }
  },
  newEpisodeCarrousel: {
    height: 35,
    background: "rgba(185,185,185,0.7)",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    borderRadius: "24px 0 24px 0",
    zIndex: 12,
    pointerEvents: "none",
    "& h6": {
      margin: "0 10px",
      color: "#FFF"
    }
  },
  watchContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: 12,
    overflow: "hidden",
    display: "flex",
    alignItems: "end",
    bottom: 24,
    "& div": {
      width: "100%",
      height: 5,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      overflow: "hidden",
      "& div": {
        borderBottomLeftRadius: "12px",
        backgroundColor: "rgba(255, 104, 55, 0.7)",
        height: "100%"
      }
    }
  },
  nCWatchContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: 12,
    overflow: "hidden",
    display: "flex",
    alignItems: "end",
    bottom: 0,
    "& div": {
      width: "100%",
      height: 5,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      overflow: "hidden",
      "& div": {
        borderBottomLeftRadius: "12px",
        backgroundColor: "rgba(255, 104, 55, 0.7)",
        height: "100%"
      }
    }
  },
  watchContainer2: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: 15,
    overflow: "hidden",
    display: "flex",
    alignItems: "end",
    bottom: 0,
    "& div": {
      width: "100%",
      height: 5,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      overflow: "hidden",
      "& div": {
        borderBottomLeftRadius: "12px",
        backgroundColor: "rgba(255, 104, 55, 0.7)",
        height: "100%"
      }
    }
  },
  watchContainerCarrousel: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: 24,
    overflow: "hidden",
    display: "flex",
    alignItems: "end",
    bottom: 0,
    zIndex: 13,
    pointerEvents: "none",
    "& div": {
      width: "100%",
      height: 5,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      overflow: "hidden",
      "& div": {
        borderBottomLeftRadius: "12px",
        backgroundColor: "rgba(255, 104, 55, 0.7)",
        height: "100%"
      }
    }
  },
  freeElement: {
    position: "absolute",
    top: 24,
    left: 0,
    display: "flex",
    alignItems: "center",
    "& img": {
      marginTop: 5,
      marginLeft: 5
    }
  },
  nCFreeElement: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    "& img": {
      marginTop: 5,
      marginLeft: 5
    }
  },
  freeElementCarrousel: {
    color: "#ff6833",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    zIndex: 10,
    pointerEvents: "none",
    "& i": {
      marginTop: 25,
      marginLeft: 10
    }
  },
  freeElementCarrouselMobile: {
    color: "#ff6833",
    marginLeft: 15
  },
  genreLibCarrousel: {
    color: "#FFF",
    backgroundColor: "#ff6833",
    padding: "6px 16px",
    borderRadius: "0 24px 0 24px",
    margin: 0,
    fontSize: 15,
    position: "absolute",
    whiteSpace: "nowrap",
    top: 0,
    right: 0,
    pointerEvents: "none",
    zIndex: 15
  },
  genreLibCarrouselM: {
    color: "#FFF",
    backgroundColor: "#ff6833",
    padding: "3px 7px",
    borderRadius: "24px 0 24px 0",
    margin: 0,
    fontSize: 12,
    position: "absolute",
    whiteSpace: "nowrap",
    bottom: 0,
    left: 0,
    pointerEvents: "none",
    zIndex: 15
  },
  nonCarousel: {
    width: "fit-content !important",
    height: "100% !important",
    "& img": {
      borderRadius: 12
    }
  },
  nonCarouselElement: {
    position: "relative"
  },
  titleTextContainer: {
    width: "100%",
    paddingBottom: "56.25%",
    position: "relative",
    "& div": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& h1": {
        fontSize: "3.5rem !important",
        textAlign: "center"
      }
    }
  },
  presentationElement: {
    display: "flex",
    marginTop: 10,
    "& a": {
      margin: 8,
      textDecoration: "none"
    }
  },
  popOverElement: {
    width: 300,
    "& p": {
      margin: 10
    },
    "& input": {
      width: 280,
      margin: 10,
      padding: "2px 4px"
    }
  },
  popOverText: {
    margin: "10px !important"
  },
  playButton: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
    "& a": {
      margin: 8,
      textDecoration: "none",
      "& button": {
        fontSize: 12, height: 50
      }
    }
  },
  textFree: {
    marginBottom: 0,
    marginLeft: 8
  },
  BAContainer: {
    display: "flex",
    alignItems: "center"
  },
  BAContainerDiv: {
    width: "100%",
    overflow: "hidden",
    borderRadius: 10
  },
  blurBackground: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    margin: 0
  },
  darkBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    padding: "0 75px",
    overflow: "hidden"
  },
  darkBackgroundM: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    overflow: "hidden"
  },
  mainContainer: {
    marginRight: 0,
    marginLeft: 0,
    "& div": {
      width: "100%"
    }
  },
  tabsContainer: {
    color: "#FFF",
    paddingTop: 5
  },
  informationContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 50,
    position: "relative"
  },
  informationContainerM: {
    display: "flex",
    flexDirection: "column"
  },
  descriptionContainer: {
    width: "66% !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  descriptionContainerM: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  textDescription: {
    textAlign: "justify",
    marginRight: 10,
    whiteSpace: "pre-line"
  },
  clickableItem: {
    padding: 8,
    width: "fit-content !important",
    "& a": {
      margin: 8,
      textDecoration: "none"
    }
  },
  productorElement: {
    marginRight: 20,
    display: "flex",
    alignItems: "center",
    "& img": {
      borderRadius: "50%"
    },
    "& p": {
      marginBottom: 0,
      marginLeft: 10,
      color: "#FFF"
    }
  },
  logoContainer: {
    width: "34% !important",
    paddingLeft: 0,
    position: "absolute",
    right: 0,
    top: 0
  },
  tabLogo: {
    minWidth: "fit-content",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: 10
  },
  exclusifLogo: {
    marginRight: 20,
    alignSelf: "center"
  },
  nCFreeLogoG: {
    color: "#ff6833",
    position: "absolute",
    top: 5,
    right: 10,
    width: "fit-content !important",
    height: "fit-content !important"
  },
  freeLogoG: {
    color: "#ff6833",
    position: "absolute",
    top: 30,
    right: 10,
    width: "fit-content !important",
    height: "fit-content !important"
  },
  freeLogo: {
    color: "#ff6833",
    width: "fit-content !important",
    height: "fit-content !important",
    alignSelf: "center"
  },
  portraitLogo: {
    color: "#fff",
    marginRight: 20,
    display: "grid",
    alignContent: "center",
    width: "fit-content !important"
  },
  ageLogo: {
    marginRight: 10
  },
  timeText: {
    color: "#FFF",
    backgroundColor: "#ff6833",
    padding: "7px 15px",
    borderRadius: 20,
    marginRight: 10,
    maxHeight: 33.2,
    whiteSpace: "nowrap",
    alignSelf: "center",
    marginBottom: 0
  },
  containerLogo: {
    display: "flex",
    justifyContent: "flex-end"
  },
  genreItem: {
    marginLeft: 7,
    height: "auto !important",
    "& a": {
      textDecoration: 'none',
      maxHeight: 160
    }
  },
  containerPeopleList: {
    padding: 0
  },
  peopleItem: {
    padding: "8px !important",
    height: "240px !important",
    "& a": {
      margin: 8,
      textDecoration: "none"
    }
  },
  peopleDiv: {
    height: 240,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: 0,
    "& img": {
      borderRadius: "50%"
    },
    "& h6": {
      color: '#FFF',
      marginTop: 15,
      textAlign: 'center'
    },
    "& p": {
      color: '#FFF',
      fontSize: 12,
      marginTop: -10,
      textAlign: 'center'
    }
  },
  peopleDivM: {
    height: 240,
    marginTop: 40,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& img": {
      borderRadius: "50%"
    },
    "& h6": {
      color: '#FFF',
      marginTop: 15,
      textAlign: 'center'
    },
    "& p": {
      color: '#FFF',
      fontSize: 12,
      marginTop: -10,
      textAlign: 'center'
    }
  },
  largePeopleItem: {
    padding: "8px !important",
    width: "300px !important",
    height: "160px !important",
    "& a": {
      margin: 8,
      textDecoration: "none"
    }
  },
  largePeopleDiv: {
    width: "290px !important",
    height: "160px !important",
    display: "flex",
    alignItems: "center",
    "& img": {
      borderRadius: "50%"
    },
    "& div": {
      marginLeft: 20,
      "& h6": {
        color: '#FFF'
      },
      "& p": {
        color: '#FFF',
        fontSize: 12,
        margin: 0
      }
    }
  },
  listPeople: {
    paddingRight: 5,
    marginLeft: "40px !important",
    "& div": {
      width: "100% !important",
      margin: "0 10px"
    }
  },
  listLargePeople: {
    paddingRight: 5,
    marginLeft: "65px !important"
  },
  listLargePeopleM: {
    paddingRight: 5,
    marginLeft: "20px !important"
  },
  listGenre: {
    zIndex: 10
  },
  episodeContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.07) !important',
    marginBottom: '5px !important',
    color: '#FFF !important'
  },
  imageEpisodeElement: {
    margin: "auto !important",
    width: "fit-content !important"
  },
  imageEpisode: {
    borderRadius: 15,
    overflow: "hidden"
  },
  newEpisodeText: {
    background: "rgba(185,185,185,0.7)",
    color: "#FFF",
    padding: "5px 10px",
    width: "fit-content",
    borderTopLeftRadius: 15,
    borderBottomRightRadius: 15,
    position: "absolute",
    top: 0,
    left: 0
  },
  episodeContainerElement: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column !important"
  },
  episodeContainerDivM: {
    margin: "0 !important"
  },
  episodeTopElement: {
    display: "flex",
    justifyContent: "space-between"
  },
  titleEpisode: {
    width: "auto !important",
    fontSize: "20px !important",
    display: "flex"
  },
  horlogeLogo: {
    marginLeft: 10,
    alignSelf: "center"
  },
  timeEpisode: {
    color: "#CCCCCC",
    fontSize: "12px !important",
    width: "auto !important",
    textAlign: "end"
  },
  descriptionEpisode: {
    textAlign: "justify",
    whiteSpace: "pre-line"
  },
  linkEpisode: {
    margin: 8,
    textDecoration: "none"
  },
  textLinkEpisode: {
    color: "#FFF",
    fontSize: "12px !important"
  },
  suggList: {
    paddingLeft: 15,
    overflow: "hidden",
    paddingBottom: 20,
    maxWidth: 1140,
    margin: "0 auto !important"
  },
  suggListM: {
    paddingLeft: 0,
    overflow: "hidden",
    paddingBottom: 20,
    maxWidth: 1140,
    margin: "0 -15px !important",
    flexWrap: "wrap"
  },
  suggItem: {
    width: "fit-content !important",
    height: "fit-content !important",
    padding: "8px !important",
    marginTop: 20,
    "& a": {
      margin: 8,
      textDecoration: "none"
    }
  },
  suggItemM: {
    height: "fit-content !important",
    width: "33.33% !important",
    marginTop: 20,
    "& a": {
      margin: 8,
      textDecoration: "none"
    }
  },
  suggElement: {
    display: "flex",
    justifyContent: "center",
    width: "fit-content !important"
  },
  suggImage: {
    borderRadius: 12,
    overflow: "hidden"
  },
  replaceTextDiv1: {
    width: "100%",
    paddingBottom: "56.25%",
    position: "relative"
  },
  replaceTextDiv2: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  replaceText: {
    fontSize: "3.5rem !important",
    textAlign: "center",
    margin: 0
  }
};

export default landingPageStyle;
