import React,{useState,useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Link,
  useParams
} from "react-router-dom";
// @material-ui/icons
// core components
import { Helmet } from "react-helmet";
import Button from "components/CustomButtons/Button.js";
import useWindowSize from 'components/useWindowSize';

export default function FourOFour(props) {
  const { ...rest } = props;
  const { width, height } = useWindowSize();

  return (
    <div style={{ backgroundColor: "#1B1B1B",}}>
        <Helmet>
            <title>Erreur 404</title>
        </Helmet>
        <div style={{ background: "linear-gradient(135deg,#141414, #462012,#141414)", height: height, width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <p style={{ color: "#FFF" }}>La page que vous cherchez n'existe pas. Vérifiez que votre télécommande n'ai pas glissée dans un coin du Sofa :)</p>
        </div>
    </div>
  )
}
