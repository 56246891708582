import axios from "axios";
import UrlAPI from "./UrlAPI"

export function getItemById(pi_token, id, idUser) {
    const url = UrlAPI.sUrl+'/item/getById/' + id.toString() + '/' + idUser.toString();
    return axios(url,{
        method: 'GET',
        headers:{
            'Content-Type': "application/json",
            'x-access-token': pi_token
        },
    })

}