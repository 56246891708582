import axios from "axios";
import UrlAPI from "./UrlAPI"

export function addUserProps(pi_token, json) {
    const url = UrlAPI.sUrl+'/user/addProps';
    return axios(url,{
        method: 'POST',
        data: json,
        headers:{
            'Content-Type': "application/json",
            'x-access-token': pi_token
        },
    })

}