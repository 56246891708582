import axios from "axios";
import UrlAPI from "./UrlAPI"

export function confirmMail(idUser, email) {
    const url = 'https://test.api.sofavod.com/user/confirmEMail/' + idUser.toString() + "/" + email;
    return axios(url,{
        method: 'PUT',
        headers:{
            'Content-Type': "application/json"
        },
    })

}