import React,{useState,useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Link,
  useParams
} from "react-router-dom";
// @material-ui/icons
// core components
import Button from "components/CustomButtons/Button.js";
import useWindowSize from 'components/useWindowSize';
import { unsubscribe } from '../../API/UnsubscribeAPI';

export default function Unsubscribe(props) {
  const idUser = props.match.params.idUser;
  const email = props.match.params.email;
  const { ...rest } = props;
  const { width, height } = useWindowSize();
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [error, setError] = useState(false);

  const unsubscribeButton = () => {
      unsubscribe(idUser, email).then(
        (response) => {
          setUnsubscribed(true);
        }
      ).catch(err => {
        setError(true);
      })
  }

  return (
    <div style={{ backgroundColor: "#1B1B1B",}}>
        <div style={{ background: "linear-gradient(135deg,#141414, #462012,#141414)", height: height, width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            {unsubscribed ? <p style={{ color: "#FFF" }}>Vous avez bien été désinscrit de la newsletter.</p> : error ? <p style={{ color: "#FFF" }}>Une erreur s'est produite, merci de réessayer plus tard.</p> : 
            <Button style={{borderRadius:25}}
                color="sofa"
                size="lg"
                target="_blank"
                rel="noopener noreferrer"
                onClick={unsubscribeButton}
              >
                
               Se désinscrire de la newsletter
                  
              </Button>}
        </div>
    </div>
  )
}
