import axios from "axios"
import UrlAPI from "./UrlAPI"

export function getCategoryAll(pi_token, random) {
    const url = random ? UrlAPI.sUrl+'/item/category/all/true' : UrlAPI.sUrl+'/item/category/all'
    return axios(url,{
        method: 'GET',
        headers:{
            'Content-Type': "application/json",
            'x-access-token': pi_token
        },
    })
}