// API/upUserItemAPI.js
import axios from "axios";
import UrlAPI from "./UrlAPI"

export function upUserItem(pi_token, id) {
    
  const url = UrlAPI.sUrl+'/user/upUserItem/'+id.toString()
  return axios(url,{
    method: 'put',
    data:{
      statut:90,
    
      } ,
    headers:{
        'Content-Type': "application/json",
        'x-access-token': pi_token
    },
    })  
}