// API/LoginAPI.js
import axios from "axios";
import UrlAPI from "./UrlAPI"
import {
  osVersion,
  osName
} from "react-device-detect";

export function tokenAPI(pi_sUser, pi_sToken) {
  const url = UrlAPI.sUrl+'/auth/token'
  return axios(url,{
    method: 'post',
    data:{ id: pi_sUser, accessToken: pi_sToken, idDevice: pi_sUser + "_" + osName + " " + osVersion },
    headers:{
        'Content-Type': "application/json",
    },
    })  
    
}