import React, { useState, useEffect } from 'react';
import Header from "components/Header/Header.js";
import { makeStyles } from "@material-ui/core/styles";
import useWindowSize from 'components/useWindowSize';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Card from "components/Card/Card.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "components/CustomButtons/Button.js";
import { Link, useHistory } from 'react-router-dom';
import exclusif from "assets/img/exclusif.png"
import exclusifLarge from "assets/img/exclusif_large.png";
import sofaLogo from "assets/img/Sofa_Logo_couleur.png";
import confetti from "assets/img/Confetti.png";
import confettiAVIF from "assets/img/Confetti.avif";
import { getCategoryAll } from 'API/CategoryAPI.js';
import { getSuggestionsGenre } from 'API/GetSuggestionsGenreAPI.js';
import AuthService from "services/auth.service";
import noImageAVIF from "assets/img/Fond_lampadaire.avif";
import noImage from "assets/img/Fond_lampadaire.jpg";

import styles from "assets/jss/material-kit-react/views/loginPage.js";
import 'components/welcomeStyle.css'

const useStyles = makeStyles(styles);

export default function WelcomePage() {

    const currentUser = AuthService.getCurrentUser();
    const classes = useStyles();
    const {width, height} = useWindowSize();
    const [cardAnimaton, setCardAnimation] = useState("cardHidden");
    setTimeout(function() {
      setCardAnimation("");
    }, 700);
    const [loading, setLoading] = useState(true);
    const [tabGenre, setTabGenre] = useState([]);
    const [selectedGenre, setSelectedGenre] = useState([]);
    const [forceRerender, setForceRerender] = useState(false);
    const [step, setStep] = useState(1);
    const [tabSugg, setTabSugg] = useState([]);
    const [selectedId, setSelectedId] = useState(0);
    const [oldId, setOldId] = useState(0);
    const [transition, setTransition] = useState(true);

    let history = useHistory();

    useEffect(() => {
        const fetchData = async() => {
            if (currentUser && currentUser.accessToken) {
                const respGenre = await getCategoryAll(currentUser.accessToken, true);

                setTabGenre(respGenre.data);

                window.scrollTo({top: 0, left: 0, behavior: "instant"});

                setLoading(false);
            } else {
                history.push('/login');
            }
        }
        fetchData();
        if (sessionStorage.getItem('sId') && sessionStorage.getItem('tabSugg')) {
            setTabSugg(JSON.parse(sessionStorage.getItem('tabSugg')));
            setSelectedId(parseInt(sessionStorage.getItem('sId')));
            setOldId(parseInt(sessionStorage.getItem('sId')));
            setStep(2);
            setTransition(false);
            setTimeout(() => {
                document.getElementById("carouselImage").addEventListener("load", () => {
                    setForceRerender(!forceRerender);
                })
            }, 300)
        }
    }, [])

    const selectGenre = (e) => {
        const id = e.currentTarget.value;
        const index = selectedGenre.findIndex(x => x.id == id);
        if (index != -1) {
            let nSelectedGenre = selectedGenre;
            nSelectedGenre.splice(index, 1);
            setSelectedGenre(nSelectedGenre);
        } else if (selectedGenre.length  >= 2) {
            let nSelectedGenre = selectedGenre;
            nSelectedGenre.splice(0, 1);
            nSelectedGenre.push(tabGenre.find(x => x.id == id));
            setSelectedGenre(nSelectedGenre);
        } else {
            let nSelectedGenre = selectedGenre;
            nSelectedGenre.push(tabGenre.find(x => x.id == id));
            setSelectedGenre(nSelectedGenre);
        }
        setForceRerender(!forceRerender);
    }

    const getGridListCols = () => {
        let nWidth = width;
        if (height > 1080 && width > 1920) {
            nWidth = width * 0.9;
        } else if (width > 1200) {
            nWidth = 1140;
        }
        let nColumns;
        if (height > 1080 && width > 1920) {
            nColumns = nWidth / 160;
        } else if (width > 768) {
            nColumns = nWidth / 105;
        } else {
            nColumns = nWidth / 75;
        }
        return Math.trunc(nColumns);
    }

    // const getFilmsCols = () => {
    //     let nWidth = width;
    //     if (height > 1080 && width > 1920) {
    //         nWidth = width * 0.7;
    //     } else if (width > 1200) {
    //         nWidth = 1140;
    //     }
    //     let nColumns;
    //     if (height > 1080 && width > 1920) {
    //         nColumns = nWidth / 600;
    //     } else {
    //         nColumns = nWidth / 135;
    //     }
    //     if (nColumns > 4) {
    //         nColumns = 4;
    //     } else if (nColumns > 2 && nColumns < 4) {
    //         nColumns = 2;
    //     }
    //     return Math.trunc(nColumns);
    // }

    const sendGenre = () => {
        setLoading(true);
        const tabId = selectedGenre.map(genre => genre.id);
        const json = { genre: tabId };
        getSuggestionsGenre(currentUser.accessToken, json).then(res => {
            setLoading(false);
            setTabSugg(res.data);
            sessionStorage.setItem('tabSugg', JSON.stringify(res.data));
            setSelectedId(res.data[0].Id);
            setOldId(res.data[0].Id);
            sessionStorage.setItem('sId', res.data[0].Id.toString());
            setStep(2);
            window.scrollTo({top: 0, left: 0, behavior: "instant"});
            setTransition(false);
            document.getElementById("carouselImage").addEventListener("load", () => {
                setForceRerender(!forceRerender);
            })
        })
    }

    const selectId = (e) => {
        setOldId(selectedId);
        setTransition(true);
        const id = e.currentTarget.value;
        setTimeout(() => {
            setSelectedId(id);
            sessionStorage.setItem('sId', id);
            setTransition(false);
            setForceRerender(!forceRerender);
        }, 200)
    }

    const defaultImage = (style, width, height, title) => {
        let picture = document.createElement("picture");
        picture.setAttribute("class", "defaultImagePicture");
        let source = document.createElement("source");
        source.setAttribute("srcSet", noImageAVIF);
        source.setAttribute("type", "image/avif");
        let img = document.createElement("img");
        img.setAttribute("src", noImage);
        let jsonStyle = {};
        Object.values(JSON.parse(JSON.stringify(style))).forEach(element => {
          jsonStyle[element] = style[element];
        })
        img.setAttribute("style", JSON.stringify(jsonStyle).replace(/","/g, ";").replace(/["'{}]/g, ""));
        img.setAttribute("width", width);
        img.setAttribute("height", height);
        img.setAttribute("class", "imageFilm");
        let div = document.createElement("div");
        div.setAttribute("class", "defaultImageDiv");
        let text = document.createElement("h3");
        text.setAttribute("class", "defaultImageText");
        text.appendChild(document.createTextNode(title));
        div.appendChild(text);
        picture.appendChild(source);
        picture.appendChild(img);
        picture.appendChild(div);
        return picture;
    }

    return(
        <div className='backgroundPage'>
            <Header 
                absolute
                color="transparent"
                brand="SOFA vod"
                fixed
            />
            {step == 1 ?
                <div className={classes.pageHeader + " backgroundHeader"}
                style={width < 3840 && width >= ((height*3840)/2160) ? { backgroundImage: "url(https://ik.imagekit.io/sofavod/Couverture_SOFA_2021_sans-titre.jpg?tr=w-" + width + ",h-" + ((width*2160)/3840) + ",dpr-" + window.devicePixelRatio.toString() + ")" } : 
                { backgroundImage: "url(https://ik.imagekit.io/sofavod/Couverture_SOFA_2021_sans-titre.jpg)" }}>
                <div className={classes.container + " mainContainer"}>
                    <GridContainer justify="center" className="containerGrid">
                        <GridItem xs={12} sm={12} md={10} className="itemGrid">
                            <Card className={classes[cardAnimaton] + " cardStyle"}>
                                <div className='presentationContainer'>
                                    <picture>
                                        <source srcSet={confettiAVIF} type="image/avif" />
                                        <img src={confetti} width={height > 1080 && width > 1920 ? 320 : 170} height={height > 1080 && width > 1920 ? 320 : 170} style={{ marginBottom: 20 }} />
                                    </picture>
                                    <h4 className='bienvenueText'>Bienvenue sur</h4>
                                    <img src={sofaLogo} width={height > 1080 && width > 1920 ? 320 : 170} height={height > 1080 && width > 1920 ? 117.6 : 62.5} style={{ marginBottom: 40 }} />
                                    {height > 1080 && width > 1920 && <div></div>}
                                    <h4 className='experienceText' style={{ marginBottom: 40 }}>Ton expérience commence ici</h4>
                                    <h4 className='experienceText' style={{ margin: 0 }}>C'est parti !</h4>
                                    <a href='#genres'>
                                        <div className='bouncingArrow'>
                                            <i class="fa-solid fa-chevron-down fa-5x"></i>
                                        </div>
                                    </a>
                                </div>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
                <div id="genres">
                    {loading ? <div className={classes.container}>
                        <div className='loadingCircle'>
                            <CircularProgress />
                        </div>       
                    </div> : <div className='genreListView'>
                        <div className={classes.container + " genreListContainer"}>
                            <h3 className='title genreMainTitle'>Choisis tes 2 genres préférés</h3>
                            <GridList className='genreList' cellHeight={height > 1080 && width > 1920 ? 150 : width > 768 ? 100 : 70} cols={getGridListCols()}>
                                {tabGenre.map(genre => 
                                    <li className={selectedGenre.length >= 2 && selectedGenre.findIndex(x => x.id == genre.id) == -1 ? 'genreElementDisabled liGenreElement' : 'genreElement liGenreElement'}>
                                        <button type='button' onClick={selectGenre} value={genre.id} disabled={selectedGenre.length >= 2 && selectedGenre.findIndex(x => x.id == genre.id) == -1}>
                                            <img src={genre.url + "?tr=w-" + (height > 1080 && width > 1920 ? "150,h-150" : width > 768 ? "100,h-100" : "70,h-70") + ",dpr-" + window.devicePixelRatio.toString()} width={height > 1080 && width > 1920 ? 150 : width > 768 ? 100 : 70} height={height > 1080 && width > 1920 ? 150 : width > 768 ? 100 : 70} className={selectedGenre.findIndex(x => x.id == genre.id) != -1 ? "imageGenreSelected" : (selectedGenre.length >= 2 ? "imageGenreDisabled" : "imageGenre")} />
                                            <div className='genreTitleElement'>
                                                <img className='logoImage' src={genre.imageLogo + "?tr=w-" + (height > 1080 && width > 1920 ? "150,h-" + (150*9/16).toString() : width > 768 ? "100,h-" + (100*9/16).toString() : "70,h-" + (70*9/16).toString()) + ",dpr-" + window.devicePixelRatio.toString()} width={height > 1080 && width > 1920 ? 150 : width > 768 ? 100 : 70} />
                                            </div>
                                        </button>
                                    </li>
                                )}
                            </GridList>
                            <div className='mainButton'>
                                <Button className={selectedGenre.length == 0 ? "selectButtonDisabled" : "selectButton"} color={selectedGenre.length == 0 ? "" : width > 768 ?  "sofa" : "sofaM"} size="lg" disabled={selectedGenre.length == 0} onClick={sendGenre}>Je choisis</Button>
                            </div>
                        </div>
                    </div>}
                </div>
            </div> : step == 2 && 
            <div className='backgroundSelectionContainer' style={{ backgroundImage: "url(" + tabSugg.find(x => x.Id == oldId).mini_path + "?tr=bl-50)" }}>
                <div className='backgroundImageSelection' style={transition ? { backgroundImage: "url(" + tabSugg.find(x => x.Id == selectedId).mini_path + "?tr=bl-50)", opacity: 0} :
                { backgroundImage: "url(" + tabSugg.find(x => x.Id == selectedId).mini_path + "?tr=bl-50)", opacity: 1 }}>
                <div className='mainContainerSelection'>
                    <div className={classes.container + " containerSelection"}>
                        <h3 className='title titleSelection'>Regarde ton premier film/série sur SOFA</h3>
                        <small className='smallTextSelection'>Par rapport à tes choix, voici nos suggestions</small>
                        <GridListTile className='mainElementContainer'>
                            <div className='imageElement'>
                                <div className='mainElement'>
                                    <Link className='mainElementImageLink' to={{
                                        pathname: "/detail/" + tabSugg.find(x => x.Id == selectedId).Id
                                    }}>
                                        <img id="carouselImage" src={encodeURI(tabSugg.find(x => x.Id == selectedId).mini_title_path+"?tr=w-" + (width > 1200 ? (1140*0.5).toString() + ",h-" + ((1140*0.5)*9/16).toString() : (width*0.5).toString() + ",h-" + ((width*0.5)*9/16).toString()) + ",dpr-" + window.devicePixelRatio.toString())} width="100%" alt={tabSugg.find(x => x.Id == selectedId).title} loading="lazy" onError={(e) => {e.target.src = noImage}} />
                                    </Link>
                                    <div id="backgroundImage" style={document.getElementById("carouselImage") ? { maxHeight: document.getElementById("carouselImage").clientHeight } :
                                    width <= 768 ? { minHeight: (((width*0.85-128)*9)/16), backgroundImage: "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("+tabSugg.find(x => x.Id == selectedId).mini_path+"?tr=bl-50)" } : {}}>
                                        <Link to={{
                                        pathname: "/detail/" + tabSugg.find(x => x.Id == selectedId).Id
                                        }}>
                                            <div className='exclusifContainer'>
                                            {/* <img src={encodeURI(item.item.logo_path + "?tr=w-192,h-108")} loading="lazy" /> */}
                                            {tabSugg.find(x => x.Id == selectedId).isExclusif == 1 ? <img src={exclusifLarge} width={width > 768 ? 160 : 96} height={width > 768 ? 30 : 18} /> : <div></div>}
                                            </div>
                                        </Link>
                                        <Link to={{
                                        pathname: "/detail/" + tabSugg.find(x => x.Id == selectedId).Id
                                        }}>
                                            <div className='informationContainer'>
                                                <div className='descriptionContainer'>
                                                    <p>{ tabSugg.find(x => x.Id == selectedId).short_desc }</p>
                                                {/*width > 1280 && <div style={{ justifyContent: "center", display: "flex", flexDirection: "column", marginTop: 10 }}>
                                                    <img src={encodeURI(item.Table_Region.logo_path + "?tr=w-230,h-230")} style={{ width: 70 }} alt={item.Table_Region.name} loading="lazy" />
                                                    <h6 style={{ color: '#FFF', textAlign: "center" }}>{item.Table_Region.name}</h6>
                                                </div>*/}
                                                </div>
                                                <div className='logoContainer'>
                                                    <img src={tabSugg.find(x => x.Id == selectedId).Table_Age_Limit.image_path + "?tr=w-" + (height > 1080 && width > 1920 ? "60,h-60" : width > 768 ? "40,h-40" : "30,h-30") + ",dpr-" + window.devicePixelRatio.toString()} width={height > 1080 && width > 1920 ? 60 : width > 768 ? 40 : 30} height={height > 1080 && width > 1920 ? 60 : width > 768 ? 40 : 30} />
                                                    <GridList>
                                                        {tabSugg.find(x => x.Id == selectedId).categories.map((genre) => (
                                                        <div className='genreMainElement'>
                                                            <img src={encodeURI(genre.url + "?tr=w-" + (height > 1080 && width > 1920 ? "120,h-120" : width > 992 ? "70,h-70" : "40,h-40") + ",dpr-" + window.devicePixelRatio.toString())} width={height > 1080 && width > 1920 ? 120 : width > 992 ? 70 : 40} height={height > 1080 && width > 1920 ? 120 : width > 992 ? 70 : 40} alt={genre.url} loading="lazy" />
                                                            <div>
                                                                <img src={encodeURI(genre.imageLogo + "?tr=w-" + (height > 1080 && width > 1920 ? "120,h-" + (120*9/16).toString() : width > 992 ? "70,h-" + (70*9/16).toString() : "40,h-" + (40*9/16).toString()) + ",dpr-" + window.devicePixelRatio.toString())} width={height > 1080 && width > 1920 ? 120 : width > 992 ? 70 : 40} alt={genre.imageLogo} loading="lazy" />
                                                            </div>
                                                        </div>
                                                        ))}
                                                    </GridList>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className='buttonContainer'>
                                            <Link to={{
                                            pathname: "/player",
                                            state: tabSugg.find(x => x.Id == selectedId)
                                            }}>
                                                <Button
                                                    color={width > 768 ? "sofa" : "sofaM"}
                                                    size={width > 1280 ? "lg" : "sm"}
                                                    //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                <i className="fa-regular fa-circle-play fa-xl" /> &nbsp;  &nbsp;Lecture
                                            </Button></Link>
                                            {/*width <= 768 && item.myList ? <Button
                                                color="sofa3"
                                                size="sm"
                                                //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={removeList}
                                                value={item.Id}
                                            >Retirer de ma liste</Button> : width <= 768 && <Button
                                                color="sofa3"
                                                size="sm"
                                                //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={addList}
                                                value={item.Id}
                                        >Ajouter à ma liste</Button>*/}
                                            <div className='infoButtonContainer'>
                                                {/*item.myList ?
                                                <div>
                                                    <button onClick={removeList} value={item.Id} className={classes.interactButton} style={width > 1280 ? { display: "flex", marginTop: 15, alignItems: "center" } : { display: "flex", flexDirection: "column" }}><div style={width > 1280 ? { display: "flex", flexDirection: "column", marginRight: 10, marginTop: 5 } : { display: "flex", flexDirection: "column", marginRight: 10 }}><i class={width > 1280 ? "fas fa-list-ul fa-xl" : "fas fa-list-ul"} style={{ alignSelf: "center" }}></i><i style={width > 1280 ? { alignSelf: "flex-end", marginRight: -10, marginTop: 3 } : { alignSelf: "flex-end", marginRight: -10 }} class={width > 1280 ? "fas fa-check fa-1x" : "fas fa-check fa-sm"}></i></div>{width > 1280 && <p style={{ alignSelf: "center", whiteSpace: "nowrap", marginBottom: 10, marginLeft: 5 }}>Ma liste</p>}</button>
                                                </div>
                                                :
                                                <div>
                                                    <button onClick={addList} value={item.Id} className={classes.interactButton} style={width > 1280 ? { display: "flex", marginTop: 15, alignItems: "center" } : { display: "flex", flexDirection: "column" }}><div style={width > 1280 ? { display: "flex", flexDirection: "column", marginRight: 10, marginTop: 5 } : { display: "flex", flexDirection: "column", marginRight: 10 }}><i class={width > 1280 ? "fas fa-list-ul fa-xl" : "fas fa-list-ul"} style={{ alignSelf: "center" }}></i><i style={width > 1280 ? { alignSelf: "flex-end", marginRight: -10, marginTop: 3 } : { alignSelf: "flex-end", marginRight: -10 }} class={width > 1280 ? "fas fa-plus fa-1x" : "fas fa-plus fa-sm"}></i></div>{width > 1280 && <p style={{ alignSelf: "center", whiteSpace: "nowrap", marginBottom: 10, marginLeft: 5 }}>Ma liste</p>}</button>
                                                </div>*/
                                                }
                                                <Link to={{
                                                    pathname: "/detail/" + tabSugg.find(x => x.Id == selectedId).Id
                                                }}>
                                                    <button className="infoButton"><i class={height > 1080 && width > 1920 ? "fa-solid fa-info fa-2xl" : width > 1280 ? "fa-solid fa-info fa-xl" : "fa-solid fa-info"}></i></button>
                                                </Link>
                                            </div>
                                        </div>
                                        {width <= 768 && <h6 className='mainGenreLibM'>{tabSugg.find(x => x.Id == selectedId).genreLib}</h6>}
                                    </div>
                                </div>
                                {width > 768 && <h6 className='mainGenreLib'>{tabSugg.find(x => x.Id == selectedId).genreLib}</h6>}
                                {tabSugg.find(x => x.Id == selectedId).isFree != 1 && currentUser.statut != 20 && <div className='horlogeContainer'><i class="fa-solid fa-lock fa-xl"></i></div>}
                            </div>
                        </GridListTile>
                        {width > 768 ? <GridList className='listFilms' cellHeight={height > 1080 && width > 1920 ? "auto" : width > 768 ? 170 : 120} cols={4}>
                            {tabSugg.filter(x => x.Id != selectedId).map((item) =>
                                <li className='item suggItem' onClick={selectId} value={item.Id}>
                                    <div className='imageElement'>
                                        <img src={item.mini_title_path + "?tr=w-" + (width > 1200 ? (1140*0.25).toString() + ",h-" + ((1140*0.25)*9/16).toString() : (width*0.25).toString() + ",h-" + ((width*0.25)*9/16).toString()) + ",dpr-" + window.devicePixelRatio.toString()} className='imageFilm' width="100%" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,"100%",null,item.title))}} />
                                        <h6 className='genreLibSugg'>{item.genreLib}</h6>
                                        {item.isExclusif == 1 && (item.isFree == 1 || currentUser.statut == 20) && <div className='exclusifSugg'><img src={exclusif} height={width > 992 ? 20 : 15} alt="Exclusif" /></div>}
                                        {item.isFree != 1 && currentUser.statut != 20 && <div className='freeSugg'><i class="fa-solid fa-lock fa-xl"></i></div>}
                                    </div>
                                </li>
                            )}
                        </GridList> : <ul className='listFilmsM'>
                            {tabSugg.filter(x => x.Id != selectedId).map((item) =>
                                <li className='item suggItem' onClick={selectId} value={item.Id}>
                                    <div className='imageElement'>
                                        <img src={item.mini_title_path + "?tr=w-200,h-" + (200*9/16).toString() + ",dpr-" + window.devicePixelRatio.toString()} className='imageFilm' width={200} height={200*9/16} onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,200,(200*9/16),item.title))}} />
                                        <h6 className='genreLibSugg'>{item.genreLib}</h6>
                                        {item.isExclusif == 1 && (item.isFree == 1 || currentUser.statut == 20) && <div className='exclusifSugg'><img src={exclusif} height={15} alt="Exclusif" /></div>}
                                        {item.isFree != 1 && currentUser.statut != 20 && <div className='freeSugg'><i class="fa-solid fa-lock fa-xl"></i></div>}
                                    </div>
                                </li>
                            )}
                        </ul>}
                    </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}