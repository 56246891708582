/* eslint-disable no-unused-expressions */
import React, {useState,useEffect, useRef} from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Link,
  useParams
} from "react-router-dom";
// @material-ui/icons
import CircularProgress from '@material-ui/core/CircularProgress';
// core components 
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import AuthService from "services/auth.service.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import HomePage from './HomePage';
import Carousel from 'components/CarouselMulti/Carousel';
import MainCarousel from 'components/CarouselMulti/MainCarousel';
import useWindowSize from 'components/useWindowSize';
import { useHistory } from 'react-router-dom';

import { getRegionLandingAll } from 'API/RegionLandingAPI.js';
import { getItemsAll } from 'API/ItemsAPI.js';
import { getUserItemById } from "API/GetUserItemAPI.js";
import { addUserItem } from "API/AddUserItemAPI.js";
import { upUserItem } from "API/UpUserItemAPI.js";
import { getTheme } from "API/GetThemeAPI.js";
import { getBanner } from "API/GetBannerAPI.js";
import exclusif from 'assets/img/exclusif.png'
import exclusifLarge from "assets/img/exclusif_large.png";
import noImageAVIF from "assets/img/Fond_lampadaire.avif";
import noImage from "assets/img/Fond_lampadaire.jpg";
import noImagePAVIF from "assets/img/Fond_lampadaire_Portrait.avif";
import noImageP from "assets/img/Fond_lampadaire_Portrait.jpg";


const dashboardRoutes = [];

    //console.log(data);
    
    class ItemTab {
      constructor(id, item,orderNum) {
        this.Id = id;
        this.item = item;
        this.orderNum = orderNum;
      }
    }
    class BannerTab {
      constructor(id, itemTab, name, orderNum, orientation) {
        this.id = id;
        this.itemTab = itemTab;
        this.name = name;
        this.orderNum = orderNum;
        this.orientation = orientation;
      }
    }
const useStyles = makeStyles(styles);
const useStylesList = makeStyles(() => ({
  title: {
    marginLeft: 45
  },
  titleM: {
    marginLeft: 6
  }
}));
export default function LandingPage(props) {
  const classes = useStyles();
  const classesList = useStylesList();
  let currentUser = AuthService.getCurrentUser();
  let history = useHistory();
  const { width, height } = useWindowSize();
  const nWidthItem = width > 640 ? 300 : 200;
  const nHeightItem = width > 640 ? 170 : 113;
  const nWidthDepartement = width > 640 ? 210 : 160;

//console.log(currentUser)
  let banList = [];
   // console.log(getItemsAll().then(data => console.log(data)));

   //const [data, setData] = useState([]);
    
    const [theme, setTheme] = useState([]);
    const [listBanner, setListBanner] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [newToken, setNewToken] = useState(false);
    const [hasCall, setHasCall] = useState(false);
    const tokenRef = useRef();
    const callRef = useRef();

    tokenRef.current = newToken;
    callRef.current = hasCall;

    const [tabRegion, setTabRegion] = useState([]);

    const [forceRerender, setForceRerender] = useState(false);
  
  // Appel des API en asynchrone
    useEffect(() => {
      const getAPI  = async () => {
        let validtoken = true;

        if(currentUser != null) {
          if (currentUser.statut) {
            fetchData();
          } else {
            setNewToken(true);
            AuthService.refreshToken(currentUser.id, currentUser.accessToken).then(() => {
              currentUser = AuthService.getCurrentUser();
              getAPI();
            }).catch(err => {
              console.log(err);
              AuthService.logout();
              history.push('/login');
            })
          }
        }
      }
  
      const fetchData  = async () => {
        try {
          const respItems = await getItemsAll(currentUser.accessToken, currentUser.id);

          const respBanner = await getBanner(currentUser.accessToken);
    
          const respRegion = await getRegionLandingAll(currentUser.accessToken);
    
          const respTheme = await getTheme(currentUser.accessToken);
    
          setTabRegion(respRegion.data);
          setTheme(respTheme.data);
      //   console.log(tabItems)
        let newTab = [];

          respItems.data.map(item => {
            let newItem = item;
            if (!newItem.start) {
              newItem.start = 0;
            }
            if (newItem.idType == 1 && newItem.Table_User_Players && newItem.Table_User_Players.length > 0) {
              newItem.Table_User_Players.forEach(uP => {
                if (uP.idEpisode && uP.endTime && newItem.Table_Seasons.find(x => x.id == uP.idEpisode)) {
                  newItem.Table_Seasons.find(x => x.id == uP.idEpisode).start = uP.endTime;
                }
              })
            } else if (newItem.Table_User_Players && newItem.Table_User_Players.length > 0 && newItem.Table_User_Players[0].endTime) {
              newItem.start = newItem.Table_User_Players[0].endTime;
            }
            newTab.push(newItem);
          })

          respBanner.data.map(banner => {
            banList.push(new BannerTab(banner.id, [], banner.name, banner.orderNum, banner.orientation));
          })
    
        newTab.map(function(item){
          //console.log(item)

          item.Table_Banner_Items.map(function(banner){
            if (banner.idBanner != 5) {
              banList.find(x => x.id == banner.idBanner).itemTab.push(new ItemTab(item.Id, item, banner.orderNum));
            }
          });
        });

        banList = banList.filter(ban => ban.itemTab.length > 0 || (ban.id == 113 || ban.id == 114 || ban.id == 115));
          banList.forEach(ban => {
            if (ban.id != 113 || ban.id != 114 || ban.id != 115) {
              ban.itemTab = ban.itemTab.sort((a, b) => a.orderNum - b.orderNum);
            }
          })
          setListBanner(banList.sort((a, b) => a.orderNum - b.orderNum));

          setLoading(false);
          window.scrollTo({top: 0, left: 0, behavior: "instant"});
          
          banList.find(x => x.id == 1).itemTab.forEach((film, index) => {
            document.getElementById("carouselImage" + index).addEventListener("load", () => {
              setForceRerender(!forceRerender);
            })
            if (width > 960) {
              const style = "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(" + film.item.mini_path + "?tr=bl-50)";
              const src = film.item.mini_path + "?tr=bl-50";
              var image = new Image();
              image.addEventListener('load', () => {
                document.getElementById("backgroundImage" + index).style.backgroundImage = style;
              })
              image.src = src;  
            } else {
              const style = "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(" + film.item.mini_path + "?tr=bl-10)";
              const src = film.item.mini_path + "?tr=bl-10";
              var image = new Image();
              image.addEventListener('load', () => {
                document.getElementById("backgroundImage" + index).style.backgroundImage = style;
              })
              image.src = src;  
            }
          })
        } catch (erreur) {
          const erreurs = [erreur];
          if (!tokenRef.current && currentUser) {
            AuthService.refreshToken(currentUser.id,currentUser.accessToken).then(() => {
              setNewToken(true);
              currentUser = AuthService.getCurrentUser();
              getAPI();
            }).catch(err => {
              setNewToken(true);
              console.log(err);
              AuthService.logout();
              history.push('/login');
            })
          } else {
            console.log(erreur);
            AuthService.logout();
            history.push('/login');
          }
        }
      }
      if (!callRef.current) {
        setHasCall(true);
        getAPI();
      }
    }, []);
 
    useEffect(() => {
      setForceRerender(!forceRerender);
    }, [width])

    const getItemColumns = () => {
      let nItem = width / nWidthItem;
      return Math.trunc(nItem);
    }

    const getDepartementColums = () => {
      let nDepartement = width / nWidthDepartement;
      return Math.trunc(nDepartement);
    }
  
    const addList = (event) => {
      let id;
      if (event.currentTarget) {
        id = event.currentTarget.value;
      } else if (event.target) {
        id = event.target.value;
      }
      addUserItem(currentUser.accessToken, currentUser.id, id, 0, 0, 2).then(() => {
        let newListBanner = listBanner;
        newListBanner.find(x => x.id == 1).itemTab.find(x => x.Id == id).item.myList = true;
        setListBanner(newListBanner);
        setForceRerender(!forceRerender);
      })
    }
  
    const removeList = (event) => {
      let id;
      if (event.currentTarget) {
        id = event.currentTarget.value;
      } else if (event.target) {
        id = event.target.value;
      }
      getUserItemById(currentUser.accessToken, currentUser.id).then(response => {
        let element = response.data.find(x => x.IdItem == id && x.idMyBanner == 2);
        upUserItem(currentUser.accessToken, element.id).then(() => {
          let newListBanner = listBanner;
          newListBanner.find(x => x.id == 1).itemTab.find(x => x.Id == id).item.myList = false;
          setListBanner(newListBanner);
          setForceRerender(!forceRerender);
        })
      })
    }

    const defaultImage = (style, width, height, title) => {
      let picture = document.createElement("picture");
      picture.setAttribute("class", classes.defaultImgPicture);
      let source = document.createElement("source");
      source.setAttribute("srcSet", noImageAVIF);
      source.setAttribute("type", "image/avif");
      let img = document.createElement("img");
      img.setAttribute("src", noImage);
      let jsonStyle = {};
      Object.values(JSON.parse(JSON.stringify(style))).forEach(element => {
        jsonStyle[element] = style[element];
      })
      if (JSON.stringify(jsonStyle).replace(/["'{}]/g, "").length > 0) {
        img.setAttribute("style", JSON.stringify(jsonStyle).replace(/","/g, ";").replace(/["'{}]/g, ""));
      }
      if (width) {
        img.setAttribute("width", width);
      }
      if (height) {
        img.setAttribute("height", height);
      }
      let div = document.createElement("div");
      div.setAttribute("class", classes.defaultImgDiv);
      let text = document.createElement("h3");
      text.setAttribute("class", classes.defaultImgText);
      text.appendChild(document.createTextNode(title));
      div.appendChild(text);
      picture.appendChild(source);
      picture.appendChild(img);
      picture.appendChild(div);
      return picture;
    }

    const defaultImageP = (style, width, height, title) => {
      let picture = document.createElement("picture");
      picture.setAttribute("class", classes.defaultImgPicture);
      let source = document.createElement("source");
      source.setAttribute("srcSet", noImagePAVIF);
      source.setAttribute("type", "image/avif");
      let img = document.createElement("img");
      img.setAttribute("src", noImageP);
      let jsonStyle = {};
      Object.values(JSON.parse(JSON.stringify(style))).forEach(element => {
        jsonStyle[element] = style[element];
      })
      if (JSON.stringify(jsonStyle).replace(/["'{}]/g, "").length > 0) {
        img.setAttribute("style", JSON.stringify(jsonStyle).replace(/","/g, ";").replace(/["'{}]/g, ""));
      }
      if (width) {
        img.setAttribute("width", width);
      }
      if (height) {
        img.setAttribute("height", height);
      }
      let div = document.createElement("div");
      div.setAttribute("class", classes.defaultImgDiv);
      let text = document.createElement("h3");
      text.setAttribute("class", classes.defaultImgText);
      text.appendChild(document.createTextNode(title));
      div.appendChild(text);
      picture.appendChild(source);
      picture.appendChild(img);
      picture.appendChild(div);
      return picture;
    }

  const { ...rest } = props;
  if (currentUser == null) {
    // retour vers la page de vente 
    return <HomePage />
  } else {
  return (
    <div className={classes.mainBackground}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="SOFA vod"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "#1B1B1B" 
        }}
        {...rest}
      />
       {isLoading ? <div className={classes.container}>
       <div className={classes.loading}>
       <CircularProgress />
      </div>        
         </div> : (
      <div className={classes.main + " " + classes.containerElement}>
      <MainCarousel show={1}>
         {listBanner.find(x => x.id == 1).itemTab.map((item, index) => (
            <GridListTile className={width > 960 ? classes.carrouselElement : classes.carrouselElementM}>
              <div className={width > 960 ? classes.carrouselMainDiv : classes.carrouselMainDivM}>
                <div className={width > 960 ? classes.carrouselContainer : classes.carrouselContainerM}>
                  {width > 960 ? <Link className={classes.carrousel__link} to={{
                    pathname: "/detail/" + item.item.Id
                  }}>
                    <img id={"carouselImage" + index} className={classes.carrouselImg} src={encodeURI(item.item.mini_title_path+"?tr=w-" + (width*0.85*0.5).toString() + ",h-" + ((width*0.85*0.5)*9/16).toString() + ",dpr-" + window.devicePixelRatio.toString())} width="100%" alt={item.item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,"100%",null,item.item.title))}} />
                  </Link> : <div id={"carouselImage" + index} className={classes.carrouselDivM}></div>}
                  <div id={"backgroundImage" + index} className={width > 960 ? classes.carrouselBackground : classes.carrouselBackgroundM} style={width > 960 && document.getElementById("carouselImage" + index) ? { maxHeight: document.getElementById("carouselImage" + index).clientHeight, backgroundImage: "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(" + item.item.mini_path + "?tr=bl-50)" } :
                  (width > 960 ? { backgroundImage: "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(" + item.item.mini_path + "?tr=bl-50)" } :
                  { minHeight: (((width-16)*9)/16), backgroundImage: "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(" + item.item.mini_path + "?tr=bl-10)" })}>
                    <Link className={classes.link} to={{
                      pathname: "/detail/" + item.item.Id
                    }}>
                    <div className={classes.logoDivCarrousel}>
                      {width > 960 ? null : !item.item.logo_path ? <h3 className={classes.titleCarrousel}>{ item.item.title }</h3> : <img src={encodeURI(item.item.logo_path+"?tr=w-" + (84*16/9).toString() + ",h-84,dpr-" + window.devicePixelRatio.toString())} className={classes.logoCarrousel} height={84} onError={(e) => {e.target.replaceWith(document.createElement("h3").setAttribute('class', classes.titleCarrousel).appendChild(document.createTextNode(item.item.title)))}} />}
                      {/* <img src={encodeURI(item.item.logo_path + "?tr=w-192,h-108")} loading="lazy" /> */}
                      {item.item.isExclusif == 1 ? <img src={exclusifLarge} className={classes.departementImg} width={width > 960 ? 160 : 128} height={width > 960 ? 30 : 24} /> : <div></div>}
                    </div>
                    </Link>
                    <Link className={classes.link} to={{
                      pathname: "/detail/" + item.item.Id
                    }}>
                      {width <= 640 && <div className={classes.carrouselInfoM}>
                        <h6>{item.item.genreLib}</h6>
                        <img src={item.item.Table_Age_Limit.image_path + "?tr=w-30,h-30,dpr-" + window.devicePixelRatio.toString()} width={30} height={30} />
                        {item.item.isFree != 1 && currentUser.statut != 20 && <div className={classes.freeElementCarrouselM}><i class="fa-solid fa-lock fa-xl"></i></div>}
                      </div>}
                      <div className={classes.carrouselInfoContainer}>
                        <div className={width > 640 ? classes.carrouselDesc : classes.carrouselDescM}>
                          <p className={width >= 1920 ? classes.carrouselDescTextB : width > 640 ? classes.carrouselDescText : classes.carrouselDescTextM}>{ item.item.short_desc }</p>
                          {/*width > 1280 && <div style={{ justifyContent: "center", display: "flex", flexDirection: "column", marginTop: 10 }}>
                            <img src={encodeURI(item.Table_Region.logo_path + "?tr=w-230,h-230")} style={{ width: 70 }} alt={item.Table_Region.name} loading="lazy" />
                            <h6 style={{ color: '#FFF', textAlign: "center" }}>{item.Table_Region.name}</h6>
                          </div>*/}
                        </div>
                        {width > 640 && <div className={classes.carrouselInfo}>
                          <img src={height > 1080 ? item.item.Table_Age_Limit.image_path + "?tr=w-60,h-60,dpr-" + window.devicePixelRatio.toString() : item.item.Table_Age_Limit.image_path + "?tr=w-40,h-40,dpr-" + window.devicePixelRatio.toString()} width={height > 1080 ? 60 : 40} height={height > 1080 ? 60 : 40} />
                          {width > 1280 && <GridList className={classes.gridList}>
                            {item.item.categories.map((genre) => (
                              <div className={height > 960 ? classes.categoryItem : classes.categoryItemM}>
                                <img src={encodeURI(genre.url + "?tr=w-" + (height > 960 ? "120,h-120" : "80,h-80") + ",dpr-" + window.devicePixelRatio.toString())} className={classes.categoryImg} width={height > 960 ? 120 : 80} height={height > 960 ? 120 : 80} alt={genre.url} loading="lazy" />
                                <div className={height > 960 ? classes.categoryLogoDiv : classes.categoryLogoDivM}>
                                  <img src={encodeURI(genre.imageLogo + "?tr=w-" + (height > 960 ? "120,h-" + (120*9/16).toString() : "80,h-" + (80*9/16).toString()) + ",dpr-" + window.devicePixelRatio.toString())} width={height > 960 ? 120 : 80} alt={genre.imageLogo} loading="lazy" />
                                </div>
                              </div>
                            ))}
                          </GridList>}
                        </div>}
                      </div>
                    </Link>
                    <div className={width > 640 ? classes.carrouselButtonDiv : classes.carrouselButtonDivM}>
                        {currentUser.statut != 20 && item.item.isFree != 1 ? <Link className={width > 640 ? classes.link : classes.linkButtonM} to={{
                          pathname: "/detail/" + item.item.Id,
                          state: item.item
                        }}>
                          <Button
                            color={width > 640 ? "sofa" : "sofaM"}
                            size={width > 1280 ? "lg" : "sm"}
                            target="_blank"
                            rel="noopener noreferrer"
                          ><i className="fa-regular fa-circle-play fa-xl" /> &nbsp;  &nbsp;Lecture</Button>
                        </Link> : <Link className={width > 640 ? classes.link : classes.linkButtonM} to={{
                          pathname: "/player",
                          state: (item.item.idType == 1 && item.item.Table_User_Players.length > 0 ? {item: item.item, episode: item.item.Table_Seasons.find(x => x.id == item.item.Table_User_Players[item.item.Table_User_Players.length - 1].idEpisode)} : item.item)
                        }}>
                          {item.item.seen == "onWatch" ? <Button
                            color={width > 640 ? "sofa" : "sofaM"}
                            size={width > 1280 ? "lg" : "sm"}
                            //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fa-regular fa-circle-play fa-xl" /> &nbsp;  &nbsp;Reprendre</Button> : <Button
                            color={width > 640 ? "sofa" : "sofaM"}
                            size={width > 1280 ? "lg" : "sm"}
                            //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                            target="_blank"
                            rel="noopener noreferrer"
                          >

                            <i className="fa-regular fa-circle-play fa-xl" /> &nbsp;  &nbsp;Lecture

                          </Button>}</Link>}
                          {width <= 640 && item.item.myList ? <Button
                            color="sofa3"
                            size="sm"
                            //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={removeList}
                            value={item.item.Id}
                          >Retirer de ma liste</Button> : width <= 640 && <Button
                            color="sofa3"
                            size="sm"
                            //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={addList}
                            value={item.item.Id}
                          >Ajouter à ma liste</Button>}
                      {width > 640 && <div className={width > 640 ? classes.carrouselRightButton : classes.carrouselRightButtonM}>
                        {item.item.myList ? <div>
                            <button onClick={removeList} value={item.item.Id} className={width > 1280 ? classes.interactButton + " " + classes.listButton : classes.interactButton + " " + classes.listButtonM}>
                              <div>
                                <i class={width > 1280 ? "fas fa-list-ul fa-xl " + classes.listIcon : "fas fa-list-ul " + classes.listIcon}></i>
                                <i class={width > 1280 ? "fas fa-check fa-1x " + classes.addIcon : "fas fa-check fa-sm " + classes.addIconM}></i>
                              </div>
                              {width > 1280 && <p className={classes.addListText}>Ma liste</p>}
                            </button>
                          </div> : <div>
                            <button onClick={addList} value={item.item.Id} className={width > 1280 ? classes.interactButton + " " + classes.listButton : classes.interactButton + " " + classes.listButtonM}>
                              <div>
                                <i class={width > 1280 ? "fas fa-list-ul fa-xl " + classes.listIcon : "fas fa-list-ul " + classes.listIcon}></i>
                                <i class={width > 1280 ? "fas fa-plus fa-1x " + classes.addIcon : "fas fa-plus fa-sm " + classes.addIconM}></i>
                              </div>
                              {width > 1280 && <p className={classes.addListText}>Ma liste</p>}
                            </button>
                          </div>}
                        <div>
                          <Link className={classes.link} to={{
                            pathname: "/detail/" + item.item.Id
                          }}>
                            <button className={width > 1280 ? classes.infoButton + " " + classes.info : classes.infoButton + " " + classes.info}><i class={width > 1280 ? "fa-solid fa-info fa-xl" : "fa-solid fa-info"}></i></button>
                          </Link>
                        </div>
                      </div>}
                    </div>
                  </div>
                </div>
                {item.item.isNewEpisode == 1 && (item.item.isFree == 1 || currentUser.statut == 20) && width > 640 && <div className={classes.newEpisodeCarrousel}><h6>Nouvel épisode</h6></div>}
                {item.item.seen == "onWatch" && <div className={classes.watchContainerCarrousel}><div><div style={{ width: ( item.item.Table_User_Players[item.item.Table_User_Players.length - 1].endTime / item.item.Table_User_Players[item.item.Table_User_Players.length - 1].totalTime * 100 ) + "%" }}></div></div></div>}
                {item.item.isFree != 1 && currentUser.statut != 20 && width > 640 && <div className={classes.freeElementCarrousel}><i class="fa-solid fa-lock fa-2xl"></i></div>}
                {width > 640 && <h6 className={width > 960 ? classes.genreLibCarrousel : classes.genreLibCarrouselM}>{item.item.genreLib}</h6>}
              </div>
            </GridListTile>
           ))}
           </MainCarousel>

           

        <GridContainer className={width > 640 ? classes.bannerContainer : classes.bannerContainerM}>
          {listBanner.filter(x => x.id != 1).map(banner => <GridItem xs={12} className={classes.bannerItem}>
            <h3 className={width > 640 ? classes.title + " " + classesList.title : classes.title + " " + classesList.titleM}>{banner.name}</h3>
            {banner.id == 113 ? <div className={classes.root}>
              {theme.length > getItemColumns() ? <Carousel show={getItemColumns()}>
                {theme.map(item => <GridListTile className={"item " + classes.themeElement}>
                  <Link to={{
                    pathname: "/theme",
                    state: item
                  }}>
                    <div>
                      <img src={encodeURI(item.image_path+"?tr=w-" + nWidthItem.toString() + ",h-" + (nWidthItem*9/16).toString() + ",dpr-" + window.devicePixelRatio.toString())} width='100%' alt={item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,'100%',null,item.title))}} />
                    </div>
                  </Link>
                </GridListTile>)}
              </Carousel> : <GridList className={width > 640 ? classes.gridList + " " + classes.listItem : classes.gridList + " " + classes.listItemM}>
                {theme.map(item => <div className={"item " + classes.themeElement + " " + classes.nonCarousel}>
                  <Link to={{
                    pathname: "/theme",
                    state: item
                  }}>
                    <div>
                      <img src={encodeURI(item.image_path+"?tr=w-" + nWidthItem.toString() + ",h-" + (nWidthItem*9/16).toString() + ",dpr-" + window.devicePixelRatio.toString())} width='100%' alt={item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,'100%',null,item.title))}} />
                    </div>
                  </Link>
                </div>)}
              </GridList>}
            </div> : banner.id == 115 ? <div className={classes.root}>
              <Carousel show={getDepartementColums()}>
                {tabRegion.map((item) => (
                  <div className={"item " + classes.departementElement}>
                    <Link to={{
                      pathname: "/departement",
                      state: item
                    }}>
                      <div className={classes.departementDiv}>
                        <img className={classes.departementImg} src={encodeURI(item.logo_path+"?tr=w-" + nHeightItem.toString() + ",h-" + nHeightItem.toString() + ",dpr-" + window.devicePixelRatio.toString())} width={nHeightItem} height={nHeightItem} alt={item.name} loading="lazy" />
                        <h6 className={classes.departementText}>{item.name}</h6>
                      </div>
                    </Link>
                  </div>
                ))}
              </Carousel>
            </div> :
            banner.orientation == "L" ?
              <div className={classes.root}>
                {banner.itemTab.length > getItemColumns() ? <Carousel show={getItemColumns()}>
                    {banner.itemTab.map(item => <GridListTile className={"item " + classes.themeElement}>
                        <Link to={{
                          pathname: "/detail/" + item.item.Id
                        }}>
                          <div>
                            <img src={encodeURI(item.item.mini_title_path+"?tr=w-" + nWidthItem.toString() + ",h-" + (nWidthItem*9/16).toString() + ",dpr-" + window.devicePixelRatio.toString())} width='100%' alt={item.item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,'100%',null,item.item.title))}} />
                            <h6 className={classes.genreLib}>{item.item.genreLib}</h6>
                            {item.item.isExclusif == 1 && (item.isFree == 1 || currentUser.statut == 20) && <div className={classes.exclusif}><img src={exclusif} height={20} alt="Exclusif" /></div>}
                            {item.item.isNewEpisode == 1 && <div className={classes.newEpisode}><h6>Nouvel épisode</h6></div>}
                            {item.item.seen == "onWatch" && <div className={classes.watchContainer}><div><div style={{ width: ( item.item.Table_User_Players[item.item.Table_User_Players.length - 1].endTime / item.item.Table_User_Players[item.item.Table_User_Players.length - 1].totalTime * 100 ) + "%" }}></div></div></div>}
                            {item.item.isFree != 1 && currentUser.statut != 20 && <div className={classes.freeLogoG}>
                              <i class="fa-solid fa-lock fa-xl"></i>
                            </div>}
                          </div>
                        </Link>
                    </GridListTile>)}
                </Carousel> : <GridList className={width > 640 ? classes.gridList + " " + classes.listItem : classes.gridList + " " + classes.listItemM}>
                        {banner.itemTab.map(item => <div className={"item " + classes.themeElement + " " + classes.nonCarousel}>
                          <Link to={{
                            pathname: "/detail/" + item.item.Id
                          }}>
                            <div className={classes.nonCarouselElement} style={{ width: nWidthItem }}>
                              <img src={encodeURI(item.item.mini_title_path+"?tr=w-" + nWidthItem.toString() + ",h-" + (nWidthItem*9/16).toString() + ",dpr-" + window.devicePixelRatio.toString())} width='100%' alt={item.item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,'100%',null,item.item.title))}} />
                              <h6 className={classes.nCGenreLib}>{item.item.genreLib}</h6>
                              {item.item.isExclusif == 1 && (item.isFree == 1 || currentUser.statut == 20) && <div className={classes.nCExclusif}><img src={exclusif} height={20} alt="Exclusif" /></div>}
                              {item.item.isNewEpisode == 1 && <div className={classes.nCNewEpisode}><h6>Nouvel épisode</h6></div>}
                              {item.item.seen == "onWatch" && <div className={classes.nCWatchContainer}><div><div style={{ width: ( item.item.Table_User_Players[item.item.Table_User_Players.length - 1].endTime / item.item.Table_User_Players[item.item.Table_User_Players.length - 1].totalTime * 100 ) + "%" }}></div></div></div>}
                              {item.isFree != 1 && currentUser.statut != 20 && <div className={classes.nCFreeLogoG}>
                                <i class="fa-solid fa-lock fa-xl"></i>
                              </div>}
                            </div>
                          </Link>
                        </div>)}
                </GridList>}
              </div> : banner.orientation == "P" && <div className={classes.root}>
                {banner.itemTab.length > getItemColumns() ? <Carousel show={getItemColumns()}>
                  {banner.itemTab.map(item => <GridListTile className={"item " + classes.themeElement}>
                    <Link to={{
                      pathname: "/detail/" + item.item.Id
                    }}>
                      <div>
                        <img src={encodeURI(item.item.portrait_title_path+"?tr=w-" + nWidthItem.toString() + ",h-" + (nWidthItem*3/2).toString() + ",dpr-" + window.devicePixelRatio.toString())} width='100%' alt={item.item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImageP(e.nativeEvent.target.style,'100%',null,item.item.title))}} />
                        <h6 className={classes.genreLib}>{item.item.genreLib}</h6>
                        {item.item.isExclusif == 1 && (item.isFree == 1 || currentUser.statut == 20) && <div className={classes.exclusif}><img src={exclusif} height={20} alt="Exclusif" /></div>}
                        {item.item.isNewEpisode == 1 && <div className={classes.newEpisode}><h6>Nouvel épisode</h6></div>}
                        {item.item.seen == "onWatch" && <div className={classes.watchContainer}><div><div style={{ width: ( item.item.Table_User_Players[item.item.Table_User_Players.length - 1].endTime / item.item.Table_User_Players[item.item.Table_User_Players.length - 1].totalTime * 100 ) + "%" }}></div></div></div>}
                        {item.item.isFree != 1 && currentUser.statut != 20 && <div className={classes.freeLogoG}>
                          <i class="fa-solid fa-lock fa-xl"></i>
                        </div>}
                      </div>
                    </Link>
                  </GridListTile>)}
                </Carousel> : <GridList className={width > 640 ? classes.gridList + " " + classes.listItem : classes.gridList + " " + classes.listItemM}>
                  {banner.itemTab.map(item => <div className={"item " + classes.themeElement + " " + classes.nonCarousel}>
                    <Link to={{
                      pathname: "/detail/" + item.item.Id
                    }}>
                      <div className={classes.nonCarouselElement} style={{ width: nWidthItem }}>
                        <img src={encodeURI(item.item.portrait_title_path+"?tr=w-" + nWidthItem.toString() + ",h-" + (nWidthItem*3/2).toString() + ",dpr-" + window.devicePixelRatio.toString())} width='100%' alt={item.item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImageP(e.nativeEvent.target.style,'100%',null,item.item.title))}} />
                        <h6 className={classes.nCGenreLib}>{item.item.genreLib}</h6>
                        {item.item.isExclusif == 1 && (item.isFree == 1 || currentUser.statut == 20) && <div className={classes.nCExclusif}><img src={exclusif} height={20} alt="Exclusif" /></div>}
                        {item.item.isNewEpisode == 1 && <div className={classes.nCNewEpisode}><h6>Nouvel épisode</h6></div>}
                        {item.item.seen == "onWatch" && <div className={classes.nCWatchContainer}><div><div style={{ width: ( item.item.Table_User_Players[item.item.Table_User_Players.length - 1].endTime / item.item.Table_User_Players[item.item.Table_User_Players.length - 1].totalTime * 100 ) + "%" }}></div></div></div>}
                        {item.item.isFree != 1 && currentUser.statut != 20 && <div className={classes.nCFreeLogoG}>
                          <i class="fa-solid fa-lock fa-xl"></i>
                        </div>}
                      </div>
                    </Link>
                  </div>)}
                </GridList>}
              </div>}
          </GridItem>
           )}
        </GridContainer>
      </div>)}
      <Footer />
    </div>
  );
            }
}
