// API/AddUserItemAPI.js
import axios from "axios";
import UrlAPI from "./UrlAPI"

export function addUserItem(pi_token, pi_idUser, pi_idItem, pi_idPeople, pi_idProductor, pi_idMyBanner) {
    
  const url = UrlAPI.sUrl+'/user/addUserItem'
  return axios(url,{
    method: 'post',
    data:{
      idUser:pi_idUser,
      IdItem:pi_idItem,
      idPeople:pi_idPeople,
      idProductor:pi_idProductor,
      idMyBanner:pi_idMyBanner,
      } ,
    headers:{
        'Content-Type': "application/json",
        'x-access-token': pi_token
    },
    })  
}