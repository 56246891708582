import React, { useEffect, useRef, useState } from "react";
// nodejs library that concatenates classes
import {
    BrowserRouter as Router,
    NavLink,
    Route,
    Link,
    useParams
} from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import Lightbox from 'react-image-lightbox';
//import 'react-image-lightbox/style.css'; 
// @material-ui/icons
import sofaLogo from "assets/img/sofa-blanc_final.png";
// core components


import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import useWindowSize from 'components/useWindowSize';
import exclusif from 'assets/img/exclusif.png';

import { loginAPI } from "API/LoginAPI.js";
import { getItemsHomePage } from "API/GetItemHomePageAPI.js";
import noImageAVIF from "assets/img/Fond_lampadaire_Portrait.avif";
import noImage from "assets/img/Fond_lampadaire_Portrait.jpg";

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(250, 167, 74, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);
//import 'assets/assets-home/js/main.js'
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    pictureDefault: {
        position: "relative"
    },
    textDefault: {
        color: "#FFF",
        fontSize: 20,
        fontFamily: "Bubblebody Light",
        textAlign: "center",
        position: "relative",
        top: "-50%"
    }
}));
export default function HomePageV2() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('');
    const { width, height } = useWindowSize();
    const [callingAPI, setCallAPI] = useState(false);
    const [loading, setLoading] = useState(true);
    const [listItems, setListItems] = useState([]);
    const [listAvis, setListItemsAvis] = useState([]);
    const [autoScrollTimer, setAutoScrollTimer] = useState();
    const [returnTimer, setReturnTimer] = useState();
    const [leftScroll, setLeftScroll] = useState(false);
    const [lastPosition, setLastPosition] = useState(0);
    const [returnController, setReturnController] = useState(false);
    const timerRef = useRef();
    const leftScrollRef = useRef();
    const positionRef = useRef();
    const returnRef = useRef();
    const returnTimerRef = useRef();

    timerRef.current = autoScrollTimer;
    leftScrollRef.current = leftScroll;
    positionRef.current = lastPosition;
    returnRef.current = returnController;
    returnTimerRef.current = returnTimer;

    useEffect(() => {
        const getAPI = async () => {
            const name = "siteabout";
            const psw = "s1teS0fa2022";
            const respLogin = await loginAPI(name, psw);
            const token = respLogin.data.accessToken;
            const respItems = await getItemsHomePage(token);
            setListItems(respItems.data);
            setLoading(false);
            mousseScroll();
            if (width <= 768) {
                let element = document.getElementById('listText');
                let element2 = document.getElementById('listMobile');
                const heightElement = element.clientHeight;
                element2.setAttribute('style', "min-height: 350px;margin-bottom: " + heightElement.toString() + "px;");
            }
        }
        const callingAPIF = () => {
            window.removeEventListener('scroll', callingAPIF);
            setTimeout(() => {
                if (!callingAPI) {
                    setCallAPI(true);
                    getAPI();
                }    
            }, 10)
        }
        window.addEventListener('scroll', callingAPIF);
        if (width <= 768) {
            callingAPIF();
        }
        // let avis = [];
        // avis.push({
        //     imageAVIF: alexisAVIF,
        //     image: alexis,
        //     name: "Alexis Bonzi",
        //     text: "Tellement de films et séries à découvrir ! J'adore SOFA vod !"
        // }, {
        //     imageAVIF: kevinAVIF,
        //     image: kevin,
        //     name: "Kévin Graillot",
        //     text: "Depuis que je me suis abonné à SOFA vod je peux regarder des films depuis mon yacht."
        // }, {
        //     imageAVIF: guillaumeAVIF,
        //     image: guillaume,
        //     name: "Guillaume Pissot",
        //     text: "Grâce à SOFA vod j'ai été promu directeur !"
        // })
        // setListItemsAvis(avis);
    }, [])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const mousseScroll = () => {
        const ele = document.getElementById('scrollList');

        let pos = { top: 0, left: 0, x: 0, y: 0 };

        const mouseDownHandler = function (e) {
            clearTimeout(timerRef.current);
            clearTimeout(returnTimerRef.current);
            ele.style.cursor = 'grabbing';
            ele.style.userSelect = 'none'

            pos = {
                left: ele.scrollLeft,
                // Get the current mouse position
                x: e.clientX
            };

            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        };

        const mouseMoveHandler = function (e) {
            // How far the mouse has been moved
            const dx = e.clientX - pos.x;

            // Scroll the element
            ele.scrollLeft = pos.left - dx;
        };

        const mouseUpHandler = function () {
            ele.style.cursor = 'grab';
            ele.style.removeProperty('user-select');
            setReturnTimer(setTimeout(() => {
                autoScroll();
                setReturnController(false);
            }, 2000))

            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
        };

        const autoScroll = function () {
            setAutoScrollTimer(setTimeout(() => {
                setLastPosition(ele.scrollLeft);
                if (!leftScrollRef.current) {
                    ele.scrollBy(1, 0);
                } else {
                    ele.scrollBy(-1, 0);
                }
                if (ele.scrollLeft === positionRef.current && !returnRef.current) {
                    clearTimeout(timerRef.current);
                    setReturnController(true);
                    clearTimeout(returnTimerRef.current);
                    setLeftScroll(!leftScrollRef.current);
                    setReturnTimer(setTimeout(() => {
                        autoScroll();
                        setReturnController(false);
                    }, 2000))
                } else if (!returnRef.current) {
                    autoScroll();
                }
            }, 20))
        }

        // Attach the handler
        ele.addEventListener('mousedown', mouseDownHandler);
        setAutoScrollTimer(setTimeout(() => {
            autoScroll();
        }, 2000))
    }

    useEffect(() => {
        return () => clearTimeout(autoScrollTimer);
    }, [autoScrollTimer])

    const defaultImage = (style, width, height, title, clas) => {
        let picture = document.createElement("picture");
        picture.setAttribute("class", classes.pictureDefault);
        let source = document.createElement("source");
        source.setAttribute("srcSet", noImageAVIF);
        source.setAttribute("type", "image/avif");
        let img = document.createElement("img");
        img.setAttribute("src", noImage);
        let jsonStyle = {};
        Object.values(JSON.parse(JSON.stringify(style))).forEach(element => {
          jsonStyle[element] = style[element];
        })
        if (JSON.stringify(jsonStyle).replace(/["'{}]/g, "").length > 0) {
            img.setAttribute("style", JSON.stringify(jsonStyle).replace(/","/g, ";").replace(/["'{}]/g, ""));
        }
        if (width) {
            img.setAttribute("width", width);
        }
        if (height) {
            img.setAttribute("height", height);
        }
        if (clas) {
            img.setAttribute("class", clas);
        }
        let text = document.createElement("h3");
        text.setAttribute("class", classes.textDefault);
        text.appendChild(document.createTextNode(title));
        picture.appendChild(source);
        picture.appendChild(img);
        picture.appendChild(text);
        return picture;
    }

    return (
        <div class="background-gradient">
            <nav id="sidebar-wrapper">
                <a id="menu-close" href="#" class="close-btn toggle"><i class="ion-ios-close-empty"></i></a>
                <ul class="sidebar-nav">
                    <li><Link to={'/login'} class="clickableLink">Se connecter</Link></li>
                    <li><a href="https://moncompte.sofavod.com/web/signup?redirect=https%3A%2F%2Fmoncompte.sofavod.com%2Fshop">S'abonner</a></li>
                </ul>
            </nav>
            <header class={window.scrollY >= 100 ? "sticky fixed" : "sticky"} id="header">
                <div id="logo_menu" class="row">
                    {width > 768 && <section class="col-6"><img src={sofaLogo} height={70} /></section>}
                    <section class={width > 768 ? "col-6 btn-container" : "col-12 btn-container"}>
                        <div id="button-toggle">
                            <a href="/login" class="polo-btn-3 clickableLink">Se connecter</a>
                            {width > 768 && <div class="subscribe-btn-container">
                                <a href="https://moncompte.sofavod.com/web/signup?redirect=https%3A%2F%2Fmoncompte.sofavod.com%2Fshop" class="polo-btn-2">S'abonner</a>
                            </div>}
                        </div>
                        {/* <div>
                            <a id="menu-toggle" href="#" style={{position:"absolute", top:15,right:30,width:44}} class="toggle  rotateIn" data-wow-delay="1s"><i class="ion-navicon"></i></a>
                        </div> */}
                    </section>
                </div>
            </header>


            {width > 768 ? <section id="banner" class="mainImage" style={(width/height) > (18/9) && width >= 3840 ? { backgroundImage: "url(https://ik.imagekit.io/sofavod/Haut_de_page_21-9_degrade.png)" } : 
            (width/height) > (18/9) ? { backgroundImage: "url(https://ik.imagekit.io/sofavod/Haut_de_page_21-9_degrade.png?tr=w-" + width + ",h-" + ((width*1440)/3840) + ",dpr-" + window.devicePixelRatio.toString() + ")" } :
            (width/height) > (14/9) && width >= 3840 ? { backgroundImage: "url(https://ik.imagekit.io/sofavod/Haut_de_page_16-9_degrade.png)" } : 
            (width/height) > (14/9) ? { backgroundImage: "url(https://ik.imagekit.io/sofavod/Haut_de_page_16-9_degrade.png?tr=w-" + width + ",h-" + ((width*2160)/3840) + ",dpr-" + window.devicePixelRatio.toString() + ")" } :
            width >= 3157 ? { backgroundImage: "url(https://ik.imagekit.io/sofavod/Haut_de_page_4-3_degrade.png)" } : 
            (width >= height) ? { backgroundImage: "url(https://ik.imagekit.io/sofavod/Haut_de_page_4-3_degrade.png?tr=w-" + width + ",h-" + ((width*2148)/3157) + ",dpr-" + window.devicePixelRatio.toString() + ")" } :
            { backgroundImage: "url(https://ik.imagekit.io/sofavod/Haut_de_page_4-3_degrade.png?tr=w-" + ((height*3157)/2148) + ",h-" + height + ",dpr-" + window.devicePixelRatio.toString() + ")" }}>
                <section class="container main-container headings mobileFilms">

                    <h1>Des films et séries uniques<br />dans ton canapé</h1>

                    {/* <p class="wow animated fadeInLeft">Par des <span class="underline">réalisateurs indépendants</span><br />Français et <span class="underline">proches de vous</span></p>
                    <p style={{ fontSize: 15, opacity: 1, padding: 2, marginTop: -10 }} class="wow animated fadeInDown">A partir de <span class="colorOrange">5,50 €</span> par mois</p> */}

                    <div>
                        <a href="https://moncompte.sofavod.com/web/signup?redirect=https%3A%2F%2Fmoncompte.sofavod.com%2Fshop" class="polo-btn">Je m'abonne</a>
                        <p class="price-text">À partir de 4,99€/mois</p>
                        <p>Sans engagement, annulable à tout moment.</p>
                    </div>
                    {/* <p style={{ fontSize: 14, opacity: 1, fontStyle: 'Italic' }}>Testez 7 jours<br />Sans engagement</p> */}
                    {//<a class="progButton" href="https://youtu.be/viusXnoxwus?t=588" target="_blank" rel="noreferrer">Découvrez la programmation Oct-Nov-Dec</a><br/>
                    }
                    <div></div>
                    <a class="slidedown" href="#features"><i class="fa-solid fa-chevron-down"></i></a>


                    {/*<div class="col-md-6 hidden-xs hidden-sm" id="imageFilms">
                        <div class="hand-container" style={{position:"absolute", right:0, left:"50%",bottom:0}}>
                            <img class="iphone-hand img_res  animated bounceInUp" data-wow-duration="1.2s" src={"https://ik.imagekit.io/sofavod/Films_accueil_long_film_janvier_1.png?tr=w-" + ((height*3946)/1850) + ",h-" + height } alt="" loading="lazy" ></img>
                            <div class="clearfix"></div>
                        </div>
                        <div style={{ marginTop: 157 }}>
                            <h4 style={{ color: "#FFF", marginBottom: "20px", whiteSpace: "nowrap" }}>Découvrons ensemble la <span class="colorOrange">programmation</span> des prochains mois :</h4>
                            <iframe style={{ borderRadius: "5px" }} width="654" height="367.875" src="https://www.youtube-nocookie.com/embed/QgpW4ne_N7c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>*/}
                </section>
            </section> : <section>
                <section class="sofa-logo" style={{ backgroundImage: "url(https://ik.imagekit.io/sofavod/Haut_de_page_4-3_degrade.png?tr=w-" + width + ",h-" + ((width*2148)/3157) + ",dpr-" + window.devicePixelRatio.toString() + ")", height: (width*2148)/3157 }}>
                    <img src={sofaLogo} height={70} />
                </section>
                <div class="headings mobileFilms">

                    <h2>Des films et séries uniques<br />dans ton canapé</h2>

                    {/* <p class="wow animated fadeInLeft">Par des <span class="underline">réalisateurs indépendants</span><br />Français et <span class="underline">proches de vous</span></p>
                    <p style={{ fontSize: 15, opacity: 1, padding: 2, marginTop: -10 }} class="wow animated fadeInDown">A partir de <span class="colorOrange">5,50 €</span> par mois</p> */}


                    <a href="https://moncompte.sofavod.com/web/signup?redirect=https%3A%2F%2Fmoncompte.sofavod.com%2Fshop" class="polo-btn front-element">Je m'abonne</a>
                    {/* <p style={{ fontSize: 14, opacity: 1, fontStyle: 'Italic' }}>Testez 7 jours<br />Sans engagement</p> */}
                    {//<a class="progButton" href="https://youtu.be/viusXnoxwus?t=588" target="_blank" rel="noreferrer">Découvrez la programmation Oct-Nov-Dec</a><br/>
                    }
                    <p class="price-text">À partir de 4,99€/mois</p>
                    <p>Sans engagement, annulable à tout moment.</p>

                </div>

                <div class="center-div">
                    <a class="slidedown" href="#features"><i class="fa-solid fa-chevron-down"></i></a>
                </div>

                {/*<div class="col-md-6 hidden-xs hidden-sm" id="imageFilms">
                    <div class="hand-container" style={{position:"absolute", right:0, left:"50%",bottom:0}}>
                        <img class="iphone-hand img_res  animated bounceInUp" data-wow-duration="1.2s" src={"https://ik.imagekit.io/sofavod/Films_accueil_long_film_janvier_1.png?tr=w-" + ((height*3946)/1850) + ",h-" + height } alt="" loading="lazy" ></img>
                        <div class="clearfix"></div>
                    </div>
                    <div style={{ marginTop: 157 }}>
                        <h4 style={{ color: "#FFF", marginBottom: "20px", whiteSpace: "nowrap" }}>Découvrons ensemble la <span class="colorOrange">programmation</span> des prochains mois :</h4>
                        <iframe style={{ borderRadius: "5px" }} width="654" height="367.875" src="https://www.youtube-nocookie.com/embed/QgpW4ne_N7c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>*/}
            </section>}



            <section id="features" class="block row main-section">
                {width > 768 ? <div style={{ display: "flex" }}><section class="col-md-6 section-container">
                    <div class="front-element"><div class="title-box"><h2 class="sofa-title start-title">

                        Le plus grand choix de films et séries émergents

                    </h2></div>
                    <div class="list-box">
                        <div><img class="list-element" src={exclusif} height={12} />Des films exclusifs et inédits</div>
                        <div><img class="list-element" src={exclusif} height={12} />Des nouveautés toutes les semaines</div>
                        <div><img class="list-element" src={exclusif} height={12} />Dans 25 genres différents</div>
                        <div><img class="list-element" src={exclusif} height={12} />Des centaines de courts-métrages récompensés en festival</div>
                    </div></div>
                </section>
                <section class="row col-md-6">
                    {loading ? <div class="loading-div">
                        <CircularProgress />
                    </div> : <div class="list-item-container">
                        <ul id="scrollList" class="scroll">
                            {listItems.map(item => <li class="element-list">
                                <a href={"/itemShare/" + item.Id} draggable={false} target="_blank">
                                    <img class="imageList" src={item.portrait_title_path + "?tr=w-200,h-300,dpr-" + window.devicePixelRatio.toString()} width={200} height={300} onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,200,300,item.title,"imageList"))}} />
                                    {item.isExclusif == 1 && <div class="logo-exclu"><img src={exclusif} alt="Exclusif" /></div>}
                                </a>
                            </li>)}
                        </ul>
                    </div>}
                </section></div> : <div id="listMobile"><section class="row col-md-6">
                    {loading ? <div class="loading-div">
                        <CircularProgress />
                    </div> : <ul id="scrollList" class="scroll">
                            {listItems.map(item => <li class="element-list">
                                <a href={"/itemShare/" + item.Id} draggable={false} target="_blank">
                                    <img class="imageList" src={item.portrait_title_path + "?tr=w-200,h-300,dpr-" + window.devicePixelRatio.toString()} width={200} height={300} onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,200,300,item.title,"imageList"))}} />
                                    {item.isExclusif == 1 && <div class="logo-exclu"><img src={exclusif} alt="Exclusif" /></div>}
                                </a>
                            </li>)}
                        </ul>}
                </section>
                <section id="listText" class="col-md-6 section-container">
                    <div class="title-box"><h2 class="sofa-title start-title">

                        Le plus grand choix de films et séries émergents

                    </h2></div>
                    <div class="list-box">
                        <div><img class="list-element" src={exclusif} height={12} />Des films exclusifs et inédits</div>
                        <div><img class="list-element" src={exclusif} height={12} />Des nouveautés toutes les semaines</div>
                        <div><img class="list-element" src={exclusif} height={12} />Dans 25 genres différents</div>
                        <div><img class="list-element" src={exclusif} height={12} />Des centaines de courts-métrages récompensés en festival</div>
                    </div>
                </section></div>}
                <section class="row col-md-6 center-div">
                    <div class="center-div">
                        <img src={"https://ik.imagekit.io/sofavod/accueil/Departements_France.png?tr=w-500,h-500,dpr-" + window.devicePixelRatio.toString()} class="france-map" width="100%" loading="lazy" />
                    </div>
                </section>
                <section class="col-md-6 real-element">
                    <div class="title-box"><h2 class="sofa-title start-title">

                        Des réalisateurs proches de chez vous

                    </h2></div>
                    <div class="list-box">
                        <div><img class="list-element" src={exclusif} height={12} />Contenu issu de nos départements</div>
                        <div><img class="list-element" src={exclusif} height={12} />Une rémunération juste dès la première minute</div>
                        <div><img class="list-element" src={exclusif} height={12} />Des oeuvres en circuit court</div>
                        <div style={{margin:0}}><img class="list-element" src={exclusif} height={12} />Si votre voisin fait des bons films, ils sont sur SOFA !*</div>
                        <small style={{ fontSize: 10, marginLeft: 50 }}>* peut-être</small>
                    </div>
                </section>
            </section>



            <section id="team" class="block section-container main-section">
                <section class="row">
                    <div class="title-box">
                        <h1 class="sofa-title">
                            Disponible sur tous les écrans
                        </h1>
                        {/* <h2 style={{ fontSize: 15, color: '#FFF' }} class="block-title wow animated fadeInLeft">Smartphones (Android, iOS), tablettes et sur le web.</h2> */}

                    </div>
                </section>
                <section class="row container">
                    <img class="img_res2" src="https://ik.imagekit.io/sofavod/accueil/Mockup_Sofa_2.3_1280.png" width="100%" loading="lazy" />
                    <h2 style={{ fontSize: 15, color: '#FFF' }} class="block-title">L’accès sur TV est disponible via Airplay et Chrome Cast</h2>
                </section>
            </section>



            <section id="testimonial" class="block section-container">
                {/* <div>
                <section class="container" style={{ paddingRight: 20, paddingLeft: 20 }}>
                    <section class="row">
                        <div class="title-box">
                            <h1 class="sofa-title">
                                Ils ont testé SOFA
                            </h1>
                            <picture style={{ display: "flex", justifyContent: "center" }}>
                                <source srcSet={TrustPilotAVIF} type="image/avif" />
                                <img width={300} src={TrustPilot} />
                            </picture>
                        </div>
                    </section>
                </section>
                <section style={width > 768 ? { padding: 30 } : { padding: "30px 0" }}>
                    <section class="row">
                        <section class="col-sm-12">
                            <div id="review">
                                <div style={{ width: "100%" }} class="itemH">
                                    <ul style={width > 768 ? { width: "100%", justifyContent: "space-evenly", cursor: "auto" } : { margin: 0, width: width }} class="scroll">
                                        {listAvis.map(avis => <li style={{ maxWidth: 300, minWidth: 200, margin: "0 15px" }}>
                                            <div class="row">
                                                <div class="col-sm-12 col-sm-offset-1 client-element">
                                                    <div class="client-pic" style={{ width: 100,
                                                        height: 100,
                                                        borderRadius: "50%",
                                                        overflow: "hidden",
                                                        display: "flex",
                                                        justifyContent: "center" }}>
                                                        <picture>
                                                            <source srcSet={avis.imageAVIF} type="image/avif" />
                                                            <img class="img_res2" src={avis.image} width={150} />
                                                        </picture>
                                                    </div>
                                                    <p class="client-name" style={{ whiteSpace: "normal" }}>
                                                        {avis.name}
                                                    </p>
                                                    <p class="review-desc" style={{ whiteSpace: "normal", textAlign: "justify" }}>
                                                        {avis.text}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>)}
                                    </ul>
                                </div>
                            </div>
                        </section>
                    </section>
                </section>
                </div> */}
                <div class="headings mobileFilms" style={{ padding: 0, height: "auto" }}>
                    <a href="https://moncompte.sofavod.com/web/signup?redirect=https%3A%2F%2Fmoncompte.sofavod.com%2Fshop" class="polo-btn">Je m'abonne</a>
                    <p class="price-text">À partir de 4,99€/mois</p>
                    {/* <p style={{ fontSize: 14, opacity: 1, fontStyle: 'Italic' }}>Testez 7 jours<br />Sans engagement</p> */}
                    {//<a class="progButton" href="https://youtu.be/viusXnoxwus?t=588" target="_blank" rel="noreferrer">Découvrez la programmation Oct-Nov-Dec</a><br/>
                    }
                    <p>Sans engagement, annulable à tout moment.</p>
                </div>
            </section>


            <section id="contact" class="mailbox">


                <div class="container question-block">
                    <div class="title-box"><h1 class="sofa-title">

                            Des questions ?

                        </h1>
                    </div>
                    <div class="col-sm-12">
                        <Accordion style={{ borderColor: '#faa74a', }} square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary style={{ backgroundColor: 'rgba(27, 27, 27, 1)', color: '#FFF' }} aria-controls="panel1d-content" id="panel1d-header">
                                <Typography className={classes.heading} style={{ fontSize: 17 }}>SOFA vod qu'est-ce que c'est ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{ fontSize: 12 }}>
                                    <p>SOFA est la plateforme de vidéo à la demande qui offre le plus grand choix de films et séries issus du cinéma émergent français.</p>
                                    <p>Nous avons pour objectif de promouvoir et soutenir des créateurs et œuvres uniques souvent mis de côté par les autres plateformes.</p>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ borderColor: '#faa74a' }} square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary style={{ backgroundColor: 'rgba(27, 27, 27, 1)', color: '#FFF' }} aria-controls="panel2d-content" id="panel2d-header">
                                <Typography className={classes.heading} style={{ fontSize: 17 }}>Quel est le prix de SOFA vod ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{ fontSize: 12 }}>
                                    <p>L’abonnement mensuel est à 5,99€. Sans engagement.</p>
                                    <p>Soyez malin et profitez de l’entièreté du catalogue en illimité pour 59,99€/an (Soit 2 mois offerts)</p>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ borderColor: '#faa74a' }} square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary style={{ backgroundColor: 'rgba(27, 27, 27, 1)', color: '#FFF' }} aria-controls="panel3d-content" id="panel3d-header">
                                <Typography className={classes.heading} style={{ fontSize: 17 }}>Comment nous soutenons les créateurs ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{ fontSize: 12 }}>
                                    <p>Nous dédions 50% de nos revenus aux créateurs qui sont ainsi rémunérés dès la première vue.</p>
                                    <p>Ils bénéficient également de nos moyens de communication ainsi que d’une mise en avant sur nos espaces de diffusion. Un bon moyen de valoriser leurs œuvres !</p>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ borderColor: '#faa74a' }} square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary style={{ backgroundColor: 'rgba(27, 27, 27, 1)', borderBottomWidth: 1, borderBottomColor: '#faa74a', color: '#FFF' }} aria-controls="panel4d-content" id="panel4d-header">
                                <Typography className={classes.heading} style={{ fontSize: 17 }}>Puis-je annuler mon abonnement ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{ fontSize: 12 }}>
                                    <p>Bien sûr ! Vous pouvez arrêter votre abonnement à tout moment à partir de votre espace client.</p>
                                    <p>Si vous avez opté pour l’abonnement annuel, vous pourrez continuer à profiter du catalogue jusqu’à la fin de la période.</p>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                    </div>
                </div>

                <div class="container text-center mt-5 flag-container">
                    <span>Conçu en France <i class="fa-solid fa-heart flag-heart"></i></span>
                    <div class="row justify-content-center mt-1">
                        <div class="col-md-1 col-1 col-sm-1 flag-item" style={{ backgroundColor: '#002654' }}></div>
                        <div class="col-md-1 col-1 col-sm-1 flag-item" style={{ backgroundColor: '#FFF' }}></div>
                        <div class="col-md-1 col-1 col-sm-1 flag-item" style={{ backgroundColor: '#ED2939' }}></div>
                    </div>
                </div>
            </section>

            <section id="footer" >
                <section class="container footer-container">
                    <div class="row text-center justify-content-around">

                        <div class="col-md-2">
                            <ul class="footer-list">
                                {/* <li><a href="">Qui sommes-nous ?</a></li>
                        <li><a href="">Presse</a></li> */}
                                <li><a class="footerButton" href="https://salon.sofavod.com/emission-sofa-live/" target="_blank">L'émission SOFA live</a></li>
                                <li><br></br></li>
                                {/* <li><a href="">Centre d'aide abonnés</a></li> */}
                                <li><a class="footerButton" href="https://moncompte.sofavod.com/assistance" target="_blank">Nous contacter</a></li>
                            </ul>
                        </div>
                        <div class="col-md-2">
                            <ul class="footer-list">
                                <li><a class="footerButton" href="https://createurs.sofavod.com" target="_blank">Je suis créateur</a></li>
                                {/* <li><a href="">Centre d'aide créateurs</a></li>
                        <li><a href="">Ressources pour les créateurs</a></li> */}
                                <li><br></br></li>
                                <li><a class="footerButton" href="https://www.studio.sofavod.com" target="_blank">SOFA Studio</a></li>
                            </ul>
                        </div>
                        <div class="col-md-3">
                            <ul class="footer-list">
                                <li><a class="footerButton" href="https://moncompte.sofavod.com/politique-confidentialite" target="_blank">Mentions légales</a></li>
                                <li><br></br></li>
                                <li style={{ color: '#FFF', fontSize: 14 }}><a class="footerButton" href="https://moncompte.sofavod.com/web/content/6514?unique=77115ff3cf5e1a666d520fc3235e0b0af785843a" target="_blank">CGU</a> / <a class="footerButton" href="https://moncompte.sofavod.com/web/content/6515?unique=7a1d2ac8753b50311212944b625cb2fe8918f6a5" target="_blank">CGV</a></li>
                            </ul>
                        </div>
                        <div class="col-md-2">
                            <ul class="footer-list">
                                <li><a class="footerButton" href="https://salon.sofavod.com/" target="_blank">Notre Entreprise</a></li>
                                <li><br></br></li>
                                <li><button class="footerButton" type="button" onClick={() => {
                                    if (document.location.href.search(/#features/g) == -1) {
                                        document.location += "#tarteaucitron";
                                    } else {
                                        document.location = document.location.href.slice(0, document.location.href.length - "#features".length) + "#tarteaucitron";
                                    }
                                }}>Cookies</button></li>
                            </ul>
                        </div>
                        <div id="rs" class="col-md-3">
                            <a href="https://www.facebook.com/SOFAvod" target="_blank"><i class="fa-brands fa-facebook"></i></a>
                            <a href="https://www.instagram.com/sofavod/" target="_blank"><i class="fa-brands fa-instagram"></i></a>
                            <a href="https://www.youtube.com/channel/UC6YjATNUcv8dmynXatIsUGA" target="_blank"><i class="fa-brands fa-youtube"></i></a>
                            <a href="https://www.tiktok.com/@sofa_vod" target="_blank"><i class="fa-brands fa-tiktok"></i></a>
                        </div>

                    </div>
                </section>
            </section>
        </div>

    );
}