import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
//import "bootstrap/dist/js/bootstrap.js";
import "./App.css";

import AuthService from "./services/auth.service";
import Detail from "./views/DetailPage/DetailPage"
import People from "./views/PeoplePage/PeoplePage"
import Login from "./views/LoginPage/LoginPage";
import Search from "./views/SearchPage/SearchPage";
import Home from "./views/LandingPage/LandingPage";
// import HomePage from './views/LandingPage/HomePage';
// import Home2 from "./views/LandingPage/HomePageV2";
import MySofa from "./views/MySofaPage/MySofaPage";
import ItemShare from "./views/ItemShare/ItemShare";
// import Register from "./views/register.screen";
//import Home from "./views/home.screen";
import Profile from "./views/ProfilePage/ProfilePage";
import Genre from "./views/GenrePage/GenrePage";
import Departement from "./views/DepartementPage/DepartementPage";
import Productor from "./views/ProductorPage/ProductorPage";
import Player from "./views/PlayerPage/PlayerPage";
import Unsubscribe from "./views/UnsubscribePage/UnsubscribePage";
import FourOFour from "views/FourOFourPage/FourOFourPage";
// import Signup from "views/SignupPage/SignupPage";
import Welcome from 'views/WelcomePage/WelcomePage';
import Theme from 'views/ThemePage/ThemePage';
// import HomePage from "views/LandingPage/HomePage";
import ConfirmMail from 'views/ConfimMailPage/ConfirmMailPage';


class App extends Component {
  constructor(props) {
    super(props);
   

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
  }



  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login">
          <Login authed={true}/> 
        </Route>
        {/* <Route exact path="/signup" component={Signup} /> */}
        <Route exact path="/welcome" component={Welcome} />
        <Route path="/itemShare/:id" component={ItemShare}>
        </Route>
        {/* <Route exact path="/register" component={Register} /> */}
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/detail/:id" component={Detail} />
        <Route exact path="/people" component={People} />
        <Route exact path="/search" component={Search} />
        <Route exact path="/mysofa" component={MySofa} />
        <Route exact path="/genre" component={Genre} />
        <Route exact path="/player" component={Player} />
        <Route exact path="/departement" component={Departement} />
        <Route exact path="/productor" component={Productor} />
        <Route exact path="/theme" component={Theme} />
        {/* <Route exact path="/home2" component={Home2} /> */}
        <Route exact path="/unsubscribe/:idUser/:email" component={Unsubscribe} />
        <Route exact path="/confirmMail/:idUser/:email" component={ConfirmMail} />
        <Route component={FourOFour} />
      </Switch>
    );
  }
}

export default App;
