import React, { useEffect, useState } from 'react';
import './carousel.css';
import useWindowSize from 'components/useWindowSize';

const MainCarousel = (props) => {
    const {children, show} = props;
    const { width } = useWindowSize();

    const timer = 10;    

    const [currentIndex, setCurrentIndex] = useState(0);
    const [initialLength, setInitialLength] = useState(children.length);
    const [length, setLength] = useState(children.length);

    const [touchPosition, setTouchPosition] = useState(null);
    const [countDown, setCountDown] = useState(0);

    // Set the length to match current children from props
    useEffect(() => {
        setInitialLength(children.length);
        setLength(children.length);
    }, [children])

    /*const startCountDown = () => {
        interval = setInterval(setCountDown(countDown + 1), 1000);
    }*/

    useEffect(() => {
        const timer = setTimeout(() => {
          setCountDown(countDown+1);
        }, 1000);
        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
    }, [countDown]);

    useEffect(()=> {
        if (countDown > timer) {
            setCountDown(0)
            if (currentIndex < (length - 2*show)) {
                setCurrentIndex(prevState => prevState + show);
            } else if (currentIndex < (length - show)) {
                setCurrentIndex(length - show);
            } else if (currentIndex >= length - show){
                setCurrentIndex(0);
                if (length >= initialLength * 2) {
                    children.splice(initialLength, length - initialLength);
                    setLength(children.length);
                }
            }
        }
    }, [countDown])

    const next = () => {
        console.log(currentIndex);
        console.log(length);
        setCountDown(0)
        if (currentIndex < (length - 2*show)) {
            setCurrentIndex(prevState => prevState + show);
        } else if (currentIndex < (length - show)) {
            setCurrentIndex(length - show);
        } else if (currentIndex >= length - show){
            setCurrentIndex(0);
            if (length >= initialLength * 2) {
                children.splice(initialLength, length - initialLength);
                setLength(children.length);
            }
        }
        if (currentIndex == (length - 2)) {
            children.forEach(film => {
                children.push(film);
            })
            setLength(children.length);
        }
    }

    const prev = () => {
        setCountDown(0)
        if (currentIndex > show) {
            setCurrentIndex(prevState => prevState - show);
        } else if (currentIndex > 0) {
            setCurrentIndex(0);
        } else if (currentIndex <= 0) {
            setCurrentIndex(length - show);
        }
    }

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }

    const handleTouchMove = (e) => {
        const touchDown = touchPosition

        if(touchDown === null) {
            return
        }

        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch

        if (diff > 5) {
            next()
        }

        if (diff < -5) {
            prev()
        }

        setTouchPosition(null)
    }

    return (
        <div className="carousel-container">
            <div className="carousel-wrapper">
                {/* You can alwas change the content of the button to other things */}
                    <button onClick={prev}  className="left-arrow">
                        <i class="fa-solid fa-chevron-left"></i>
                    </button>
                <div
                    className="carousel-content-wrapper-2"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                >
                    <div
                        className={`carousel-content-main`}
                        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                    >
                        {children}
                    </div>
                </div>
                {/* You can always change the content of the button to other things */}
                    <button onClick={next} className="right-arrow">
                        <i class="fa-solid fa-chevron-right"></i>
                    </button>
            </div>
        </div>
    )
}

export default MainCarousel;