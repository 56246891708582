import axios from "axios";
import UrlAPI from './UrlAPI'

export function getByParams(pi_token, json) {

    const url = UrlAPI.sUrl+'/item/getByParams'
    return axios(url,{
        method: 'post',
        data: json,
        headers:{
            'Content-Type': "application/json",
            'x-access-token': pi_token
        },
    })
}