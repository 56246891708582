// API/AddEndItemAPI.js
import axios from "axios";
import UrlAPI from "./UrlAPI"

export function addEndItemAPI(pi_token, pi_idSession, pi_endTime, pi_endHour) {
    
  const url = UrlAPI.sUrl+'/user/upUserPlayer/'+pi_idSession.toString()
  return axios(url,{
    method: 'put',
    data:{
       endTime:pi_endTime,
       endHour:pi_endHour
      } ,
    headers:{
        'Content-Type': "application/json",
        'x-access-token': pi_token
    },
    })  
}