/*eslint-disable*/
import React, { useState,useEffect } from 'react'
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import {
  isMobile,
  isIOS, // ------ utiliser pour choisir le lien via Apple Store 
  isAndroid // ------ utiliser pour choisir le lien via Google Play
} from "react-device-detect";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import logoApp from "assets/img/Logo_App.png"
import { BottomSheet } from 'react-spring-bottom-sheet'
import { TramRounded } from '@material-ui/icons';
import {useHistory,useLocation} from "react-router-dom";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const [open, setOpen] = useState(false);
  const location = useLocation();


  React.useEffect(() => {
    if (isMobile && localStorage.getItem('mobile') != "no" && location.pathname.search("/itemShare") === -1) {
      setTimeout(() => {
        verifyPopUpMobile();
      }, 1000)
    }
  });

  const verifyPopUpMobile = () => {
    if (document.getElementById("tarteaucitronAlertBig").style.display != "none") {
      setTimeout(() => {
          verifyPopUpMobile()
      }, 1000)
    } else {
      setOpen(true);
    }
  }

    function onDismiss() {
      setOpen(false)
      localStorage.setItem("mobile", "no");

    }
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses} >
    {/* <BottomSheet
          open={open}
          onDismiss={onDismiss}

        >
         {open && <div className={classes.mobilePopUp}>
            <p>
             Afin d'optimiser votre expérience avec SOFA vod, nous vous conseillons de télécharger notre application 
            </p>
    

              <a href={isIOS ? "https://apps.apple.com/fr/app/sofa-vod/id1525885609" : isAndroid ? "https://play.google.com/store/apps/details?id=com.kevingraillot.sofa" : ""}>
                <img src={logoApp} width={60} />
                Télécharger <br/>notre application
              </a>
              <div className="bg-gray-200 block rounded-md h-10 w-full my-10" />
          
            <Button onClick={onDismiss} className="w-full">
              Ne plus afficher
            </Button>
            </div>}
        </BottomSheet> */}
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
{/*            
            <ListItem className={classes.inlineBlock}>
              <a
                href=""
                className={classes.block}
                style={{color:"#FFF"}}
                target="_blank"
              >
                A propos
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href=""
                className={classes.block}
                style={{color:"#FFF"}}
                target="_blank"
              >
                Blog
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href=""
                className={classes.block}
                style={{color:"#FFF"}}
                target="_blank"
              >
                FAQ
              </a>
            </ListItem> */}
          </List>
        </div>
        <div className={classes.left}>
          <a className={classes.navLink} target="_blank" rel="noreferrer" href="https://moncompte.sofavod.com/assistance">Besoin d'aide ?</a>
          <button className={classes.navLink} type="button" onClick={() => {document.location += "#tarteaucitron"}}>Cookies</button>
        </div>
        <div className={classes.right}>
            Site version 1.6.4
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
