import React, { useState, useEffect, useRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Link,
  useLocation,
  useParams
} from "react-router-dom";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Parallax from "components/Parallax/Parallax.js";
import GridList from '@material-ui/core/GridList';

import GridListTile from '@material-ui/core/GridListTile';
import AuthService from "../../services/auth.service.js";
import PropTypes from 'prop-types';
import { getItemById } from '../../API/ItemByIdAPI';
import { useHistory } from 'react-router-dom';
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import Carousel from 'components/CarouselMulti/Carousel';
import useWindowSize from 'components/useWindowSize';
import { Popover } from "@material-ui/core";

import { Box } from "@material-ui/core";
import { ReactVideo } from '../../components/Video/Video.js';
//import  ReactHlsPlayer  from '../../components/Video/ReactHlsPlayer.js';
import { addUserItem } from '../../API/AddUserItemAPI';
import { upUserItem } from '../../API/UpUserItemAPI';
import exclusif from '../../assets/img/exclusif.png';
import exclusifLarge from "assets/img/exclusif_large.png";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import noImageAVIF from "assets/img/Fond_lampadaire.avif";
import noImage from "assets/img/Fond_lampadaire.jpg";
import noImagePAVIF from "assets/img/Fond_lampadaire_Portrait.avif";
import noImageP from "assets/img/Fond_lampadaire_Portrait.jpg";


const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(styles);
const useStylesList = makeStyles(() => ({
  listContainer: {
    justifyContent: 'flex-start',
    height: 260,
    width: "100vw !important",
    margin: "0 0 0 -90px",
    paddingRight: 18
  },
  listContainerM: {
    justifyContent: 'flex-start',
    height: 260,
    margin: "0 -15px",
    width: "100vw !important"
  },
  title: {
    textTransform: "uppercase",
    fontSize: "2em"
  }
}));
const dashboardRoutes = [];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DetailPage(props) {
  const location = useLocation()
  const nWidthPeople = 120;
  const nHeightPeople = 120;
  const classes = useStyles();
  const classesList = useStylesList();
  const [isLoading, setLoading] = useState(true);
  const { ...rest } = props;
  const { width } = useWindowSize();
  const [item, setItem] = useState({});
  let id = props.match.params.id;
  const [tabSeason, setTabSeason] = useState([]);
  const [tabProductor, setTabProductor] = useState([]);
  const [tabRegion, setTabRegion] = useState([]);
  const [tabGenre, setTabGenre] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const share = open ? 'simple-popover' : undefined;
  const copied = open2 ? 'simple-popover' : undefined;

  const [respItem, setRespItem] = useState({});

  const [tabActorData, setTabActorData] = useState([]);
  const [tabRealData, setTabRealData] = useState([]);
  const [tabTechData, setTabTechData] = useState([]);
  const [tabSugg, setTabSugg] = useState([]);
  const [value, setValue] = useState(0);
  const [userItems, setUserItems] = useState([]);
  const [isList, setList] = useState(false);
  const [isFavorite, setFavorite] = useState(false);
  const [tabAge, setTabAge] = useState([]);
  const [continu, setContinu] = useState(false);
  const [newToken, setNewToken] = useState(false);

  const tokenRef = useRef();

  tokenRef.current = newToken;

  //const offset = useRef(new Animated.Value(0)).current;
  const [seasons, setSeasons] = useState([]);
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  let history = useHistory();

  let currentUser = AuthService.getCurrentUser();

  if (currentUser == null) {
    history.push('/login');
  }

  const fetchData = async () => {
    const respItemC = await getItemById(currentUser.accessToken, id, currentUser.id);

    if (respItemC.data.length == 0) {
      history.push('/');
    }

    //   console.log(itemSugg.data)

    respItemC.data.forEach(film => {

      let UserItems = [];
      if (film.Table_User_Items.length > 0) {
        film.Table_User_Items.forEach(element => {
          if (element.idMyBanner == 2) {
            setList(true);
            UserItems.push(element);
          } else if (element.idMyBanner == 3) {
            setFavorite(true);
            UserItems.push(element);
          }
        })
      }
  
      setUserItems(UserItems);
  
      //  i++;
      setTabSeason(film.Table_Seasons);
      if (film.Table_User_Players.length > 0) {
        if (film.idType == 1) {
          setContinu(true);
          let newTableSeason = film.Table_Seasons;
          film.Table_User_Players.forEach(userPlayer => {
            if (userPlayer.idEpisode != 0) {
              if (userPlayer.endTime < (userPlayer.totalTime * 0.95) && userPlayer.endTime > 10) {
                let index = newTableSeason.findIndex(x => x.id == userPlayer.idEpisode);
                newTableSeason[index].start = userPlayer.endTime - 5;
                newTableSeason[index].tTotalTime = userPlayer.totalTime;
              } else if (userPlayer.endTime > 10) {
                let index = newTableSeason.findIndex(x => x.id == userPlayer.idEpisode);
                newTableSeason[index].start = 0;
              }
            }
          })
          let nIndex = newTableSeason.findIndex(x => x.start != 0);
          if (nIndex === -1) {
            setContinu(false);
          }
          setTabSeason(newTableSeason);
        } else {
          const userPlayer = film.Table_User_Players[0];
          if (userPlayer.endTime < (userPlayer.totalTime * 0.95) && userPlayer.endTime > 10) {
            setContinu(true);
            film.start = userPlayer.endTime - 5;
          }
        }
      }
      const suggestion = film.Table_Item_Suggestions.map(item => item.Table_Item);
      if (suggestion.length > 0) {
        film.Table_Suggestion = suggestion;
      }
      setItem(film);
      setTabProductor(film.Table_Productor);
      setTabGenre(film.categories);
      setTabRegion(film.Table_Region);
      setTabAge(film.Table_Age_Limit);

      if (film.idType == 1) {
        const saisons = [... new Set(film.Table_Seasons.map(ep => ep.season))];
        setSeasons(saisons.sort());
      }
      let tabActorDataTemp = [];
      let tabRealDataTemp = [];
      let tabTechDataTemp = [];
      film.Table_People_Projects.sort((a,b) => a.idFunction - b.idFunction).forEach(people => {
        let oPeople = people.Table_Person;

        if (people.idFunction == 1) {
          tabRealDataTemp.push({ name: oPeople.name, photo_path: oPeople.photo_path, function: people.description, id: oPeople.id, description: oPeople.description });
        }
        if (people.idFunction == 3) {
          tabActorDataTemp.push({ name: oPeople.name, photo_path: oPeople.photo_path, function: people.description, id: oPeople.id, description: oPeople.description });
        }
        if (people.idFunction == 2 || people.idFunction == 4) {
          tabTechDataTemp.push({ name: oPeople.name, photo_path: oPeople.photo_path, function: people.description, id: oPeople.id, description: oPeople.description });
        }
      });
      setRespItem(film);
      setTabSugg(suggestion);
      setTabRealData(tabRealDataTemp);
      setTabActorData(tabActorDataTemp);
      setTabTechData(tabTechDataTemp);
    });
    window.scrollTo({top: 0, left: 0, behavior: "instant"});
    setLoading(false);
  }





  // useFocusEffect(
  //   React.useCallback(() => {

  //     setView(true);
  //     if (videoplayer != null) {
  //     videoplayer.setStatusAsync({ shouldPlay: true, positionMillis: 0 })
  //     }
  //     return () => {
  //   //    setView(false);

  //   if (videoplayer != null) {

  //     videoplayer.setStatusAsync({ shouldPlay: false, positionMillis: 0 })
  //     }
  //     };
  //   }, [])
  // );
  // const _handleVideoRef = (component) => {
  //   videoplayer = component;

  // }
  // const _onMutePressed = () => {
  //  if (videoplayer != null) {
  //    videoplayer.setIsMutedAsync(!isMuted);

  //    setIsMuted(!isMuted)

  //  }
  // };
  useEffect(() => {
    const getAPI = async () => {
      let validtoken = true;
      setLoading(true);
      setSeasons([]);
      setList(false);
      setFavorite(false);

      if (currentUser != null) {
        fetchData().catch(error => {
            if (error.response) {
              if (error.response.status == 401) {
                validtoken = false;
              }
              if (error.response.status == 402) {
                validtoken = false;
              }
              if (error.response.status == 403) {
                validtoken = false;
              }
            } else {
              console.log('Error', error.message);
            }
            if (!tokenRef.current && currentUser) {
              AuthService.refreshToken(currentUser.id,currentUser.accessToken).then(() => {
                setNewToken(true);
                currentUser = AuthService.getCurrentUser();
                getAPI();
              }).catch(err => {
                console.log(err);
                AuthService.logout();
                history.push('/login');
              })
            } else {
              console.log(error);
              AuthService.logout();
              history.push('/login');
            }
        })
      } else {
        history.push('/login');
      }
    }
    getAPI();
  }, [location.key]);

  const getPeopleColumns = () => {
    let nPeople = width / 200;
    return Math.trunc(nPeople);
  }

  const getLargePeopleColums = () => {
    let nPeople = width / 300;
    return Math.trunc(nPeople);
  }

  const getGridListCols = () => {
    let thisWidth = 1320 - 250;
    if (width < 840) {
      thisWidth = width - 100;
    } else if (width < 1320) {
      thisWidth = width - 250;
    }
    let nColumns = thisWidth / 200;
    return Math.trunc(nColumns)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const addFavorite = () => {
    addUserItem(currentUser.accessToken, currentUser.id, item.Id, 0, 0, 3).then(response => {
      setFavorite(true);
      let UserItem = userItems;
      UserItem.push({ id: response.data.id, idUser: currentUser.id, IdItem: item.Id, idMyBanner: 3 });
      setUserItems(UserItem);
    })
  }

  const removeFavorite = () => {
    const idUserItem = userItems.find(x => x.IdItem == item.Id && x.idMyBanner == 3).id;
    upUserItem(currentUser.accessToken, idUserItem).then(() => {
      setFavorite(false);
      let UserItem = userItems;
      UserItem.splice(UserItem.findIndex(x => x.IdItem == item.Id && x.idMyBanner == 3), 1);
      setUserItems(UserItem);
    })
  }

  const addList = () => {
    addUserItem(currentUser.accessToken, currentUser.id, item.Id, 0, 0, 2).then(response => {
      setList(true);
      let UserItem = userItems;
      UserItem.push({ id: response.data.id, idUser: currentUser.id, IdItem: item.Id, idMyBanner: 2 });
      setUserItems(UserItem);
    })
  }

  const removeList = () => {
    const idUserItem = userItems.find(x => x.IdItem == item.Id && x.idMyBanner == 2).id;
    upUserItem(currentUser.accessToken, idUserItem).then(() => {
      setList(false);
      let UserItem = userItems;
      UserItem.splice(UserItem.findIndex(x => x.IdItem == item.Id && x.idMyBanner == 2), 1);
      setUserItems(UserItem);
    })
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClick2 = (event) => {
    const text = "https://sofavod.com/itemShare/" + item.Id;
    navigator.clipboard.writeText(text).then(
      () => {
        setAnchorEl2(event.target);
        setTimeout(() => {
          handleClose2();
        }, 500)
      }
    );
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleClose2 = () => {
    setAnchorEl2(null);
  }

  const defaultImage = (style, width, height, title, clas) => {
    let picture = document.createElement("picture");
    picture.setAttribute("class", classes.defaultImgPicture + " " + classes.imageEpisode);
    let source = document.createElement("source");
    source.setAttribute("srcSet", noImageAVIF);
    source.setAttribute("type", "image/avif");
    let img = document.createElement("img");
    img.setAttribute("src", noImage);
    let jsonStyle = {};
    Object.values(JSON.parse(JSON.stringify(style))).forEach(element => {
      jsonStyle[element] = style[element];
    })
    img.setAttribute("style", JSON.stringify(jsonStyle).replace(/","/g, ";").replace(/["'{}]/g, ""));
    img.setAttribute("width", width);
    img.setAttribute("height", height);
    img.setAttribute("class", clas)
    let div = document.createElement("div");
    div.setAttribute("class", classes.defaultImgDiv);
    let text = document.createElement("h3");
    text.setAttribute("class", classes.defaultImgText);
    text.appendChild(document.createTextNode(title));
    div.appendChild(text);
    picture.appendChild(source);
    picture.appendChild(img);
    picture.appendChild(div);
    return picture;
  }

  const defaultImageP = (style, width, height, title) => {
    let picture = document.createElement("picture");
    picture.setAttribute("class", classes.defaultImgPicture + " " + classes.suggImage);
    let source = document.createElement("source");
    source.setAttribute("srcSet", noImagePAVIF);
    source.setAttribute("type", "image/avif");
    let img = document.createElement("img");
    img.setAttribute("src", noImageP);
    let jsonStyle = {};
    Object.values(JSON.parse(JSON.stringify(style))).forEach(element => {
      jsonStyle[element] = style[element];
    })
    img.setAttribute("style", JSON.stringify(jsonStyle).replace(/","/g, ";").replace(/["'{}]/g, ""));
    img.setAttribute("width", width);
    img.setAttribute("height", height);
    let div = document.createElement("div");
    div.setAttribute("class", classes.defaultImgDiv);
    let text = document.createElement("h3");
    text.setAttribute("class", classes.defaultImgText);
    text.appendChild(document.createTextNode(title));
    div.appendChild(text);
    picture.appendChild(source);
    picture.appendChild(img);
    picture.appendChild(div);
    return picture;
  }

  const replaceTitle = (title) => {
    let div1 = document.createElement("div");
    div1.setAttribute("class", classes.replaceTextDiv1);
    let div2 = document.createElement("div");
    div2.setAttribute("class", classes.replaceTextDiv2);
    let text = document.createElement("h1");
    text.setAttribute("class", classes.title + " " + classes.replaceText);
    text.appendChild(document.createTextNode(title));
    div2.appendChild(text);
    div1.appendChild(div2);
    return div1;
  }

  if (respItem) {
    return (
      <div className={classes.mainBackground}>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="SOFA vod"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 100,
            color: "#1B1B1B"
          }}
          {...rest}
        />
        {isLoading ? <div className={classes.container}>
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        </div> : (
          <div>
            <Parallax filter image={width >= 1920 ? encodeURI(item.mini_path) : width > 640 ? encodeURI(item.mini_path + "?tr=w-" + width + ",h-" + ((width*1080)/1920)) + ",dpr-" + window.devicePixelRatio.toString() : item.portrait_path ? encodeURI(item.portrait_path + "?tr=w-" + width + ",h-" + ((width*3000)/2000)) + ",dpr-" + window.devicePixelRatio.toString() : encodeURI(item.mini_path + "?tr=w-" + width + ",h-" + ((width*1080)/1920)) + ",dpr-" + window.devicePixelRatio.toString()}>
              <div className={classes.container}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    {item.logo_path ?
                      <img src={item.logo_path + "?tr=w-" + (width > 1200 ? (1140*0.5).toString() + ",h-" + ((1140*0.5)*9/16).toString() : (width*0.5).toString() + ",h-" + ((width*0.5)*9/16).toString()) + ",dpr-" + window.devicePixelRatio.toString()} alt={item.title} width="100%" loading="lazy" onError={(e) => {e.target.replaceWith(replaceTitle(item.title))}} /> : <div className={classes.titleTextContainer}><div><h1 className={classes.title}>{item.title}</h1></div></div>
                    }
                    {/*<h4>
                      {item.short_desc}
                    </h4>*/}
                    <div className={classes.presentationElement}>
                      {width > 640 && (currentUser.statut == 20 || item.isFree == 1) ? <Link to={{
                        pathname: "/player",
                        state: item
                      }}>
                        {item.start || continu ? <Button
                          color={width > 640 ? "sofa" : "sofaM"}
                          size={width > 640 ? "lg" : "sm"}
                          //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                          target="_blank"
                          rel="noopener noreferrer"
                        >

                          <i className="fa-regular fa-circle-play fa-xl" /> &nbsp;  &nbsp;Reprendre</Button> : <Button
                          color={width > 640 ? "sofa" : "sofaM"}
                          size={width > 640 ? "lg" : "sm"}
                          //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                          target="_blank"
                          rel="noopener noreferrer"
                        >

                          <i className="fa-regular fa-circle-play fa-xl" /> &nbsp;  &nbsp;Lecture

                        </Button>}</Link> : width > 640 && item.idType != 1 ? <a href="https://moncompte.sofavod.com/shop" rel="noopener noreferrer"><Button
                          color={width > 640 ? "sofa" : "sofaM"}
                          size={width > 640 ? "lg" : "sm"}
                          rel="noopener noreferrer"
                        >S'abonner pour visionner</Button></a> : width > 640 && <Link to={{
                          pathname: "/player",
                          state: {item, episode: tabSeason.find(x => x.season == 1 && x.number == 1)}
                        }}><Button
                          color={width > 640 ? "sofa" : "sofaM"}
                          size={width > 640 ? "lg" : "sm"}
                          target="_blank"
                          rel="noopener noreferrer"
                        ><i className="fa-regular fa-circle-play fa-xl" /> &nbsp;  &nbsp;Regarder l'épisode 1</Button></Link>}
                      <div className={classes.interactList}>
                        {isList ? <div>
                            <button onClick={removeList} className={width > 640 ? classes.interactButton + " " + classes.listButtonDetail : classes.interactButton + " " + classes.listButtonDetailM}>
                              <i class={width > 640 ? "fas fa-list-ul fa-xl " + classes.listIcon : "fas fa-list-ul " + classes.listIcon}></i>
                              <i class={width > 640 ? "fas fa-check fa-1x " + classes.checkIcon : "fas fa-check fa-sm " + classes.checkIcon}></i>
                              <p>Ma liste</p>
                            </button>
                          </div> : <div>
                            <button onClick={addList} className={width > 640 ? classes.interactButton + " " + classes.listButtonDetail : classes.interactButton + " " + classes.listButtonDetailM}>
                              <i class={width > 640 ? "fas fa-list-ul fa-xl " + classes.listIcon : "fas fa-list-ul " + classes.listIcon}></i>
                              <i class={width > 640 ? "fas fa-plus fa-1x " + classes.checkIcon : "fas fa-plus fa-sm " + classes.checkIcon}></i>
                              <p>Ma liste</p>
                            </button>
                          </div>}
                        {isFavorite ? <div>
                            <button onClick={removeFavorite} className={classes.interactButton}>
                              <i class={width > 640 ? "fas fa-heart fa-xl" : "fas fa-heart"}></i>
                              <p className={classes.interactText}>J'aime</p>
                            </button>
                          </div> : <div>
                            <button onClick={addFavorite} className={classes.interactButton}>
                              <i class={width > 640 ? "far fa-heart fa-xl" : "far fa-heart"}></i>
                              <p className={classes.interactText}>J'aime</p>
                            </button>
                          </div>}
                        <div>
                          <button aria-describedby={share} onClick={handleClick} variant="contained" className={classes.interactButton}>
                            <i class={width > 640 ? "fas fa-share fa-xl" : "fas fa-share"}></i>
                            <p className={classes.interactText}>Partager</p>
                          </button>
                          <Popover
                            id={share}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left"
                            }}
                          >
                            <div className={classes.popOverElement}>
                              <p>Lien de partage :</p>
                              <input type="text" value={"https://sofavod.com/itemShare/" + item.Id} autoFocus readOnly></input>
                              <button aria-describedby={copied} variant="contained" className={classes.copyButton} onClick={handleClick2}>Copier</button>
                              <Popover 
                                id={copied}
                                open={open2}
                                anchorEl={anchorEl2}
                                onClose={handleClose2}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left"
                                }}
                              >
                                <p className={classes.popOverText}>Lien copié !</p>
                              </Popover>
                            </div>
                          </Popover>
                        </div>
                      </div>
                    </div>
                    {width <= 640 && (currentUser.statut == 20 || item.isFree == 1) ? <div className={classes.playButton}>
                      <Link to={{
                        pathname: "/player",
                        state: item
                      }}>
                        {item.start || continu ? <Button
                          color="sofaM"
                          size="sm"
                          //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa-regular fa-circle-play fa-xl" /> &nbsp;  &nbsp;Reprendre</Button> : <Button
                          color="sofaM"
                          size="sm"
                          //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa-regular fa-circle-play fa-xl" /> &nbsp;  &nbsp;Lecture
                        </Button>}</Link>
                      </div> : width <= 640 && item.idType != 1 ? <div className={classes.playButton}>
                          <a href="https://moncompte.sofavod.com/shop" rel="noopener noreferrer"><Button
                            color="sofaM"
                            size="sm"
                            rel="noopener noreferrer"
                            >S'abonner pour visionner</Button></a>
                      </div> : width <= 640 && <div className={classes.playButton}>
                        <Link to={{
                          pathname: "/player",
                          state: {item, episode: tabSeason.find(x => x.season == 1 && x.number == 1)}
                        }}><Button
                          color="sofaM"
                          size="sm"
                          target="_blank"
                          rel="noopener noreferrer"
                        ><i className="fa-regular fa-circle-play fa-xl" />&nbsp;&nbsp;Regarder l'épisode 1</Button></Link></div>}
                        {currentUser.statut != 20 && item.isFree != 1 && <p className={classes.textFree}>{item.idType == 1 ? "Vous pouvez regarder gratuitement le 1er épisode de cette série." : "Ce film nécessite un abonnement pour pouvoir être visionné."}</p>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} className={classes.BAContainer}>
                    {item.teaser_path && width >= 840 &&
                      <div className={classes.BAContainerDiv}>
                        <ReactVideo
                          autoPlay={true}
                          width='100%'
                          // height="300"
                          // poster={item.mini_path}
                          controls={true}
                          idEpisode={item.idEpisode}
                          idItem={item.Id}
                          user={currentUser}
                          src={item.teaser_path}
                        />
                      </div>
                    }
                  </GridItem>
                </GridContainer>
              </div>
            </Parallax>

            <div className={classes.main + " " + classes.blurBackground} style={{ backgroundImage: "url(" + item.mini_path + "?tr=bl-50), linear-gradient(135deg, #141414, #462012, #141414)" }}>
              <div className={width > 960 ? classes.darkBackground : classes.darkBackgroundM}>
                <GridContainer className={classes.mainContainer}>
                  <GridItem className={classes.tabsContainer}>
                    <Tabs TabIndicatorProps={{ style: { background: '#ec6f39' } }} value={value} onChange={handleChange}>
                      <Tab label="Informations" {...a11yProps(0)} />
                      {seasons.length > 0 && seasons.map(season => (
                        <Tab label={"Saison " + season.toString()} {...a11yProps(season)} />
                      ))}
                      {tabSugg.length > 0 &&
                        <Tab label="Suggestions" {...a11yProps(seasons.length + 1)} />
                      }
                    </Tabs>
                  </GridItem>
                  <TabPanel value={value} index={0}>
                    <GridItem xs={12}>
                      <div className={width > 720 ? classes.informationContainer : classes.informationContainerM}>
                        <div className={width > 720 ? classes.descriptionContainer : classes.descriptionContainerM}>
                          <div className={classes.description}>
                            <p className={classes.textDescription}>
                              {item.overview}
                            </p>
                          </div>
                          {tabProductor ?
                            <div className={"item " + classes.clickableItem}>
                              <Link to={{
                                pathname: "/productor",
                                state: tabProductor
                              }}>
                                <div className={classes.productorElement}>
                                  <img src={encodeURI(tabProductor.photo_path + "?tr=w-" + (width > 640 ? "120,h-120" : "90,h-90") + ",dpr-" + window.devicePixelRatio.toString())} width={width > 640 ? 120 : 90} height={width > 640 ? 120 : 90} alt={tabProductor.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                  <p>Produit par<br />{ tabProductor.name }</p>
                                </div>
                              </Link>
                            </div> : (
                              <div></div>
                            )
                          }
                        </div>
                        <div className={width > 720 && classes.logoContainer}>
                          <div className={classes.tabLogo}>
                            {item.isExclusif == 1 &&
                              <img src={exclusifLarge} className={classes.exclusifLogo} height={30} alt="Exclusif" />
                            }
                            {currentUser.statut != 20 && item.isFree != 1 &&
                              <div className={classes.freeLogo} style={{ marginRight: 10 }}>
                                <i class="fa-solid fa-lock fa-2xl" title={item.idType == 1 ? "Vous pouvez regarder le 1er épisode de cette série gratuitement." : "Vous devez avoir un abonnement pour visionner ce film."}></i>
                              </div>
                            }
                            {item.format == "portrait" &&
                              <div className={classes.portraitLogo}>
                                <i className="fa-solid fa-mobile-screen fa-2xl" title="Ce film est au format portrait" />
                              </div>
                            }
                            {tabAge &&
                              <img src={encodeURI(tabAge.image_path + "?tr=w-40,h-40,dpr-" + window.devicePixelRatio.toString())} alt={tabAge.name} className={classes.ageLogo} width={39.2} height={39.2} loading="lazy" />
                            }
                            <h6 className={classes.timeText}>{item.time}</h6>
                          </div>
                          <div className={classes.containerLogo}>
                            <GridList className={classes.gridList + " " + classes.listGenre}>
                              {tabGenre.map((genre) => (
                                <div className={"item " + classes.genreItem}>
                                  <Link to={{
                                    pathname: "/genre",
                                    state: genre
                                  }}>
                                    <div className={classes.categoryItem}>
                                      <img src={encodeURI(genre.url + "?tr=w-120,h-120,dpr-" + window.devicePixelRatio.toString())} className={classes.categoryImg} width={120} height={120} alt={genre.url} loading="lazy" />
                                      <div className={classes.categoryLogoDiv}>
                                        <img src={encodeURI(genre.imageLogo + "?tr=w-120,h-" + (120*9/16).toString() + ",dpr-" + window.devicePixelRatio.toString())} width={120} height={120*9/16} alt={genre.imageLogo} loading="lazy" />
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              ))}
                            </GridList>
                          </div>
                          <div className={classes.containerLogo}>
                            <div className={"item " + classes.clickableItem}>
                              <Link to={{
                                pathname: "/departement",
                                state: tabRegion
                              }}>
                                <div className={classes.departementDiv2}>
                                  <img src={encodeURI(tabRegion.logo_path + "?tr=w-120,h-120,dpr-" + window.devicePixelRatio.toString())} width={120} height={120} className={classes.departementImg} alt={tabRegion.name} loading="lazy" />
                                  <h6 className={classes.departementText}>{tabRegion.name}</h6>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </GridItem>
                    {tabRealData.length > 0 && <GridItem xs={12} className={classes.containerPeopleList}>
                      <h4 className={classes.title + " " + classesList.title}>Réalisation</h4>
                      <div className={width > 960 ? classes.root + " " + classesList.listContainer : classes.root + " " + classesList.listContainerM}>
                        {tabRealData.length > getLargePeopleColums() ?
                        (tabRealData.length >= getPeopleColumns() ? 
                          <Carousel show={getPeopleColumns()}>
                            {tabRealData.map((people) => (
                              <div className={"item " + classes.peopleItem}>
                                <Link to={{
                                  pathname: "/people",
                                  state: people
                                }}>
                                  <div className={width >= 840 ? classes.peopleDiv : classes.peopleDivM}>
                                    <img src={encodeURI(people.photo_path + "?tr=w-" + nWidthPeople.toString() + ",h-" + nWidthPeople.toString() + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nHeightPeople} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                    <h6>{people.name}</h6>
                                    <p>{people.function}</p>
                                  </div>                                    
                                </Link>
                              </div>
                            ))}
                          </Carousel> : <GridList className={classes.gridList + " " + classes.listPeople}>
                            {tabRealData.map((people) => (
                              <div className={"item " + classes.peopleItem}>
                                <Link to={{
                                  pathname: "/people",
                                  state: people
                                }}>
                                  <div className={width >= 840 ? classes.peopleDiv : classes.peopleDivM}>
                                    <img src={encodeURI(people.photo_path + "?tr=w-" + nWidthPeople.toString() + ",h-" + nWidthPeople.toString() + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nHeightPeople} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                    <h6>{people.name}</h6>
                                    <p>{people.function}</p>
                                  </div>                                    
                                </Link>
                              </div>
                            ))}
                          </GridList>) : (<GridList className={width > 640 ? classes.gridList + " " + classes.listLargePeople : classes.gridList + " " + classes.listLargePeopleM}>
                            {tabRealData.map((people) => (
                              <div className={"item " + classes.largePeopleItem}>
                                <Link to={{
                                  pathname: "/people",
                                  state: people
                                }}>
                                  <div className={classes.largePeopleDiv}>
                                    <img src={encodeURI(people.photo_path + "?tr=w-" + nWidthPeople.toString() + ",h-" + nWidthPeople.toString() + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nHeightPeople} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                    <div>
                                      <h6>{people.name}</h6>
                                      <p>{people.function}</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ))}
                          </GridList>)}
                      </div>
                    </GridItem>}
                    {tabActorData.length > 0 &&
                      <GridItem xs={12} className={classes.containerPeopleList}>
                        <h4 className={classes.title + " " + classesList.title}>Casting</h4>
                        <div className={width > 960 ? classes.root + " " + classesList.listContainer : classes.root + " " + classesList.listContainerM}>
                          {tabActorData.length > getLargePeopleColums() ?
                          (tabActorData.length >= getPeopleColumns() ?
                            <Carousel show={getPeopleColumns()}>
                              {tabActorData.map((people) => (
                                <div className={"item " + classes.peopleItem}>
                                  <Link to={{
                                    pathname: "/people",
                                    state: people
                                  }}>
                                    <div className={width >= 840 ? classes.peopleDiv : classes.peopleDivM}>
                                      <img src={encodeURI(people.photo_path + "?tr=w-" + nWidthPeople.toString() + ",h-" + nWidthPeople.toString() + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nHeightPeople} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                      <h6>{people.name}</h6>
                                      <p>{people.function}</p>
                                    </div>                                    
                                  </Link>
                                </div>
                              ))}
                            </Carousel> : <GridList className={classes.gridList + " " + classes.listPeople}>
                              {tabActorData.map((people) => (
                              <div className={"item " + classes.peopleItem}>
                                <Link to={{
                                  pathname: "/people",
                                  state: people
                                }}>
                                  <div className={width >= 840 ? classes.peopleDiv : classes.peopleDivM}>
                                    <img src={encodeURI(people.photo_path + "?tr=w-" + nWidthPeople.toString() + ",h-" + nWidthPeople.toString() + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nHeightPeople} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                    <h6>{people.name}</h6>
                                    <p>{people.function}</p>
                                  </div>                                    
                                </Link>
                              </div>
                            ))}
                            </GridList>) : (<GridList className={width > 640 ? classes.gridList + " " + classes.listLargePeople : classes.gridList + " " + classes.listLargePeopleM}>
                              {tabActorData.map((people) => (
                              <div className={"item " + classes.largePeopleItem}>
                                <Link to={{
                                  pathname: "/people",
                                  state: people
                                }}>
                                  <div className={classes.largePeopleDiv}>
                                    <img src={encodeURI(people.photo_path + "?tr=w-" + nWidthPeople.toString() + ",h-" + nWidthPeople.toString() + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nHeightPeople} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                    <div>
                                      <h6>{people.name}</h6>
                                      <p>{people.function}</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ))}
                            </GridList>)}
                        </div>
                      </GridItem>
                    }
                    {tabTechData.length > 0 &&
                      <GridItem xs={12} className={classes.containerPeopleList}>
                        <h4 className={classes.title + ' ' + classesList.title}>Équipe Technique</h4>
                        <div className={width > 960 ? classes.root + " " + classesList.listContainer : classes.root + " " + classesList.listContainerM}>
                          {tabTechData.length >= getPeopleColumns() ?
                            <Carousel show={getPeopleColumns()}>
                              {tabTechData.map((people) => (
                                  <div className={"item " + classes.peopleItem}>
                                    <Link to={{
                                      pathname: "/people",
                                      state: people
                                    }}>
                                      <div className={width >= 840 ? classes.peopleDiv : classes.peopleDivM}>
                                        <img src={encodeURI(people.photo_path + "?tr=w-" + nWidthPeople.toString() + ",h-" + nWidthPeople.toString() + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nHeightPeople} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                        <h6>{people.name}</h6>
                                        <p>{people.function}</p>
                                      </div>                                    
                                    </Link>
                                  </div>
                              ))}
                            </Carousel> : (<GridList className={classes.gridList + " " + classes.listPeople}>
                              {tabTechData.map((people) => (
                                <div className={"item " + classes.peopleItem}>
                                  <Link to={{
                                    pathname: "/people",
                                    state: people
                                  }}>
                                    <div className={width >= 840 ? classes.peopleDiv : classes.peopleDivM}>
                                      <img src={encodeURI(people.photo_path + "?tr=w-" + nWidthPeople.toString() + ",h-" + nWidthPeople.toString() + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nHeightPeople} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                      <h6>{people.name}</h6>
                                      <p>{people.function}</p>
                                    </div>                                    
                                  </Link>
                                </div>
                              ))}
                            </GridList>)}
                        </div>
                      </GridItem>
                    }
                  </TabPanel>
                  {seasons.map(season => (
                    <TabPanel value={value} index={season}>


                      {tabSeason.map(episode =>
                      (episode.season == season &&
                        <Paper className={classes.episodeContainer} sx={{ p: 2, margin: 'auto', flexGrow: 1, elevation: 25 }}>
                          <Grid container spacing={2} className={width <= 840 && classes.episodeContainerDivM}>
                            <Grid item className={classes.imageEpisodeElement}>
                              <ButtonBase sx={{ width: 240, height: 135, borderRadius: "15px" }}>
                                <Img className={classes.imageEpisode} alt={episode.titleEpisode} src={encodeURI(episode.image_path + "?tr=w-240,h-135,dpr-" + window.devicePixelRatio.toString())} width={240} height={135} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,null,null,"","css-y9s1zk"))}} />
                                {episode.isNewEpisode == 1 && <Typography className={classes.newEpisodeText} gutterBottom variant="inherit">
                                    NOUVEAU
                                </Typography>}
                                {episode.start ? <div className={classes.watchContainer2}><div><div style={{ width: ( episode.start / episode.tTotalTime * 100 ) + "%" }}></div></div></div> : 
                                episode.start == 0 ? <div className={classes.watchContainer2}><div><div></div></div></div> : <></>}
                              </ButtonBase>
                            </Grid>
                            <Grid item xs={12} sm container>
                                <Grid item xs className={classes.episodeContainerElement}>
                                  <div className={classes.episodeTopElement}>
                                    <Typography className={classes.title + " " + classes.titleEpisode} gutterBottom variant="subtitle1" component="div">
                                      {episode.number}. {episode.titleEpisode}
                                      {currentUser.statut != 20 && item.isFree != 1 && (episode.number != 1 || episode.season != 1) &&
                                        <div className={classes.freeLogo} style={{ marginLeft: 10 }}>
                                          <i class="fa-solid fa-lock" title="Vous devez vous abonner pour regarder cet épisode"></i>
                                        </div>
                                      }
                                    </Typography>
                                    <Typography className={classes.timeEpisode} variant="subtitle1" component="div">
                                      {episode.time}
                                    </Typography>
                                  </div>
                                  <Typography variant="body2" gutterBottom className={classes.descriptionEpisode}>
                                    {episode.description}
                                  </Typography>
                                  {/*episode.isNewEpisode == 1 && <Typography style={{ background: "rgba(185,185,185,0.7)", color: "#FFF", padding: "5px 15px", width: "fit-content", borderRadius: 24 }} gutterBottom variant="inherit">
                                    NOUVEAU
                                  </Typography>*/}
                                  <Grid item>
                                    <Typography variant="body2">
                                      {currentUser.statut == 20 || item.isFree == 1 || (episode.number == 1 && episode.season == 1) ? <Link className={classes.linkEpisode} to={{
                                        pathname: "/player",
                                        state: {item, episode}
                                      }}>

                                        {episode.start ? <Button
                                          color={width > 640 ? "sofa" : "sofaM"}
                                          size="sm"
                                          //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >

                                          <Typography className={classes.textLinkEpisode} variant="subtitle1" component="div">
                                            Reprendre l'épisode {episode.number}
                                          </Typography>
                                        </Button> : episode.start == 0 ? <Button
                                          color="sofa3"
                                          size="sm"
                                          //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >

                                          <Typography className={classes.textLinkEpisode} variant="subtitle1" component="div">
                                            Revoir l'épisode {episode.number}
                                          </Typography>
                                        </Button> : <Button
                                          color={width > 640 ? "sofa" : "sofaM"}
                                          size="sm"
                                          //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >

                                          <Typography className={classes.textLinkEpisode} variant="subtitle1" component="div">
                                            Regarder l'épisode {episode.number}
                                          </Typography>
                                        </Button>}

                                      </Link> : <a className={classes.linkEpisode} href="https://moncompte.sofavod.com/shop" rel="noopener noreferrer"><Button
                                        color={width > 640 ? "sofa" : "sofaM"}
                                        size="sm"
                                        rel="noopener noreferrer"
                                      ><Typography className={classes.textLinkEpisode} variant="subtitle1" component="div">S'abonner pour visionner</Typography></Button></a>}
                                    </Typography>
                                  </Grid>
                                

                              </Grid>
                              {/*<Grid item>
                                <Typography style={{ color: "#CCCCCC", fontSize: 12 }} variant="subtitle1" component="div">
                                  {episode.time}
                                </Typography>
                              </Grid>*/}
                            </Grid>
                          </Grid>
                        </Paper>




                      )
                      )}


                    </TabPanel>
                  ))}

                  {/* {seasons.map(season => (
                    <TabPanel value={value} index={season.number} style={width > 1470 ? { width: 1320 } : width >= 840 ? { width: width - 170 } : { width: width - 30 }}>
                      <GridItem>
                        <GridList style={width >= 840 ? { paddingLeft: 15, overflow: "hidden" } : { overflow: "hidden", padding: 0 }}>
                          {tabSeason.map(episode =>
                          (episode.season == season.number &&
                            <div style={width > 1470 ? { width: 1320, display: "flex", justifyContent: "space-between" } : width >= 840 ? { minWidth: width - 250, display: "flex", justifyContent: "space-between" } : { minWidth: width - 80, display: "flex", justifyContent: "space-between", padding: 0, margin: 0, height: 120 }}>
                              <div style={width >= 840 ? { display: "flex", width: 370 } : { display: "flex", width: 190 }}>
                                <img src={encodeURI(episode.image_path + "?tr=w-240,h-135")} style={width >= 840 ? { width: 200, height: 112.5, borderRadius: 20 } : { width: 100, height: 56.25, borderRadius: 10 }} alt={episode.image_path} />
                                <div style={width >= 840 ? { marginTop: 7, marginLeft: 10 } : { marginLeft: 5 }}>
                                  <h6 style={width >= 840 ? { color: '#FFF' } : { color: '#FFF', marginBottom: 0 }}>{episode.number}. {episode.titleEpisode}</h6>
                                  <small style={{ color: "#FFF" }}>{episode.time}</small>
                                </div>
                              </div>
                              <div style={width > 1470 ? { maxWidth: 1320 / 3 } : width >= 840 ? { maxWidth: (width - 250) / 3 } : { maxWidth: width / 3 }}>
                                <p style={width < 840 ? { color: '#FFF', textAlign: "justify", fontSize: 12, maxHeight: 150 } : { color: '#FFF', textAlign: "justify" }}>{episode.description}</p>
                              </div>
                              <div style={{ minWidth: 110 }}>
                                <Link style={{ margin: 8 }} to={{
                                  pathname: "/player",
                                  state: episode
                                }} style={{ textDecoration: "none" }} >
                                  {width >= 840 ?
                                    <Button style={{ borderRadius: 25 }}
                                      color="sofa"
                                      size="lg"
                                      //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >


                                      <i className="fas fa-play" />  Lecture

                                    </Button> : (
                                      <Button style={{ borderRadius: 25 }}
                                        color="sofa"
                                        size="sg"
                                        //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >


                                        <i className="fas fa-play" />

                                      </Button>
                                    )}
                                </Link>
                              </div>
                            </div>
                          )
                          )}
                        </GridList>
                      </GridItem>
                    </TabPanel>
                  ))} */}
                  <TabPanel value={value} index={seasons.length + 1}>
                    <GridItem>
                      <GridList cellHeight={350} className={width > 640 ? classes.gridList + " " + classes.suggList : classes.gridList + " " + classes.suggListM} cols={getGridListCols()}>

                        {tabSugg.map(itemS => 
                          <GridListTile class={width > 640 ? "item " + classes.suggItem : "item " + classes.suggItemM} key={itemS.Id} cols={width > 640 ? 1 : 0.33}>
                            <Link to={{
                              pathname: "/detail/" + itemS.Id
                            }}
                              onClick={() => {
                                id = itemS.Id;
                                setItem(itemS);
                                setLoading(true);
                                setSeasons([]);
                                setList(false);
                                setFavorite(false);
                                fetchData();
                                handleChange(itemS, 0);
                              }}>
                              <div className={classes.suggElement}>
                                <img src={encodeURI(itemS.portrait_title_path + "?tr=w-" + (width > 640 ? "200,h-300" : "117,h-175") + ",dpr-" + window.devicePixelRatio.toString())} className={classes.suggImage} width={width > 640 ? 200 : 117} height={width > 640 ? 300 : 175} alt={itemS.title} loading="lazy"
                                onError={width > 640 ? (e) => {e.target.replaceWith(defaultImageP(e.nativeEvent.target.style,200,300,itemS.title))} : (e) => {e.target.replaceWith(defaultImageP(e.nativeEvent.target.style,117,175,itemS.title))}} />
                                <h6 className={classes.genreLib}>{itemS.genreLib}</h6>
                                {itemS.isExclusif == 1 && (itemS.isFree == 1 || currentUser.statut == 20) && <div className={classes.exclusif}><img src={exclusif} height={20} alt="Exclusif" /></div>}
                                {itemS.isNewEpisode == 1 && <div className={classes.newEpisode}><h6>Nouvel épisode</h6></div>}
                                {itemS.isFree != 1 && currentUser.statut != 20 && <div className={classes.freeLogoG}>
                                <i class="fa-solid fa-lock fa-xl"></i>
                              </div>}
                              </div>
                            </Link>
                          </GridListTile>
                        )}

                      </GridList>
                    </GridItem>
                  </TabPanel>
                </GridContainer>
              </div>


            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}
