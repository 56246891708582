import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import {
  BrowserView,
  MobileView,
  isBrowser,
  osVersion,
  osName
} from "react-device-detect";
import Header from "components/Header/Header.js";
import Login from 'components/login.components.js'
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import { useHistory } from "react-router-dom";
import AuthService from "../../services/auth.service.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import useWindowSize from 'components/useWindowSize';

import styles from "assets/jss/material-kit-react/views/loginPage.js";

const useStyles = makeStyles(styles);

export default function LoginPage(authed) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  let history = useHistory();
  const { width, height } = useWindowSize();
  let currentUser = AuthService.getCurrentUser();

  if (currentUser != null) {
    setTimeout(() => {
      history.replace('/');
    })
  }

  //console.log(history)
  //const { ...rest } = props;
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="SOFA vod"
        // rightLinks={<HeaderLinks />}
       
      />
      <div
        className={classes.pageHeader}
        style={width < 3840 && width >= ((height*3840)/2160) ? {
          backgroundImage: "url(https://ik.imagekit.io/sofavod/Couverture_SOFA_2021_sans-titre.jpg?tr=w-" + width + ",h-" + ((width*2160)/3840) + ",dpr-" + window.devicePixelRatio.toString() + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        } : {
          backgroundImage: "url(https://ik.imagekit.io/sofavod/Couverture_SOFA_2021_sans-titre.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <Login navigation={history} width={width}></Login>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
