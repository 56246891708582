// API/ItemsAPI.js
import axios from "axios";
import UrlAPI from "./UrlAPI"

export function getItemsAll(pi_sToken, idUser) {
  
  const url = UrlAPI.sUrl+'/item/all/' + idUser.toString();
  return axios(url,{
    method: 'GET', 
    headers:{
        'Content-Type': "application/json",
        'x-access-token': pi_sToken
    },
    })
}