import axios from "axios"
import UrlAPI from "./UrlAPI"

export function getItemByCategory(pi_token, id) {
    const url = UrlAPI.sUrl+'/item/listByCategory/'+id
    return axios(url,{
        method: 'GET',
        headers:{
            'Content-Type': "application/json",
            'x-access-token': pi_token
        },
    }) 

}