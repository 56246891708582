import React,{useState,useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Link,
  useParams
} from "react-router-dom";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import ParallaxBlur from "components/Parallax/ParallaxBlur.js";
import Parallax from "components/Parallax/Parallax.js";
import GridList from '@material-ui/core/GridList';
import Paper from '@material-ui/core/Paper';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import AuthService from "../../services/auth.service.js";
import useWindowSize from 'components/useWindowSize';
import { getByParams } from 'API/GetByParamsAPI';
import { useHistory } from 'react-router-dom';
import exclusif from '../../assets/img/exclusif.png';
import noImageAVIF from "assets/img/Fond_lampadaire_Portrait.avif";
import noImage from "assets/img/Fond_lampadaire_Portrait.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage";

const useStyles = makeStyles(styles);

const useStylesList = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor:'transparent'
    //background: "linear-gradient(rgba(20, 20, 20, 1), rgba(70, 32, 18, 1)),rgba(20, 20, 20, 1);" 
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: "#FFF",
  },
  titleBar: {
    borderRadius:12,
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));
const dashboardRoutes = [];



export default function ThemePage(props) {
  const classes = useStyles();
  const nWidthItem = 300;
  const nHeightItem = 170;
  const classesList = useStylesList();
  const { ...rest } = props;
  const { width } = useWindowSize();



  const oTheme = props.location.state;
  let currentUser = AuthService.getCurrentUser();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

 
  const [tabItem, setTabItem] = useState([]);
  const [isLoading, setLoading] = useState(true);

  let history = useHistory();

  if (currentUser == null) {
    history.push('/login');
  }

  const fetchData = async () => {
        const json = {
            table_name: oTheme.table_name,
            value: oTheme.value,
            limit: oTheme.maxNumber,
            order: oTheme.orderRule
        }

          const respItem = await getByParams(currentUser.accessToken, json);

          setTabItem(respItem.data);

          window.scrollTo({top: 0, left: 0, behavior: "instant"});

          setLoading(false);
        }

    



       const getGridListCols = () => {
         let nColumns = width / 250;
         return Math.trunc(nColumns)
      }
      useEffect(() => {
        const getAPI  = async () => {
          let validtoken = true;
          let newToken = false;
      
          if(currentUser != null){
            fetchData().catch(
              function (error) {
               // console.log(error.response);
                if (error.response) {
                  if(error.response.status == 401){
                    validtoken = false;
                  }
                  if(error.response.status == 402){
                    validtoken = false;
                  }
                  if(error.response.status == 403){
                    validtoken = false;
                  }
                } else {
                //  console.log('Error', error.message);
                }
                if(!validtoken && !newToken){ 
                  newToken = true;
                   AuthService.refreshToken(currentUser.id,currentUser.accessToken).then(
                    currentUser = AuthService.getCurrentUser(),
                           setTimeout(async() => {
                              getAPI()
                            }, 500)
                  ).catch(error=>{
                   // console.log(error)
                
                   AuthService.logout();
                      })
                }else{
                  AuthService.logout();
                }
            })
          }
        }
        getAPI()
    }, []);

    const defaultImage = (style, width, height, title) => {
      let picture = document.createElement("picture");
      picture.setAttribute("style", "position: relative");
      let source = document.createElement("source");
      source.setAttribute("srcSet", noImageAVIF);
      source.setAttribute("type", "image/avif");
      let img = document.createElement("img");
      img.setAttribute("src", noImage);
      let jsonStyle = {};
      Object.values(JSON.parse(JSON.stringify(style))).forEach(element => {
        jsonStyle[element] = style[element];
      })
      img.setAttribute("style", JSON.stringify(jsonStyle).replace(/","/g, ";").replace(/["'{}]/g, ""));
      img.setAttribute("width", width);
      img.setAttribute("height", height);
      let div = document.createElement("div");
      div.setAttribute("style", "position: absolute; top: 0; width: 100%; height: 100%; padding: 2.5%; display: grid; align-items: center; justify-content: center");
      let text = document.createElement("h3");
      text.setAttribute("style", "color: #FFF; font-size: 20px; font-family: Bubblebody Light; text-align: center");
      text.appendChild(document.createTextNode(title));
      div.appendChild(text);
      picture.appendChild(source);
      picture.appendChild(img);
      picture.appendChild(div);
      return picture;
    }

  if (tabItem) {

  return (
    <div style={{ backgroundColor: "#1B1B1B",}}>
    <Header
      color="transparent"
      routes={dashboardRoutes}
      brand="SOFA vod"
      rightLinks={<HeaderLinks />}
      fixed
      changeColorOnScroll={{
        height: 100,
        color: "#1B1B1B" 
      }}
      {...rest}
    />
     {isLoading ? <div className={classes.container}>
     <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
     <CircularProgress style={{color:"#ec6f39"}} />
    </div>        
       </div> : (
    <div>
      <Parallax filter image={width >= 1920 ? encodeURI(oTheme.image_background_path) : encodeURI(oTheme.image_background_path + "?tr=w-" + width + ",h-" + (width*1080/1920) + ",dpr-" + window.devicePixelRatio.toString())} style={{ height: "60vh" }} />
      <div className={classNames(classes.main)} style={{backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(" + oTheme.image_background_path + "?tr=bl-50)", backgroundColor: "rgb(27, 27, 27)", backgroundSize: "cover", backgroundPosition: "center", minHeight: '76vh', margin: 0}}>
          <div>
          <GridContainer justify="center" style={{marginRight: 0}}>
              <GridItem xs={12} sm={12} md={6} style={{ minWidth: "100%" }}>
                <div className={classes.profile} style={{marginTop:-220}}>
                  <div>
                    <img src={encodeURI(oTheme.imageLogo+"?tr=w-" + (width > 640 ? "800,h-450" : (width-100).toString() + ",h-" + ((width-100)*9/16).toString()) + ",dpr-" + window.devicePixelRatio.toString())} style={width > 640 ? { minWidth: 800, height: 450 } : {minWidth: width - 100}} alt="..." loading="lazy" />
                  </div>
                  
                </div>
              </GridItem>
            </GridContainer>
            <GridList style={width > 640 ? {overflow: "hidden", marginTop: -300, padding: 100, marginRight: 0} : {overflow: "hidden", margin: 0}} cellHeight={350} className={classes.gridList} cols={getGridListCols()}>

            {tabItem.map((item) => (
                 <GridListTile  class="item" key={item} cols={width > 640 ? 1 : 0.33} style={width > 640 ? { display: "flex", justifyContent: "center", height: "fit-content", marginTop: 20 } : { display: "flex", justifyContent: "center", height: "fit-content" }}>
            <Link style={{margin:8}} to={{
            pathname: "/detail/" + item.Id
          }}>
            <div style={{display: "flex", width: "fit-content"}}>
              <img src={encodeURI(item.portrait_title_path+"?tr=w-" + (width > 640 ? "200,h-300" : "117,h-175") + ",dpr-" + window.devicePixelRatio.toString())} width={width > 640 ? 200 : 117} height={width > 640 ? 300 : 175} style={{borderRadius:12}} alt={item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,(width > 640 ? 200 : 117),(width > 640 ? 300 : 175),item.title))}} />
              <h6 style={width > 640 ? {color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: 16, left: 0} : {color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: 16, left: 0}}>{item.genreLib}</h6>
              {item.isExclusif == 1 && (item.isFree == 1 || currentUser.statut == 20) && <div style={width > 640 ? {width: 200, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 24, borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingBottom: 10} : {width: 117, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 24, borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingBottom: 10}}><img src={exclusif} style={{height: 20, borderRadius: 0, marginTop: 5, marginRight: 5}} alt="Exclusif" /></div>}
              {item.isNewEpisode == 1 && <div style={width > 640 ? {height: 25, background: "rgba(185,185,185,0.7)", position: "absolute", top: 24, left: 0, display: "flex", alignItems: "center", borderTopLeftRadius: 12, borderBottomRightRadius: 12} : {height: 25, background: "rgba(185,185,185,0.7)", position: "absolute", top: 24, left: 0, display: "flex", alignItems: "center", borderTopLeftRadius: 12, borderBottomRightRadius: 12}}><h6 style={width > 640 ? {margin: "0 10px", color: "#FFF"} : {margin: "0 5px", color: "#FFF", fontSize: 12}}>Nouvel épisode</h6></div>}
              {item.isFree != 1 && currentUser.statut != 20 && <div style={{color: "#ff6833", position: "absolute", top: 40, right: 10, display: "flex", alignItems: "center"}}><i class="fa-solid fa-lock fa-xl"></i></div>}
            </div>
            </Link>
          </GridListTile>
       ))}
   
  </GridList>

  
           </div>
      </div>
      <Footer />
      </div>
               )}
    </div>
    
  );
    }
}
