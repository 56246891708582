import axios from "axios"
import UrlAPI from "./UrlAPI"

export function getByIdPublic(id) {
    const url = UrlAPI.sUrl+'/item/getByIdPublic/'+id
    return axios(url,{
        method: 'GET',
        headers:{
            'Content-Type': "application/json",

        },
    }) 

}