import axios from "axios";
import UrlAPI from "./UrlAPI"

export function updateUserProps(pi_token, idUser, idProps, json) {
    const url = UrlAPI.sUrl+'/user/updateUserProps/' + idUser.toString() + "/" + idProps;
    return axios(url,{
        method: 'PUT',
        data: json,
        headers:{
            'Content-Type': "application/json",
            'x-access-token': pi_token
        },
    })

}