import React,{useState,useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Link,
  useParams
} from "react-router-dom";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import ParallaxBlur from "components/Parallax/ParallaxBlur.js";
import GridList from '@material-ui/core/GridList';
import Paper from '@material-ui/core/Paper';
import GridListTile from '@material-ui/core/GridListTile';
import CircularProgress from '@material-ui/core/CircularProgress';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import AuthService from "../../services/auth.service.js";
import useWindowSize from 'components/useWindowSize';
import Carousel from 'components/CarouselMulti/Carousel';
import { getItemByPeople } from '../../API/ItemByPeopleAPI';
import { getItemsAll } from '../../API/ItemsAPI';
import { getPeopleByCreator } from '../../API/PeopleByCreatorAPI';
import { getItemsByProductor } from '../../API/ItemsByProductor';
import { useHistory } from 'react-router-dom';
import { getUserItemById } from '../../API/GetUserItemAPI';
import { addUserItem } from '../../API/AddUserItemAPI';
import { upUserItem } from '../../API/UpUserItemAPI';
import exclusif from '../../assets/img/exclusif.png'
import noImageAVIF from "assets/img/Fond_lampadaire.avif";
import noImage from "assets/img/Fond_lampadaire.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage";

const useStyles = makeStyles(styles);

const useStylesList = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor:'transparent',
    paddingLeft: 5
    //background: "linear-gradient(rgba(20, 20, 20, 1), rgba(70, 32, 18, 1)),rgba(20, 20, 20, 1);" 
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    paddingLeft: 5,
  },
  title: {
    fontFamily: "Bubblebody Bold",
    backgroundImage: "linear-gradient(to top, #FF6000 0%, #fc852e 100%)",
    backgroundClip: "text",
    color: "#ef5101",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    marginTop: 30
  },
  titleBar: {
    borderRadius:12,
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  smallTitle: {
    marginLeft: "15px",
    color: "#FFF"
  },
  followButton: {
    color: "#ff600f",
    transition: "all 100ms",
    "&:hover": {
      color: "#ff9833"
    }
  }
}));
const dashboardRoutes = [];



export default function ProductorPage(props) {
  const classes = useStyles();
  const nWidthPeople = 120;
  const nHeightPeople = 120;
  const nWidthItem = 300;
  const nHeightItem = 170;
  const classesList = useStylesList();
  const { ...rest } = props;
  const { width } = useWindowSize();
  const oProductor = props.location.state;
  let currentUser = AuthService.getCurrentUser();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

 

  const [tabPeople, setTabPeople] = useState([]);
  const [tabFilm, setTabFilm] = useState([]);
  const [tabSerie, setTabSerie] = useState([]);
  const [isFollowed, setFollowed] = useState(false);
  const [userItems, setUserItems] = useState([]);
  const [isLoading, setLoading] = useState(true);
  
  let history = useHistory();

  if (currentUser == null) {
    history.push('/login');
  }
 
  const fetchData = async () => {
  
    const respPeople = await getPeopleByCreator(currentUser.accessToken, oProductor.Id);
    const respItem = await getItemsByProductor(currentUser.accessToken, oProductor.Id);
    const respUserItem = await getUserItemById(currentUser.accessToken, currentUser.id);
 
    setTabPeople(respPeople.data)

    const listFilm = respItem.data;
    const films = listFilm.filter(x => x.idType != 1);
    const series = listFilm.filter(x => x.idType == 1);

    setTabFilm(films);
    setTabSerie(series);
    //console.log(respItem)
    
    const UserItems = respUserItem.data.filter(x => x.idProductor == oProductor.Id);
    if (respUserItem.data.findIndex(x => x.idProductor == oProductor.Id) != -1) {
      setFollowed(true);
    }
    setUserItems(UserItems);
  
    setLoading(false);
  }
 
    
  useEffect(() => {
    let validtoken = true;
    let newToken = false;
    const getAPI = async () => {
      if (currentUser != null) {
        fetchData().catch(
          function (error) {
           // console.log(error.response);
            if (error.response) {
              if(error.response.status == 401){
                validtoken = false;
              }
              if(error.response.status == 402){
                validtoken = false;
              }
              if(error.response.status == 403){
                validtoken = false;
              }
            } else {
            //  console.log('Error', error.message);
            }
            if (!validtoken && !newToken) { 
              newToken = true;
              AuthService.refreshToken(currentUser.id,currentUser.accessToken).then(() => {
                currentUser = AuthService.getCurrentUser();
                getAPI();
              }).catch(() => {            
                AuthService.logout();
                history.push('/login');
              })
            } else {
              AuthService.logout();
              history.push('/login');
            }
        })
      } else {
        history.push('/login');
      }
    }
    getAPI();
}, []);
   // useEffect(() => {

    // useFocusEffect(
    //   React.useCallback(() => {
    //     const projets = getToken()
    //  //  return () => projets();
    //   }, [])
    // );
    //  },[]);


    const getItemColumns = () => {
      let nItem = width / 300;
      return Math.trunc(nItem);
    }

    const getPeopleColumns = () => {
      let nPeople = width / 220;
      return Math.trunc(nPeople);
    }

    const addFollowed = () => {
      addUserItem(currentUser.accessToken, currentUser.id, 0, 0, oProductor.Id, 5).then(response => {
        setFollowed(true);
        let UserItems = userItems;
        UserItems.push({id: response.data.id, idUser: currentUser.id, idProductor: oProductor.Id, idMyBanner: 5});
        setUserItems(UserItems);
      })
    }

    const removeFollowed = () => {
      const idUserItem = userItems.find(x => x.idProductor == oProductor.Id).id;
      upUserItem(currentUser.accessToken, idUserItem).then(() => {
        setFollowed(false);
        let UserItems = userItems.filter(x => x.idProductor != oProductor.Id);
        setUserItems(UserItems);
      })
    }

    const defaultImage = (style, width, height, title) => {
      let picture = document.createElement("picture");
      picture.setAttribute("style", "position: relative");
      let source = document.createElement("source");
      source.setAttribute("srcSet", noImageAVIF);
      source.setAttribute("type", "image/avif");
      let img = document.createElement("img");
      img.setAttribute("src", noImage);
      let jsonStyle = {};
      Object.values(JSON.parse(JSON.stringify(style))).forEach(element => {
        jsonStyle[element] = style[element];
      })
      img.setAttribute("style", JSON.stringify(jsonStyle).replace(/","/g, ";").replace(/["'{}]/g, ""));
      img.setAttribute("width", width);
      img.setAttribute("height", height);
      let div = document.createElement("div");
      div.setAttribute("style", "position: absolute; top: 0; width: 100%; height: 100%; padding: 2.5%; display: grid; align-items: center; justify-content: center");
      let text = document.createElement("h3");
      text.setAttribute("style", "color: #FFF; font-size: 20px; font-family: Bubblebody Light; text-align: center");
      text.appendChild(document.createTextNode(title));
      div.appendChild(text);
      picture.appendChild(source);
      picture.appendChild(img);
      picture.appendChild(div);
      return picture;
    }
   
  return (
    <div style={{ backgroundColor: "#1B1B1B",}}>
    <Header
      color="transparent"
      routes={dashboardRoutes}
      brand="SOFA vod"
      rightLinks={<HeaderLinks />}
      fixed
      changeColorOnScroll={{
        height: 100,
        color: "#1B1B1B" 
      }}
      {...rest}
    />
     {isLoading ? <div className={classes.container}>
     <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
       <CircularProgress style={{color:"#ec6f39"}} />
      </div>      
       </div> : (
    <div>
      <ParallaxBlur blur small filter image={encodeURI(oProductor.photo_path + "?tr=bl-10")} />
      <div className={classNames(classes.main)} style={{background: "linear-gradient(135deg,#141414, #462012,#141414)",minHeight:"76vh", margin: 0}}>
        <div>
          <div>
            <GridContainer justify="center" style={{marginRight: 0}}>
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile} >
                  <div>
                    <img src={encodeURI(oProductor.photo_path+"?tr=w-210,h-210,dpr-" + window.devicePixelRatio.toString())} width={210} height={210} style={{ minWidth: 210 }} alt="..." className={imageClasses} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                  </div>
                  <div style={width >= 840 ? {marginTop: -130, marginRight: -250} : {marginTop: -130, marginLeft: 300}}>
                    {isFollowed ?
                      <button onClick={removeFollowed} className={classesList.followButton}><i style={{marginBottom: 5}} class="fas fa-star fa-2x"></i><p>Suivre</p></button> : 
                      <button onClick={addFollowed} className={classesList.followButton}><i style={{marginBottom: 5}} class="far fa-star fa-2x"></i><p>Suivre</p></button>
                    }
                  </div>
                  <div className={classes.name} style={{marginTop: -40}}>
                    <h2 className={classesList.title}>{oProductor.name}</h2>
                    {oProductor.description &&
                        <h6 style={{textAlign: "justify", color: "#FFF"}}>{oProductor.description}</h6>
                    }
                   
                  </div>
                </div>
              </GridItem>
            </GridContainer>

            <div>
            <GridContainer style={{marginLeft:12, marginRight: 0}}>
              {tabPeople.length > 0 &&
          <GridItem xs={12} style={{marginTop:15, marginLeft: -5}}>
            {tabPeople.length > 0 &&
              <h4 className={classesList.smallTitle}>Membres</h4>
            }
          <div className={classesList.root} style={{justifyContent: 'flex-start', height: 260, marginLeft: -35}}>
         {tabPeople.length >= getPeopleColumns() ?
          <Carousel
                show={getPeopleColumns()}
            >
              {tabPeople.map((people) => (
                <div>
                <div style={{padding: 8}} class="">
                <Link style={{ margin:8, textDecoration: "none" }} to={{
        pathname: "/people",
        state: people

      }}>
                    <GridListTile class="item" key={people.photo_path} style={{width:200, height:220,marginTop:40}} >
                      <img src={encodeURI(people.photo_path+"?tr=w-" + nWidthPeople + ",h-" + nWidthPeople + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nWidthPeople} style={{ height: "auto", borderRadius: "50%" }} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                      <h6 style={{color:'#FFF',marginTop:15,textAlign:'center'}}>{people.name}</h6>
                    </GridListTile>
                    </Link>
                </div>
            </div>
              ))}
               
            </Carousel> : (
              <GridList className={classesList.gridList} style={{overflowY: "hidden", overflowX: "hidden", paddingRight: 5}}>
              {tabPeople.map((people) => (
                <Link  to={{
                  pathname: "/people",
                  state: people
                }} style={{ textDecoration: 'none' }}>
                  <GridListTile class="item" key={people.photo_path} style={{width:200, height:220,marginTop:40}} >
                      <img src={encodeURI(people.photo_path+"?tr=w-" + nWidthPeople + ",h-" + nWidthPeople + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nWidthPeople} style={{ height: "auto", borderRadius: "50%" }} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                      <h6 style={{color:'#FFF',marginTop:15,textAlign:'center'}}>{people.name}</h6>
                  </GridListTile>
                </Link>
               ))}
              </GridList>
            )
          }
    </div>
          </GridItem>
     }
          {tabFilm.length > 0 &&
          <GridItem xs={12} style={{marginTop:30, marginLeft: -5}}>
            {tabFilm.length == 1 &&
              <h4 className={classesList.smallTitle}>Film</h4>
            }
            {tabFilm.length > 1 &&
              <h4 className={classesList.smallTitle}>Films</h4>
            }
          <div className={classesList.root} style={{justifyContent: 'flex-start'}}>
          {tabFilm.length >= getItemColumns() ?
           <Carousel
           show={getItemColumns()}
         >
           {tabFilm.map((item) => (
             <GridListTile style={{ padding: 8 }} className="item">
               <Link style={{ margin: 8 }} to={{
                 pathname: "/detail/" + item.Id
               }} >
                 <div style={{ display: "flex" }}>
                   <img src={encodeURI(item.mini_title_path+"?tr=w-" + nWidthItem + ",h-" + nHeightItem + ",dpr-" + window.devicePixelRatio.toString())} width="100%" style={{ maxWidth: nWidthItem, maxHeight: nHeightItem }} alt={item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,"100%",null,item.title))}} />
                   <h6 style={{ color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: 16 }}>{item.genreLib}</h6>
                   {item.isExclusif == 1 && (item.isFree == 1 || currentUser.statut == 20) && <div style={{width: '100%', maxWidth: nWidthItem, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 24, borderTopLeftRadius: 10, borderTopRightRadius: 10, paddingBottom: 10}}><img src={exclusif} style={{height: 20, borderRadius: 0, marginTop: 5, marginRight: 5}} alt="Exclusif" /></div>}
                   {item.isFree != 1 && currentUser.statut != 20 && <div style={{color: "#ff6833", position: "absolute", top: 40, right: 10, display: "flex", alignItems: "center"}}><i class="fa-solid fa-lock fa-xl"></i></div>}
                 </div>
               </Link>
             </GridListTile>
           ))}

         </Carousel> : (
              <GridList style={{overflowY: "hidden", overflowX: "hidden", paddingRight: 5, paddingBottom: 20}} className={classesList.gridList}>
            {tabFilm.map((item) => (
              <Link style={{margin:8}} to={{
                pathname: "/detail/" + item.Id
              }}>
         
            <GridListTile class="item" key={item.mini_title_path} style={{width:nWidthItem, height:nHeightItem}}>
       
            <div style={{display: "flex"}}>
            <img src={encodeURI(item.mini_title_path+"?tr=w-" + nWidthItem + ",h-" + nHeightItem + ",dpr-" + window.devicePixelRatio.toString())} width="100%"  style={{ maxWidth: nWidthItem, maxHeight: nHeightItem, borderRadius: 12 }} alt={item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,"100%",null,item.title))}} />
            <h6 style={{color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: -7}}>{item.genreLib}</h6>
            {item.isExclusif == 1 && (item.isFree == 1 || currentUser.statut == 20) && <div style={{width: '100%', maxWidth: nWidthItem, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 0, borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingBottom: 10}}><img src={exclusif} style={{height: 20, borderRadius: 0, marginTop: 5, marginRight: 5}} alt="Exclusif" /></div>}
            {item.isFree != 1 && currentUser.statut != 20 && <div style={{color: "#ff6833", position: "absolute", top: 15, right: 10, display: "flex", alignItems: "center"}}><i class="fa-solid fa-lock fa-xl"></i></div>}
          </div>
  
          </GridListTile>

            </Link>
        ))}
      </GridList>
            )
          }
    </div>
          </GridItem>
     }
          {tabSerie.length > 0 &&
          <GridItem xs={12} style={{marginTop:30, marginLeft: -5}}>
            {tabSerie.length == 1 &&
              <h4 className={classesList.smallTitle}>Série</h4>
            }
            {tabSerie.length > 1 &&
              <h4 className={classesList.smallTitle}>Séries</h4>
            }
          <div className={classesList.root} style={{justifyContent: 'flex-start'}}>
         {tabSerie.length >= getItemColumns() ?
              <Carousel
              show={getItemColumns()}
            >
              {tabSerie.map((item) => (
                <GridListTile style={{ padding: 8 }} className="item">
                  <Link style={{ margin: 8 }} to={{
                    pathname: "/detail/" + item.Id
                  }} >
                    <div style={{ display: "flex" }}>
                      <img src={encodeURI(item.mini_title_path+"?tr=w-" + nWidthItem + ",h-" + nHeightItem + ",dpr-" + window.devicePixelRatio.toString())} width="100%" style={{ maxWidth: nWidthItem, maxHeight: nHeightItem }} alt={item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,"100%",null,item.title))}} />
                      <h6 style={{ color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: 16 }}>{item.genreLib}</h6>
                      {item.isExclusif == 1 && (item.isFree == 1 || currentUser.statut == 20) && <div style={{width: '100%', maxWidth: nWidthItem, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 24, borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingBottom: 10}}><img src={exclusif} style={{height: 20, borderRadius: 0, marginTop: 5, marginRight: 5}} alt="Exclusif" /></div>}
                      {item.isNewEpisode == 1 && <div style={{height: 25, background: "rgba(185,185,185,0.7)", position: "absolute", top: 24, left: 0, display: "flex", alignItems: "center", borderTopLeftRadius: 12, borderBottomRightRadius: 12}}><h6 style={{margin: "0 10px", color: "#FFF"}}>Nouvel épisode</h6></div>}
                      {item.isFree != 1 && currentUser.statut != 20 && <div style={{color: "#ff6833", position: "absolute", top: 40, right: 10, display: "flex", alignItems: "center"}}><i class="fa-solid fa-lock fa-xl"></i></div>}
                    </div>
                  </Link>
                </GridListTile>
              ))}

            </Carousel> : (
              <GridList className={classesList.gridList} style={{overflowY: "hidden", overflowX: "hidden", paddingRight: 5, paddingBottom: 20}}>
              {tabSerie.map((item) => (
                <Link style={{margin:8}} to={{
                  pathname: "/detail/" + item.Id
                }}>
           
              <GridListTile class="item" key={item.mini_title_path} style={{width:nWidthItem, height:nHeightItem}}>
         
              <div style={{display: "flex"}}>
              <img src={encodeURI(item.mini_title_path+"?tr=w-" + nWidthItem + ",h-" + nHeightItem + ",dpr-" + window.devicePixelRatio.toString())} width="100%" style={{ maxWidth: nWidthItem, maxHeight: nHeightItem, borderRadius: 12 }} alt={item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,"100%",null,item.title))}} />
              <h6 style={{color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: -7}}>{item.genreLib}</h6>
              {item.isExclusif == 1 && (item.isFree == 1 || currentUser.statut == 20) && <div style={{width: '100%', maxWidth: nWidthItem, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 0, borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingBottom: 10}}><img src={exclusif} style={{height: 20, borderRadius: 0, marginTop: 5, marginRight: 5}} alt="Exclusif" /></div>}
              {item.isNewEpisode == 1 && <div style={{height: 25, background: "rgba(185,185,185,0.7)", position: "absolute", top: 0, left: 0, display: "flex", alignItems: "center", borderTopLeftRadius: 12, borderBottomRightRadius: 12}}><h6 style={{margin: "0 10px", color: "#FFF"}}>Nouvel épisode</h6></div>}
              {item.isFree != 1 && currentUser.statut != 20 && <div style={{color: "#ff6833", position: "absolute", top: 15, right: 10, display: "flex", alignItems: "center"}}><i class="fa-solid fa-lock fa-xl"></i></div>}
            </div>
    
            </GridListTile>
  
              </Link>
          ))}
        </GridList>
            )
            }
    </div>
          </GridItem>
      }
          </GridContainer>
             </div>
            
           </div>
        </div>
      </div>
      <Footer />
      </div>
               )}
    </div>
    
  );

}
