import axios from "axios";
import UrlAPI from "./UrlAPI"

export function unsubscribe(idUser, email) {
    const url = UrlAPI.sUrl+'/user/unsubscribe/' + idUser.toString() + "/" + email;
    return axios(url,{
        method: 'PUT',
        headers:{
            'Content-Type': "application/json"
        },
    })

}