import axios from "axios";
import UrlAPI from "API/UrlAPI"
import {tokenAPI} from "API/TokenAPI"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  osVersion,
  osName
} from "react-device-detect";
import { FormatColorResetRounded } from "@material-ui/icons";
const API_URL = UrlAPI.sUrl+"/auth/";

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "signin", {
        username,
        password,
        deviceName:osName+" "+osVersion
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  
  async refreshToken(userId, token) {
    const fetchData  = async () => {

      const respUsers = await tokenAPI(userId, token);

      return respUsers.data
    }    
    await fetchData().then(result => {
          if(result){
           
              if (result.accessToken == null) {
                console.log("pas bon refresh 3");
                this.logout();
              } else {
            if(result.status == 201){
              return result;
            }else{
             
              if (result.accessToken) {
                localStorage.setItem("user", JSON.stringify(result));
                return result;
              } else {
                console.log("pas bon refresh");
              }
           
            }
          }
          }else{
            console.log("pas bon refresh 2")
         
          }
      }
      )
    .catch(error=>{
        console.log(error);
    
          
          })
  }
}

export default new AuthService();
