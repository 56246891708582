import axios from "axios";
import UrlAPI from "./UrlAPI"

export function getOneBannerInfo(pi_sToken, idBanner) {
  
  const url = UrlAPI.sUrl+'/item/infoOneBanner/' + idBanner.toString();
  return axios(url,{
    method: 'GET', 
    headers:{
        'Content-Type': "application/json",
        'x-access-token': pi_sToken
    }
  })
}