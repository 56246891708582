/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Link,
  useParams
} from "react-router-dom";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import ParallaxBlur from "components/Parallax/ParallaxBlur.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MailIcon from '@material-ui/icons/Mail';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import AuthService from "../../services/auth.service.js";
import styles from "assets/jss/material-kit-react/views/profilePage";
import GridList from '@material-ui/core/GridList';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import useWindowSize from 'components/useWindowSize';
import logoApp from "assets/img/Logo_App.png"
import {
  isMobile,
  isIOS,
  isAndroid
} from "react-device-detect";

import { getUser } from '../../API/UserAPI';
import { updateUserProps } from "API/UpUserPropsAPI.js";
import { addUserProps } from "API/AddUserPropsAPI.js";

const dashboardRoutes = [];;
let isAdmin = false
let sNameBan1 = "";
let sNameBan2 = "";
let sNameBan3 = "";
let sNameBan4 = "";
//console.log(data);

class ItemTab {
  constructor(item, orderNum) {
    this.item = item;
    this.orderNum = orderNum;
  }
}
const useStyles = makeStyles(styles);
const useStylesList = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: 'transparent'
    //background: "linear-gradient(rgba(20, 20, 20, 1), rgba(70, 32, 18, 1)),rgba(20, 20, 20, 1);" 
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    paddingLeft: 5,
  },
  title: {
    fontFamily: "Bubblebody Bold",
    backgroundImage: "linear-gradient(to top, #FF6833 0%, #ff9833 100%)",
    backgroundClip: "text",
    color: "#ef5101",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  },
  titleBar: {
    borderRadius: 12,
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  }
}));
export default function ProfilePage(props) {
  const classes = useStyles();
  const classesList = useStylesList();
  const { width } = useWindowSize();
  let currentUser = AuthService.getCurrentUser();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  let history = useHistory();

  if (currentUser == null) {
    history.push('/login');
  } else {
    // Kévin
    if (currentUser.id == 100020) {
      isAdmin = true;
    }
    // Maxime
    if (currentUser.id == 100008) {
      isAdmin = true;
    }
    // Alexis
    if (currentUser.id == 100048) {
      isAdmin = true;
    }
    // Guillaume
    if (currentUser.id == 100082) {
      isAdmin = true;
    }
  }

  
  // console.log(getItemsAll().then(data => console.log(data)));

  const [User, setUser] = useState({});
  //const [data, setData] = useState([]);
  const [sortiMail, setSortiMail] = useState(false);

  const [isLoading, setLoading] = useState(true);

  const fetchData = async () => {

    const respUser = await getUser(currentUser.accessToken, currentUser.id);

    setUser(respUser.data);
    const sortiMailItem = respUser.data.Table_User_Props.find(x => x.idProps === "sortiMail");
    if (sortiMailItem && sortiMailItem.value == "1") {
      setSortiMail(true);
    } else {
      setSortiMail(false);
    }

    window.scrollTo({top: 0, left: 0, behavior: "instant"});
    
    setLoading(false);
  }

  const unsubscribe = () => {
    if (sortiMail) {
      let json = {
        value: "0"
      }
      updateUserProps(currentUser.accessToken, currentUser.id, "sortiMail", json).then(response => {
        setSortiMail(false);
      })
    } else if (!sortiMail && User.Table_User_Props.findIndex(x => x.idProps === "sortiMail") != -1) {
      let json = {
        value: "1"
      }
      updateUserProps(currentUser.accessToken, currentUser.id, "sortiMail", json).then(response => {
        setSortiMail(true);
      })
    } else {
      let json = {
        idUser: currentUser.id,
        idProps: "sortiMail",
        name: "E-mail de sortie",
        value: "1"
      }
      addUserProps(currentUser.accessToken, json).then(response => {
        setSortiMail(true);
      })
    }
  }

  useEffect(() => {
    const getAPI = async () => {
      let validtoken = true;
      let newToken = false;

      if (currentUser != null) {
        fetchData().catch(
          function (error) {
            // console.log(error.response);
            if (error.response) {
              if (error.response.status == 401) {
                validtoken = false;
              }
              if (error.response.status == 402) {
                validtoken = false;
              }
              if (error.response.status == 403) {
                validtoken = false;
              }
            } else {
              //  console.log('Error', error.message);
            }
            if (!validtoken && !newToken) {
              newToken = true;
              AuthService.refreshToken(currentUser.id, currentUser.accessToken).then(
                currentUser = AuthService.getCurrentUser(),
                setTimeout(async () => {
                  getAPI()
                }, 500)
              ).catch(error => {
                // console.log(error)

                AuthService.logout();
              })
            } else {
              AuthService.logout();
            }
          })
      }
    }
    getAPI()
  }, []);


  const logOut = () => {
    localStorage.removeItem('user');
  }

  if (currentUser == null) {
    // Mettre ici le code de Guillaume
    return ""
  } else {
    return (

      <div style={{ backgroundColor: "#1B1B1B", }}>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="SOFA vod"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 100,
            color: "#1B1B1B"
          }}
          {...rest}
        />
        {isLoading ? <div className={classes.container}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress style={{ color: "#ec6f39" }} />
            <div style={{ position: "absolute", bottom: 50 }}>
              <Link to={'/login'} style={{ textDecoration: "none" }}>
                <Button
                  color={width > 640 ? "sofa" : "sofaM"}
                  size="lg"
                  //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                  rel="noopener noreferrer"
                  onClick={logOut}
                >
                  Se déconnecter



                </Button>
              </Link>
            </div>
          </div>
        </div> : (
          <div>
            <ParallaxBlur blur filter image={encodeURI(User.photo + "?tr=bl-30")} />
            <div className={classNames(classes.main)}
              style={{ background: "linear-gradient(135deg,#141414, #462012,#141414)", minHeight: '76vh', margin: 0 }}>
              <div className={classes.container}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.profile} >
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={encodeURI(User.photo+"?tr=w-210,h-210,dpr-" + window.devicePixelRatio.toString())} width={210} height={210} style={{ maxWidth: 210, margin: 0 }} alt="..." className={imageClasses} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                      </div>
                      <div className={classes.name} style={{marginTop:-110}}>
                        <h2 className={classesList.title} style={{ marginTop: 30 }}>{User.firstName ? User.firstName + " " + User.name : User.name}</h2>
                        {isMobile && 
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 5 }}>
                      <a href={isIOS ? "https://apps.apple.com/fr/app/sofa-vod/id1525885609" : isAndroid ? "https://play.google.com/store/apps/details?id=com.kevingraillot.sofa" : ""} style={{ textDecoration: "none", display: "flex", alignItems: "center", color: "black", outline: "none", border: "none" }}>
                        <Button
                          color="white"
                          size="lg"
                          rel="noopener noreferrer"
                          style={{ borderRadius: 20, paddingLeft: 20, paddingRight: 20 }}
                        >
                          <img src={logoApp} style={{ width: 60, marginBottom:-30, marginRight: 10, borderRadius: 10 }} />
                          Télécharger notre application
                        </Button>
                      </a>
                    </div>
                   }
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
                <div style={{ color: '#FFF', textAlign: "center" }}>
                  {User.statut != 20 && <div>
                    <p>Le contenu auxquel vous avez accès est limité. Choisissez une autre offre pour accéder librement à des centaines de films et séries !</p>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <a href="https://moncompte.sofavod.com/shop" style={{ minWidth: "25%", marginBottom: 20 }}>
                        <Button
                          style={{ width: "100%" }}
                          color={width > 640 ? "sofa" : "sofaM"}
                          size="lg"
                        >
                          Changer mon offre
                        </Button>
                      </a>
                    </div>
                  </div>}
                  <div>
                    <div className={classes.root}>
                      <Grid container spacing={4}>
                        <Grid item xs>
                          <h5>Vos informations</h5>
                          <Paper elevation={3} className={classes.paper} style={{backgroundColor:'rgba(252, 252, 252, 0.9)'}}>
                            <List className={classes.root}>
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar style={{backgroundColor:'#ff600f'}}>
                                    <AccountCircleIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Titulaire du compte" secondary={User.firstName ? User.firstName + " " + User.name : User.name} />
                              </ListItem>
                              <ListItem>

                                <ListItemAvatar>
                                <Avatar style={{backgroundColor:'#ff600f'}}>
                                    <MailIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Adresse e-mail" secondary={User.email} />
                              </ListItem>
                              <ListItem>
                                <ListItemAvatar>
                                <Avatar style={{backgroundColor:'#ff600f'}}>
                                    <EventAvailableIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Date de création" secondary={new Date(User.createdAt).toLocaleDateString()} />
                              </ListItem>
                              <ListItem>
                                <ListItemAvatar>
                                <Avatar style={{backgroundColor:'#ff600f'}}>
                                    <MonetizationOnIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Offre" secondary={User.statut == 20 ? "Payante" : "Gratuite"} />
                                {User.statut != 20 && <a href="https://moncompte.sofavod.com/shop">
                                  <Button
                                    color={width > 640 ? "sofa" : "sofaM"}
                                    size="sm"
                                  >
                                    Changer mon offre
                                  </Button>  
                                </a>}
                              </ListItem>
                            </List>
                          </Paper>
                        </Grid>
                        <Grid item xs>
                          <h5>Votre espace client</h5>
                          <Paper elevation={3} className={classes.paper} style={{backgroundColor:'rgba(252, 252, 252, 0.9)'}}>
                            <div style={{padding:20}}>
                            <p>Depuis votre espace client vous pouvez : </p>
                            <ul style={{fontWeight: 900, listStyle: "none", padding: 0}}>

                              <li>Consulter vos factures</li>

                              <li>Gérer votre abonnement</li>

                              <li>Modifier vos informations</li>

                            </ul>
                            <a href="https://moncompte.sofavod.com/" target="_blank" style={{ textDecoration: "none" }} rel="noreferrer">
                            <Button style={{alignSelf:'center'}}
                        color={width > 640 ? "sofa4" : "sofaM"}
                        size="sm"
                        target="_blank"
                        //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                        rel="noopener noreferrer"
                      >
                        Accéder à mon espace client



                      </Button>
                    </a>
                    <label style={{ display: "flex", justifyContent: "center", alignItems: "end", cursor: "pointer" }} for="sortiMail"><input style={{ height: 20, width: 20, cursor: "pointer" }} type="checkbox" id="sortiMail" value={sortiMail} onClick={unsubscribe} checked={sortiMail} />&nbsp;Je souhaite recevoir des informations sur les futures sorties</label>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <Link to='/login' className={classes.buttonLink} style={{ textDecoration: "none", marginTop: 20, height: "fit-content", display: "flex", alignItems: "center" }} onClick={() => {AuthService.logout()}}>
                        <p style={{ marginBottom: 0, marginRight: 10 }}>Se déconnecter</p><i class="fa-solid fa-arrow-right-from-bracket fa-xl"></i>
                      </Link>
                    </div>
                    </div>
                          </Paper>
                        </Grid>

                      </Grid>                   
                    </div>    
                  </div>
                 
             

                </div>
              </div>



            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}
