// API/LoginAPI.js
import axios from "axios";
import UrlAPI from './UrlAPI'
import {
  osVersion,
  osName
} from "react-device-detect";

export function loginAPI(pi_sUser, pi_sPwd) {

const url = UrlAPI.sUrl+'/auth/signin'
  return axios(url,{
    method: 'post',
    data:{username:pi_sUser, password:pi_sPwd,deviceName:osName+osVersion} ,
    headers:{
        'Content-Type': "application/json",
    },
    })  
    
}