import axios from "axios";
import UrlAPI from "./UrlAPI"

export function getUserItemById(pi_token, id) {
    const url = UrlAPI.sUrl+'/user/lstUserItemByUser/'+id.toString()
    return axios(url,{
        method: 'GET',
        headers:{
            'Content-Type': "application/json",
            'x-access-token': pi_token
        },
    })

}