import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HashRouter } from 'react-router-dom'
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import HttpsRedirect from 'react-https-redirect';

ReactDOM.render(

  <BrowserRouter >
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();




