import axios from "axios"
import UrlAPI from "./UrlAPI"

export function getRegionAll(pi_token) {
    const url = UrlAPI.sUrl+'/item/region/all'
    return axios(url,{
        method: 'GET',
        headers:{
            'Content-Type': "application/json",
            'x-access-token': pi_token
        },
    }) 
}