/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import AuthService from "services/auth.service";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const currentUser = AuthService.getCurrentUser();
  const classes = useStyles();
  return (

    <nav className="navbar navbar-expand-lg navbar-dark">
    <List className={classes.list}>
      <div>
      <ListItem className={classes.listItem}>
        <Link to={'/'} className={classes.navLink}>Accueil</Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to={'/search'} className={classes.navLink}>Rechercher</Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to={'/mysofa'} className={classes.navLink}>Mon Sofa</Link>
      </ListItem>
      </div>
      <ListItem className={classes.listItem}>
        <Link to={'/profile'} className={classes.navLink + " " + classes.profileLink}>Profil{currentUser && <img src={currentUser.photo + "?tr=w-40,h-40,dpr-" + window.devicePixelRatio.toString()} width={40} height={40} />}</Link>
      </ListItem> 
    
      {/* <ListItem className={classes.listItem}>
       
        <Tooltip
          id="instagram-twitter"
          title="Suivez nous sur twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://twitter.com/SofaVod"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-twitter"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Suivez nous sur facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/SOFAvod/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Suivez nous sur instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/sofavod/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem> */}
      
    </List>
    </nav>
 
  );
}
