/* eslint-disable no-unused-expressions */
import React, {useState,useEffect,useRef} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Link,
  useParams
} from "react-router-dom";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import ParallaxBlur from "components/Parallax/ParallaxBlur.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import { Redirect } from "react-router-dom";
import AuthService from "../../services/auth.service.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import GridList from '@material-ui/core/GridList';
import Paper from '@material-ui/core/Paper';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import CircularProgress from '@material-ui/core/CircularProgress';
import useWindowSize from 'components/useWindowSize';
import Carousel from 'components/CarouselMulti/Carousel';
import { useHistory } from 'react-router-dom';




import { getItemByTitle } from '../../API/TitleSearchAPI';
import { getPeopleByName } from '../../API/PeopleSearchAPI';
import { getCategoryAll } from '../../API/CategoryAPI.js';
import { getRegionAll } from '../../API/RegionAPI.js';
import { getBanner } from '../../API/BannerAPI';
import { getItemsAll } from '../../API/ItemsAPI.js'
import { getOneBannerInfo } from "API/GetOneBannerInfoAPI.js";
import { getTheme } from "API/GetThemeAPI.js";
import { TextField } from "@material-ui/core";
import exclusif from '../../assets/img/exclusif.png';
import noImageAVIF from "assets/img/Fond_lampadaire_Portrait.avif";
import noImage from "assets/img/Fond_lampadaire_Portrait.jpg";
import noImageAVIFL from "assets/img/Fond_lampadaire.avif";
import noImageL from "assets/img/Fond_lampadaire.jpg";


const dashboardRoutes = [];

let sNameBan1 = "";
let sNameBan2 = "";
let sNameBan3 = "";
let sNameBan4 = "";

const nWidthItem = 300;
const nHeightItem = 170;
const nWidthPeople = 100;
const nHeightPeople = 100;
    //console.log(data);
    
    class ItemTab {
      constructor(item,orderNum) {
        this.item = item;
        this.orderNum = orderNum;
      }
    }
const useStyles = makeStyles(styles);
const useStylesList = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor:'transparent'
    //background: "linear-gradient(rgba(20, 20, 20, 1), rgba(70, 32, 18, 1)),rgba(20, 20, 20, 1);" 
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    fontFamily: "Bubblebody Light",
    backgroundImage: "linear-gradient(to top, #FF6833 0%, #ff9833 100%)",
    backgroundClip: "text",
    color: "#ef5101",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    marginLeft: 60,
    marginTop: 20
  },
  titleBar: {
    borderRadius:12,
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

export default function SearchPage(props) {
  const classes = useStyles();
  const classesList = useStylesList();
  let currentUser = AuthService.getCurrentUser();
  const { width } = useWindowSize();



   // console.log(getItemsAll().then(data => console.log(data)));

   const [User, setUser] = useState({});
   //const [data, setData] = useState([]);
   const [tabItemSearch, setTabItemSearch] = useState([]);
   const [tabFilmSearch, setTabFilmSearch] = useState([]);
   const [tabSerieSearch, setTabSerieSearch] = useState([]);
   const [tabPeopleSearch, setTabPeopleSearch] = useState([]);
   const [tabCategorySearch, setTabCategorySearch] = useState([]);
   const [tabRegionSearch, setTabRegionSearch] = useState([]);
   const [tabCategory, setTabCategory] = useState([]);
   const [tabRegion, setTabRegion] = useState([]);
   const [tabBanner, setTabBanner] = useState([]);
   const [titleTab, setTitleTab] = useState("");
   const [searchText, setSearchText] = useState("");
   const [onSearch, setSearch] = useState(false);
   const [buttonHidden, setButtonHidden] = useState(false);

   const searchRef = useRef();

   searchRef.current = searchText;

    const [isLoading, setLoading] = useState(true);

    let history = useHistory();

    if (currentUser == null) {
      history.push('/login');
    }
  
  const onChangeSearchText = (e) => {
    let newSearchText = e.target.value;
    setSearchText(newSearchText);
    setButtonHidden(false);
    if (newSearchText.length < 2) {
      setTabFilmSearch([]);
      setTabSerieSearch([]);
      setTabPeopleSearch([]);
      setSearch(false);
    }
  }

  const searchButton = () => {
    setLoading(true);
    setButtonHidden(true);
    getItemByTitle(currentUser.accessToken, searchRef.current).then(
      (response) => {
        sessionStorage.setItem('search', searchRef.current);
        let dataI = response.data.Table_Item;
        let dataP = response.data.Table_People;
        let dataC = response.data.Table_Category;
        let dataR = response.data.Table_Region;
        let series = [];
        let films = [];
        dataI.forEach(element => {
          if (element.idType == 1) {
            series.push(element);
          } else {
            films.push(element);
          }
        })
        setTabFilmSearch(films);
        setTabSerieSearch(series);
        setTabPeopleSearch(dataP);
        setTabCategorySearch(dataC);
        setTabRegionSearch(dataR);
        setSearch(true);
        setLoading(false);
      }
    )
  }

  const cancelButton = () => {
    setSearchText("");
    sessionStorage.removeItem('search');
    let element = document.getElementById("searchText");
    element.value = "";
    element.focus();
    setTabFilmSearch([]);
    setTabSerieSearch([]);
    setTabPeopleSearch([]);
    setSearch(false);
  }

  const fetchData = async () => {
    window.scrollTo({top: 0, left: 0, behavior: "instant"});
    if (tabBanner.length == 0 && currentUser != null) {
      const respBanner = await getOneBannerInfo(currentUser.accessToken, 113);
      const respTheme = await getTheme(currentUser.accessToken);
      const respCategory = await getCategoryAll(currentUser.accessToken, null);
      const respRegion = await getRegionAll(currentUser.accessToken);

      setTitleTab(respBanner.data.name);
      setTabBanner(respTheme.data);
      setTabCategory(respCategory.data);
      setTabRegion(respRegion.data);

      if (sessionStorage.getItem('search') && sessionStorage.getItem('search').length > 0) {
        document.getElementById('searchText').value = sessionStorage.getItem('search');
        setSearchText(sessionStorage.getItem('search'));
        searchButton();
      } else {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  const getItemColumns = () => {
    let nItem = width / 320;
    return Math.trunc(nItem);
  }

  const getGridListCols = () => {
    let nColumns = width / 250;
    return Math.trunc(nColumns)
 }

 const getPeopleColumns = () => {
  let nPeople = width / 220;
  return Math.trunc(nPeople);
}

const getGenreColums = () => {
  let nGenre = width / 230;
  return Math.trunc(nGenre);
}

const getDepartementColums = () => {
  let nDepartement = width / 250;
  return Math.trunc(nDepartement);
}

const defaultImageL = (style, width, height, title) => {
  let picture = document.createElement("picture");
  picture.setAttribute("style", "position: relative");
  let source = document.createElement("source");
  source.setAttribute("srcSet", noImageAVIFL);
  source.setAttribute("type", "image/avif");
  let img = document.createElement("img");
  img.setAttribute("src", noImageL);
  let jsonStyle = {};
  Object.values(JSON.parse(JSON.stringify(style))).forEach(element => {
    jsonStyle[element] = style[element];
  })
  img.setAttribute("style", JSON.stringify(jsonStyle).replace(/","/g, ";").replace(/["'{}]/g, ""));
  img.setAttribute("width", width);
  img.setAttribute("height", height);
  let div = document.createElement("div");
  div.setAttribute("style", "position: absolute; top: 0; width: 100%; height: 100%; padding: 2.5%; display: grid; align-items: center; justify-content: center");
  let text = document.createElement("h3");
  text.setAttribute("style", "color: #FFF; font-size: 20px; font-family: Bubblebody Light; text-align: center");
  text.appendChild(document.createTextNode(title));
  div.appendChild(text);
  picture.appendChild(source);
  picture.appendChild(img);
  picture.appendChild(div);
  return picture;
}

const defaultImage = (style, width, height, title) => {
  let picture = document.createElement("picture");
  picture.setAttribute("style", "position: relative");
  let source = document.createElement("source");
  source.setAttribute("srcSet", noImageAVIF);
  source.setAttribute("type", "image/avif");
  let img = document.createElement("img");
  img.setAttribute("src", noImage);
  let jsonStyle = {};
  Object.values(JSON.parse(JSON.stringify(style))).forEach(element => {
    jsonStyle[element] = style[element];
  })
  img.setAttribute("style", JSON.stringify(jsonStyle).replace(/","/g, ";").replace(/["'{}]/g, ""));
  img.setAttribute("width", width);
  img.setAttribute("height", height);
  let div = document.createElement("div");
  div.setAttribute("style", "position: absolute; top: 0; width: 100%; height: 100%; padding: 2.5%; display: grid; align-items: center; justify-content: center");
  let text = document.createElement("h3");
  text.setAttribute("style", "color: #FFF; font-size: 20px; font-family: Bubblebody Light; text-align: center");
  text.appendChild(document.createTextNode(title));
  div.appendChild(text);
  picture.appendChild(source);
  picture.appendChild(img);
  picture.appendChild(div);
  return picture;
}
 
  const { ...rest } = props;
  if (currentUser == null){
    // Mettre ici le code de Guillaume
    return ""
  }else{
  return (
    
    <div style={{ backgroundColor: "#1B1B1B",}}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="SOFA vod"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "#1B1B1B" 
        }}
        {...rest}
      />
      <div>
      <ParallaxBlur blur small filter image={encodeURI("https://ik.imagekit.io/sofavod/sofa-sofaback-1629107692456.jpg?tr=bl-30")} />

      <div className={classNames(classes.main)} 
        style={{background: "linear-gradient(135deg,#141414, #462012,#141414)", minHeight: '76vh', margin: 0}}>
    <div>
 
      <GridContainer justify="center" style={{marginRight: 0}}>
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile} style={{ position: "absolute", top: -125, width: "100%", maxWidth: "100vw" }}>
                  <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <div style={{ borderRadius: 8, overflow: "hidden", width: "100%", maxWidth: "90vw" }}>
                      <input
                      type="text"
                      className="form-control"
                      name="searchText"
                      id="searchText"
                      onChange={onChangeSearchText}
                      onKeyPress={(e) => {e.key == "Enter" && searchText.length > 1 && !buttonHidden && searchButton()}}
                      placeholder="Rechercher..."
                      autoFocus
                      />
                    </div>
                    {searchText.length > 1 && !buttonHidden &&
                      <Button color="sofa" size="lg" onClick={searchButton} style={{alignSelf: "center"}}>Rechercher</Button>
                    }
                    {searchText.length > 0 && buttonHidden &&
                      <button style={{ color: "#FFF", backgroundColor: "rgba(128, 128, 128, 0.5)", borderRadius: "100%", position: "absolute", alignSelf: "center", marginRight: 2, height: 33, width: 33, padding: 0, fontSize: 22, right: 0 }} onClick={cancelButton}><p style={{ marginBottom: 0 }}>X</p></button>
                    }
                  </div>
                  
                </div>
              </GridItem>
            </GridContainer>
      
      {isLoading ? <div className={classes.container}>
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '30vh'}}>
        <CircularProgress style={{color:"#ec6f39"}} />
      </div>       
         </div> : (
             <div>
               {onSearch ? 
               <div>
                 {tabCategorySearch.length > 0 &&
                  <h3 className={classesList.title}>Genres :</h3>
                 }         
          <GridList style={width >= 840 ? {paddingLeft: 15, overflow: "hidden", paddingBottom: 20, margin: "0 0 0 40px", flexWrap: "wrap"} : {paddingLeft: 15, overflow: "hidden", paddingBottom: 20, margin: 0, flexWrap: "wrap"}} cellHeight={210} className={classes.gridList} cols={getGenreColums()}>
              {tabCategorySearch.map((item) => (
                <div>
                  <div style={{padding: 8, maxWidth: 200}} className="item">
                    <Link style={{margin:8}} to={{
            pathname: "/genre",
            state: item

          }} >
                        <div style={{width:190, height:190,borderRadius:12,display:"flex"}}>
                          <img src={encodeURI(item.url+"?tr=w-190,h-190,dpr-" + window.devicePixelRatio.toString())} style={{borderRadius:12}} width={190} height={190} alt={item.url} loading="lazy" />
                          <div style={{ height: 190, display: "grid", alignContent: "center", position: "relative", top: 0, right: 190 }}>
                            <img src={encodeURI(item.imageLogo+"?tr=w-190,h-" + (190*9/16).toString() + ",dpr-" + window.devicePixelRatio.toString())} width={190} height={190*9/16} alt={item.imageLogo} loading="lazy" />
                          </div>
                        </div>
                        </Link>
                    </div>
                </div>
))}
               
            </GridList>

          {tabRegionSearch.length > 0 &&
            <h3 className={classesList.title}>Départements :</h3>
          }
          <GridList style={width >= 840 ? {paddingLeft: 15, overflow: "hidden", paddingBottom: 20, margin: "0 0 0 40px", flexWrap: "wrap"} : {paddingLeft: 15, overflow: "hidden", paddingBottom: 20, margin: 0, flexWrap: "wrap"}} cellHeight={210} className={classes.gridList} cols={getDepartementColums()}>
              {tabRegionSearch.map((item) => (
                <div>
                    <div style={{padding: 12, maxWidth: 220}} className="item">
                    <Link style={{textDecoration: "none"}} to={{
            pathname: "/departement",
            state: item

          }}>
                        <div style={{width:220, borderRadius:20,height:210, display: "flex", flexDirection: "column", alignItems: "center"}}>
                          <img src={encodeURI(item.logo_path+"?tr=w-170,h-170,dpr-" + window.devicePixelRatio.toString())} width={170} height={170} style={{borderRadius:12}} alt={item.name} loading="lazy" />
                          <h6 style={{color:'#FFF',marginTop:15,textAlign: "center", marginRight: 5}}>{item.name}</h6>
                        </div>
                        </Link>
                    </div>
                </div>
))}
               
            </GridList>
             {tabFilmSearch.length > 0 &&
               <h3 className={classesList.title}>Films :</h3>
             }
        <GridList style={width >= 840 ? {paddingLeft: 15, overflow: "hidden", paddingBottom: 20, margin: "0 0 0 40px", flexWrap: "wrap"} : {paddingLeft: 15, overflow: "hidden", paddingBottom: 20, margin: 0, flexWrap: "wrap"}} cellHeight={350} className={classes.gridList} cols={getGridListCols()}>

          {tabFilmSearch.map((item) => (
              <GridListTile  class="item" key={item} cols={1} style={{ marginTop: 20 }}>
          <Link style={{margin:8}} to={{
          pathname: "/detail/" + item.Id
          }}>

            <div style={{display: "flex"}}>
              <img src={encodeURI(item.portrait_title_path+"?tr=w-200,h-300,dpr-" + window.devicePixelRatio.toString())} width={200} height={300} style={{borderRadius:12}} alt={item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,200,300,item.title))}} />
              <h6 style={{color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: 18}}>{item.genreLib}</h6>
              {item.isExclusif == 1 && (item.isFree == 1 || currentUser.statut == 20) && <div style={{width: 200, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 24, borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingBottom: 10}}><img src={exclusif} style={{height: 20, borderRadius: 0, marginTop: 5, marginRight: 5}} alt="Exclusif" /></div>}
              {item.isNewEpisode == 1 && <div style={{height: 25, background: "rgba(185,185,185,0.7)", position: "absolute", top: 24, left: 0, display: "flex", alignItems: "center", borderTopLeftRadius: 12, borderBottomRightRadius: 12}}><h6 style={{margin: "0 10px", color: "#FFF"}}>Nouvel épisode</h6></div>}
              {item.isFree != 1 && currentUser.statut != 20 && <div style={{color: "#ff6833", position: "absolute", top: 40, right: 10, display: "flex", alignItems: "center"}}><i class="fa-solid fa-lock fa-xl"></i></div>}
            </div>

          </Link>
          </GridListTile>
          ))}

        </GridList>
        {tabSerieSearch.length > 0 &&
          <h3 className={classesList.title}>Séries :</h3>
        }
        <GridList style={width >= 840 ? {paddingLeft: 15, overflow: "hidden", paddingBottom: 20, margin: "0 0 0 40px", flexWrap: "wrap"} : {paddingLeft: 15, overflow: "hidden", paddingBottom: 20, margin: 0, flexWrap: "wrap"}} cellHeight={350} className={classes.gridList} cols={getGridListCols()}>

          {tabSerieSearch.map((item) => (
              <GridListTile  class="item" key={item} cols={1} style={{ marginTop: 20 }}>
          <Link style={{margin:8}} to={{
          pathname: "/detail/" + item.Id
          }}>

            <div style={{display: "flex"}}>
              <img  src={encodeURI(item.portrait_title_path+"?tr=w-200,h-300,dpr-" + window.devicePixelRatio.toString())} width={200} height={300} style={{borderRadius:12}} alt={item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,200,300,item.title))}} />
              <h6 style={{color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: 18}}>{item.genreLib}</h6>
              {item.isExclusif == 1 && (item.isFree == 1 || currentUser.statut == 20) && <div style={{width: 200, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 24, borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingBottom: 10}}><img src={exclusif} style={{height: 20, borderRadius: 0, marginTop: 5, marginRight: 5}} alt="Exclusif" /></div>}
              {item.isNewEpisode == 1 && <div style={{height: 25, background: "rgba(185,185,185,0.7)", position: "absolute", top: 24, left: 0, display: "flex", alignItems: "center", borderTopLeftRadius: 12, borderBottomRightRadius: 12}}><h6 style={{margin: "0 10px", color: "#FFF"}}>Nouvel épisode</h6></div>}
              {item.isFree != 1 && currentUser.statut != 20 && <div style={{color: "#ff6833", position: "absolute", top: 40, right: 10, display: "flex", alignItems: "center"}}><i class="fa-solid fa-lock fa-xl"></i></div>}
            </div>

          </Link>
          </GridListTile>
          ))}

        </GridList>
      {tabPeopleSearch.length > 0 &&
        <h3 className={classesList.title}>Personnes :</h3>
      }
      {tabPeopleSearch.length > getPeopleColumns() ?
       <Carousel
        show={getPeopleColumns()}
        >
      {tabPeopleSearch.map((people) => (
        <div>
            <div style={{padding: 8}} className="item">
            <Link style={{ margin:8, textDecoration: "none" }} to={{
              pathname: "/people",
              state: people

            }}>
                <div style={{width:200, height:220,marginTop:40, display: "flex", flexDirection: "column", alignItems: "center"}} >
                  <img src={encodeURI(people.photo_path+"?tr=w-" + nWidthPeople + ",h-" + nWidthPeople + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nWidthPeople} style={{borderRadius: "50%"}} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                  <h6 style={{color:'#FFF',marginTop:15,textAlign:'center'}}>{people.name}</h6>
                </div>
                </Link>
            </div>
        </div>
        ))}
      
   </Carousel> : (
    <GridList className={classesList.gridList} style={{overflowY: "hidden", overflowX: "hidden", paddingBottom: 10, margin: 0}}>
    {tabPeopleSearch.map((people) => (
        <GridListTile style={{padding: 8, maxWidth: 200, height: 220}} className="item">
            <Link  to={{
              pathname: "/people",
              state: people
            }} style={{ textDecoration: 'none' }}>
       
                <div style={{width:200, height:220,marginTop:40, display: "flex", flexDirection: "column", alignItems: "center"}} >
                  <img src={encodeURI(people.photo_path+"?tr=w-" + nWidthPeople + ",h-" + nWidthPeople + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nWidthPeople} style={{borderRadius: "50%"}} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                  <h6 style={{color:'#FFF',marginTop:15,textAlign:'center'}}>{people.name}</h6>
                </div>

          </Link>
        </GridListTile>
      ))}
    </GridList>
      )
      }
      {tabFilmSearch.length == 0 & tabSerieSearch.length == 0 && tabPeopleSearch.length == 0 && tabRegionSearch.length == 0 && tabCategorySearch.length == 0 &&
        <h2 style={{textAlign: "center", marginTop: 50, color: "#FFF"}}>Aucun résultat à votre recherche</h2>
      }
      </div> : (
        <div style={{ paddingBottom: 50 }}>
          <h3 className={classesList.title}>{titleTab}</h3>
          {tabBanner.length > getItemColumns() ? <Carousel show={getItemColumns()}>
            {tabBanner.map(item => <GridListTile style={{padding: 8, maxWidth: nWidthItem}} className="item">
              <Link style={{margin:8,textDecoration: "none"}} to={{
                pathname: "/theme",
                state: item
              }}>
                <div style={width > 640 ? {display: "flex"} : {display: "flex", maxWidth: nWidthItem}}>
                  <img src={encodeURI(item.image_path+"?tr=w-" + nWidthItem + ",h-" + nHeightItem + ",dpr-" + window.devicePixelRatio.toString())} width="100%" style={{maxWidth: nWidthItem, maxHeight: nHeightItem, borderRadius: 12}} alt={item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImageL(e.nativeEvent.target.style,"100%",null,item.title))}} />
                </div>
              </Link>
            </GridListTile>)}
          </Carousel> : <GridList style={width >= 840 ? {paddingLeft: 15, overflow: "hidden", paddingBottom: 20, margin: "0 0 0 50px"} : {paddingLeft: 15, overflow: "hidden", paddingBottom: 20, margin: 0}} className={classes.gridList}>

            {tabBanner.map(item => (
                 <div style={{padding: 8, maxWidth: nWidthItem}} className="item">
                 <Link style={{margin:8,textDecoration: "none"}} to={{
                   pathname: "/theme",
                   state: item
                 }}>
                   <div style={width > 640 ? {display: "flex"} : {display: "flex", maxWidth: nWidthItem}}>
                     <img src={encodeURI(item.image_path+"?tr=w-" + nWidthItem + ",h-" + nHeightItem + ",dpr-" + window.devicePixelRatio.toString())} width="100%" style={{maxWidth: nWidthItem, maxHeight: nHeightItem, borderRadius: 12}} alt={item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImageL(e.nativeEvent.target.style,"100%",null,item.title))}} />
                   </div>
                 </Link>
               </div>
            ))}
   
          </GridList>}
          <h3 className={classesList.title}>Genres :</h3>
          <Carousel show={getGenreColums()}>

            {tabCategory.map((item) => (
                 <div>
                 <div style={{padding: 8, maxWidth: 200}} className="item">
                   <Link style={{margin:8}} to={{
           pathname: "/genre",
           state: item

         }} >
                       <div style={{width:190, height:190,borderRadius:12,display:"flex"}} >
                         <img src={encodeURI(item.url+"?tr=w-190,h-190,dpr-" + window.devicePixelRatio.toString())} style={{borderRadius:12}} width={190} height={190} alt={item.url} loading="lazy" />
                         <div style={{ height: 190, display: "grid", alignContent: "center", position: "relative", top: 0, right: 190 }}>
                          <img src={encodeURI(item.imageLogo+"?tr=w-190,h-" + (190*9/16).toString() + ",dpr-" + window.devicePixelRatio.toString())} width={190} height={190*9/16} alt={item.imageLogo} loading="lazy" />
                         </div>
                       </div>
                       </Link>
                   </div>
               </div>
))}
   
    </Carousel>
    <h3 className={classesList.title}>Départements :</h3>
    <Carousel show={getDepartementColums()}>
              {tabRegion.map((item) => (
                <div>
                    <div style={{padding: 12, maxWidth: 220}} className="item">
                    <Link style={{textDecoration: "none"}} to={{
            pathname: "/departement",
            state: item

          }}>
                        <div style={{width:220, borderRadius:20,height:210, display: "flex", flexDirection: "column", alignItems: "center"}}>
                          <img src={encodeURI(item.logo_path+"?tr=w-170,h-170,dpr-" + window.devicePixelRatio.toString())} width={170} height={170} style={{borderRadius:12}} alt={item.name} loading="lazy" />
                          <h6 style={{color:'#FFF',marginTop:15,textAlign: "center", marginRight: 5}}>{item.name}</h6>
                        </div>
                        </Link>
                    </div>
                </div>
))}
               
            </Carousel>
        </div>
      )}
      </div>

      )}
      

      </div>
   

        
      </div>
      </div>
      <Footer />
    </div>
  );
            }
}
