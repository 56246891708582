import React,{useState,useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Link,
  useParams
} from "react-router-dom";
// @material-ui/icons
// core components
import Button from "components/CustomButtons/Button.js";
import useWindowSize from 'components/useWindowSize';
import { CircularProgress } from "@mui/material";
import { confirmMail } from "API/ConfirmMailAPI";

export default function ConfirmMailPage(props) {
    const idUser = props.match.params.idUser;
    const email = props.match.params.email;
    const { ...rest } = props;
    const { width, height } = useWindowSize();
    const [unsubscribed, setUnsubscribed] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        confirmMail(idUser, email).then(() => {
            setUnsubscribed(true);
            setLoading(false);
            setTimeout(() => {
                let link = document.createElement('a');
                link.setAttribute('href', 'https://test.studio.sofavod.com/');
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                document.removeChild(link);
            }, 5000)
        }).catch(err => {
            setError(true);
            setErrorMessage(err.error ? err.error.message : err.message);
            setLoading(false);
        })
    }, [])

    return (
        <div style={{ backgroundColor: "#FFF",}}>
            <div style={{ backgroundColor: "#FFF", height: height, width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div>
                {isLoading ? 
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
                        <CircularProgress style={{color:"#ec6f39"}} />
                    </div> :
                    unsubscribed ? <p>Votre addresse e-mail a bien été confirmée, merci.</p> : error && <p>{errorMessage}</p>}
                </div>
            </div>
        </div>
    )
}
