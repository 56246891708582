// API/AddStartItemAPI.js
import axios from "axios";
import UrlAPI from "./UrlAPI"

export function addStartItemAPI(pi_token, pi_idUser, pi_idItem, pi_idEpisode, pi_startTime, pi_startHour) {
    
  const url = UrlAPI.sUrl+'/user/addUserPlayer'
  return axios(url,{
    method: 'post',
    data:{
       idUser:pi_idUser,
       IdItem:pi_idItem,
       idEpisode:pi_idEpisode,
       startTime:pi_startTime,
       startHour:pi_startHour,
       endHour:0
      } ,
    headers:{
        'Content-Type': "application/json",
        'x-access-token': pi_token
    },
    })  
}