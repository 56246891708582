import axios from "axios";
import UrlAPI from "./UrlAPI"

export function suggMySofa(pi_token) {
    const url = UrlAPI.sUrl+'/item/getSuggMySofa'
    return axios(url,{
        method: 'GET',
        headers:{
            'Content-Type': "application/json",
            'x-access-token': pi_token
        },
    })
}