/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Link,
  useParams
} from "react-router-dom";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import ParallaxBlur from "components/Parallax/ParallaxBlur.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import { Redirect } from "react-router-dom";
import AuthService from "../../services/auth.service.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import GridList from '@material-ui/core/GridList';
import Paper from '@material-ui/core/Paper';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import CircularProgress from '@material-ui/core/CircularProgress';
import mySofa from "assets/img/mySofa.png";
import Carousel from 'components/CarouselMulti/Carousel';
import useWindowSize from 'components/useWindowSize';
import exclusif from '../../assets/img/exclusif.png'
import { useHistory } from 'react-router-dom';
import moment from "moment";


import { getUserItemById } from '../../API/GetUserItemAPI';
import { getUserPlayerByUser } from "API/GetUserPlayerByUserAPI.js";
import { suggMySofa } from "API/SuggMySofaAPI.js";
import noImageAVIF from "assets/img/Fond_lampadaire.avif";
import noImage from "assets/img/Fond_lampadaire.jpg";


const dashboardRoutes = [];

let sNameBan1 = "";
let sNameBan2 = "";
let sNameBan3 = "";
let sNameBan4 = "";
const nWidthItem = 300;
const nHeightItem = 170;
const nWidthPeople = 100;
const nHeightPeople = 100;
//console.log(data);

class ItemTab {
  constructor(item, orderNum) {
    this.item = item;
    this.orderNum = orderNum;
  }
}
const useStyles = makeStyles(styles);
const useStylesList = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    paddingLeft: 5
    //background: "linear-gradient(rgba(20, 20, 20, 1), rgba(70, 32, 18, 1)),rgba(20, 20, 20, 1);" 
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    marginLeft: "60px !important"
  },
  title: {
    fontFamily: "Bubblebody Light",
    backgroundImage: "linear-gradient(to top, #FF6833 0%, #ff9833 100%)",
    backgroundClip: "text",
    color: "#ef5101",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    margin: "10px 10px 10px 50px"
  },
  titleBar: {
    borderRadius: 12,
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));
export default function MySofaPage(props) {
  const classes = useStyles();
  const classesList = useStylesList();
  let currentUser = AuthService.getCurrentUser();
  const { width } = useWindowSize();


  // console.log(getItemsAll().then(data => console.log(data)));

  const [User, setUser] = useState({});
  //const [data, setData] = useState([]);
  const [favoritesFilmG, setfavoritesFilm] = useState([]);
  const [listsFilmG, setlistsFilm] = useState([]);
  const [listsPeopleG, setlistsPeople] = useState([]);
  const [listsProdG, setlistsProd] = useState([]);
  const [listReprendreG, setListReprendre] = useState([]);
  const [sugg, setSugg] = useState([]);
  let favoritesFilm = [];
  let listsFilm = [];
  let listsPeople = [];
  let listsProd = [];

  const [isLoading, setLoading] = useState(true);

  let history = useHistory();

  if (currentUser == null) {
    history.push('/login');
  }

  const fetchData = async () => {
    const result = await getUserItemById(currentUser.accessToken, currentUser.id);
    const reprendreResults = await getUserPlayerByUser(currentUser.accessToken, currentUser.id);
    result.data.map(function (user) {
      if (user.idProductor > 0) {
        if (user.Table_Productor !== null) {
          //    console.log("id prod :"+user.idProductor )
          listsProd.push(user.Table_Productor)
        }
      }
      if (user.IdItem > 0) {
        if (user.Table_Item !== null) {
          //  console.log("id banner :"+user.idMyBanner )
          if (user.idMyBanner === 2) {
            listsFilm.push(user.Table_Item)
          } else {
            favoritesFilm.push(user.Table_Item)
          }
        }
      }
      if (user.idPeople > 0) {
        if (user.Table_Person !== null) {
          //   console.log("id people :"+user.idPeople )
          listsPeople.push(user.Table_Person)
        }
      }
    });
    let listReprendre = [];
    reprendreResults.data.map(function (uP) {
      let index = listReprendre.findIndex(x => x.Table_Item.Id === uP.Table_Item.Id);
      if (index === -1) {
        uP.Table_Item.start = uP.endTime;
        listReprendre.push(uP);
      } else if (moment(uP.createdAt).isAfter(listReprendre[index].createdAt)) {
        uP.Table_Item.start = uP.endTime;
        listReprendre.splice(index, 1, uP);
      }
    })
    if (favoritesFilm.length == 0 && listsProd.length == 0 && listsPeople.length == 0 && listsFilm.length == 0 && listReprendre.length == 0) {
      const suggResults = await suggMySofa(currentUser.accessToken);
      setSugg(suggResults.data);
    }
    setfavoritesFilm(favoritesFilm);
    setlistsProd(listsProd);
    setlistsPeople(listsPeople);
    setlistsFilm(listsFilm);
    setListReprendre(listReprendre);

    window.scrollTo({top: 0, left: 0, behavior: "instant"});
    
    setLoading(false);


  }
  useEffect(() => {

    if (currentUser != null) {
      const getAPI = async () => {
        let validtoken = true;
        let newToken = false;

        if (currentUser != null) {
          fetchData().catch(
            function (error) {
              // console.log(error.response);
              if (error.response) {
                if (error.response.status == 401) {
                  validtoken = false;
                }
                if (error.response.status == 402) {
                  validtoken = false;
                }
                if (error.response.status == 403) {
                  validtoken = false;
                }
              } else {
                //  console.log('Error', error.message);
              }
              if (!validtoken && !newToken) {
                newToken = true;
                AuthService.refreshToken(currentUser.id, currentUser.accessToken).then(
                  currentUser = AuthService.getCurrentUser(),
                  setTimeout(async () => {
                    getAPI()
                  }, 500)
                ).catch(err => {
                  console.log(err);
                  AuthService.logout();
                  history.push('/');
                })
              } else {
                AuthService.logout();
                history.push('/');
              }
            })
        }
      }
      getAPI()
    }
  }, [])


  const getItemColumns = () => {
    let nItem = width / 300;
    return Math.trunc(nItem);
  }

  const getPeopleColumns = () => {
    let nPeople = width / 220;
    return Math.trunc(nPeople);
  }

  const defaultImage = (style, width, height, title) => {
    let picture = document.createElement("picture");
    picture.setAttribute("style", "position: relative");
    let source = document.createElement("source");
    source.setAttribute("srcSet", noImageAVIF);
    source.setAttribute("type", "image/avif");
    let img = document.createElement("img");
    img.setAttribute("src", noImage);
    let jsonStyle = {};
    Object.values(JSON.parse(JSON.stringify(style))).forEach(element => {
      jsonStyle[element] = style[element];
    })
    img.setAttribute("style", JSON.stringify(jsonStyle).replace(/","/g, ";").replace(/["'{}]/g, ""));
    img.setAttribute("width", width);
    img.setAttribute("height", height);
    let div = document.createElement("div");
    div.setAttribute("style", "position: absolute; top: 0; width: 100%; height: 100%; padding: 2.5%; display: grid; align-items: center; justify-content: center");
    let text = document.createElement("h3");
    text.setAttribute("style", "color: #FFF; font-size: 20px; font-family: Bubblebody Light; text-align: center");
    text.appendChild(document.createTextNode(title));
    div.appendChild(text);
    picture.appendChild(source);
    picture.appendChild(img);
    picture.appendChild(div);
    return picture;
  }

  const { ...rest } = props;
  if (currentUser == null) {
    // Mettre ici le code de Guillaume
    return ""
  } else {
    return (
      <div style={{ backgroundColor: "#1B1B1B" }}>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="SOFA vod"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 100,
            color: "#1B1B1B"
          }}
          {...rest}
        />
        {isLoading ? <div className={classes.container}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress style={{ color: "#ec6f39" }} />
          </div>
        </div> : (
          <div>
            <ParallaxBlur blur small filter image={encodeURI("https://ik.imagekit.io/sofavod/sofa-sofaback-1629107692456.jpg?tr=bl-30")} />
            <div className={classNames(classes.main)}
              style={{ background: "linear-gradient(135deg,#141414, #462012,#141414)", minHeight: '76vh', margin: 0 }}>

              <GridContainer justify="center" style={{ marginRight: 0 }}>
                <GridItem xs={12} sm={12} md={6}>
                  <div className={classes.profile} style={{ marginTop: -300 }}>
                    <div style={{ justifyContent: "center", display: "flex" }}>
                      <img src={mySofa} style={{ width: 400, height: 400 }} alt="..." />
                    </div>

                  </div>
                </GridItem>
              </GridContainer>

              <div style={{marginTop:-100}}>
                <GridContainer style={{ marginLeft: 12, marginRight: 0 }}>
                  {listReprendreG.length > 0 && <GridItem xs={12} style={{ marginTop: 15 }}>


                  {listReprendreG.length > 0 &&
                    <h3 className={classesList.title}>Reprendre</h3>
                  }
                  <div className={classesList.root} style={{ justifyContent: 'flex-start', margin: "0 -15px 0 -32px" }}>
                    {listReprendreG.length >= getItemColumns() ?
                    <Carousel
                    show={getItemColumns()}
                  >
                    {listReprendreG.map((item) => (
                      <GridListTile style={{ padding: 8 }} className="item">
                        {item.Table_Item.idType == 1 ? <Link style={{ margin: 8 }} to={{
                          pathname: "/detail/" + item.Table_Item.Id
                        }} >
                          <div style={{ display: "flex" }}>
                              <img src={encodeURI(item.Table_Item.mini_title_path+"?tr=w-" + nWidthItem + ",h-" + nHeightItem + ",dpr-" + window.devicePixelRatio.toString())} width="100%" style={{ maxWidth: nWidthItem, maxHeight: nHeightItem }} alt={item.Table_Item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,"100%",null,item.Table_Item.title))}} />
                              <h6 style={{ color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: 16 }}>{item.Table_Item.genreLib}</h6>
                              {item.Table_Item.isExclusif == 1 && <div style={{width: '100%', maxWidth: nWidthItem, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 24, borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingBottom: 10}}><img src={exclusif} style={{height: 20, borderRadius: 0, marginTop: 5, marginRight: 5}} alt="Exclusif" /></div>}
                              {item.Table_Item.isNewEpisode == 1 && <div style={{height: 25, background: "rgba(185,185,185,0.7)", position: "absolute", top: 24, left: 0, display: "flex", alignItems: "center", borderTopLeftRadius: 12, borderBottomRightRadius: 12}}><h6 style={{margin: "0 10px", color: "#FFF"}}>Nouvel épisode</h6></div>}
                              {item.endTime / item.totalTime < 1 && <div style={{ position: "absolute", width: "100%", height: "100%", borderRadius: 12, overflow: "hidden", display: "flex", alignItems: "end", bottom: 24 }}><div style={{ width: "100%", height: 5, backgroundColor: "rgba(0, 0, 0, 0.5)", overflow: "hidden" }}><div style={{ width: ( item.endTime / item.totalTime * 100 ) + "%", borderBottomLeftRadius: "12px", backgroundColor: "rgba(255, 104, 55, 0.7)", height: "100%" }}></div></div></div>}
                          </div>
                        </Link> : <Link style={{ margin: 8 }} to={{
                          pathname: "/player",
                          state: item.Table_Item

                        }} >
                          <div style={{ display: "flex" }}>
                              <img src={encodeURI(item.Table_Item.mini_title_path+"?tr=w-" + nWidthItem + ",h-" + nHeightItem + ",dpr-" + window.devicePixelRatio.toString())} width="100%" style={{ maxWidth: nWidthItem, maxHeight: nHeightItem }} alt={item.Table_Item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,"100%",null,item.Table_Item.title))}} />
                              <h6 style={{ color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: 16 }}>{item.Table_Item.genreLib}</h6>
                              {item.Table_Item.isExclusif == 1 && <div style={{width: '100%', maxWidth: nWidthItem, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 24, borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingBottom: 10}}><img src={exclusif} style={{height: 20, borderRadius: 0, marginTop: 5, marginRight: 5}} alt="Exclusif" /></div>}
                              {item.Table_Item.isNewEpisode == 1 && <div style={{height: 25, background: "rgba(185,185,185,0.7)", position: "absolute", top: 24, left: 0, display: "flex", alignItems: "center", borderTopLeftRadius: 12, borderBottomRightRadius: 12}}><h6 style={{margin: "0 10px", color: "#FFF"}}>Nouvel épisode</h6></div>}
                              {item.endTime / item.totalTime < 1 && <div style={{ position: "absolute", width: "100%", height: "100%", borderRadius: 12, overflow: "hidden", display: "flex", alignItems: "end", bottom: 24 }}><div style={{ width: "100%", height: 5, backgroundColor: "rgba(0, 0, 0, 0.5)", overflow: "hidden" }}><div style={{ width: ( item.endTime / item.totalTime * 100 ) + "%", borderBottomLeftRadius: "12px", backgroundColor: "rgba(255, 104, 55, 0.7)", height: "100%" }}></div></div></div>}
                          </div>
                        </Link>}
                      </GridListTile>
                    ))}

                  </Carousel> : (
                        <GridList className={classesList.gridList} style={{ paddingLeft: 5, overflow: "hidden" }}>
                          {listReprendreG.map((item) => (
                            item.Table_Item.idType == 1 ? <Link style={{ margin: 8 }} to={{
                              pathname: "/detail/" + item.Table_Item.Id
                            }}>

                              <GridListTile class="item" key={item.Table_Item.mini_title_path} style={{ width: nWidthItem, height: nHeightItem }}>

                                <div style={{ display: "flex" }}>
                                  <img src={encodeURI(item.Table_Item.mini_title_path+"?tr=w-" + nWidthItem + ",h-" + nHeightItem + ",dpr-" + window.devicePixelRatio.toString())} width="100%" style={{ maxWidth: nWidthItem, maxHeight: nHeightItem, borderRadius: 12 }} alt={item.Table_Item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,"100%",null,item.Table_Item.title))}} />
                                  <h6 style={{ color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: -7 }}>{item.Table_Item.genreLib}</h6>
                                  {item.Table_Item.isExclusif == 1 && <div style={{width: '100%', maxWidth: nWidthItem, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 0, borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingBottom: 10}}><img src={exclusif} style={{height: 20, borderRadius: 0, marginTop: 5, marginRight: 5}} alt="Exclusif" /></div>}
                                  {item.Table_Item.isNewEpisode == 1 && <div style={{height: 25, background: "rgba(185,185,185,0.7)", position: "absolute", top: 0, left: 0, display: "flex", alignItems: "center", borderTopLeftRadius: 12, borderBottomRightRadius: 12}}><h6 style={{margin: "0 10px", color: "#FFF"}}>Nouvel épisode</h6></div>}
                                  {item.endTime / item.totalTime < 1 && <div style={{ position: "absolute", width: "100%", height: "100%", borderRadius: 12, overflow: "hidden", display: "flex", alignItems: "end" }}><div style={{ width: "100%", height: 5, backgroundColor: "rgba(0, 0, 0, 0.5)", overflow: "hidden" }}><div style={{ width: ( item.endTime / item.totalTime * 100 ) + "%", borderBottomLeftRadius: "12px", backgroundColor: "rgba(255, 104, 55, 0.7)", height: "100%" }}></div></div></div>}
                                </div>

                              </GridListTile>

                            </Link> : <Link style={{ margin: 8 }} to={{
                              pathname: "/player",
                              state: item.Table_Item
                            }}>

                              <GridListTile class="item" key={item.Table_Item.mini_title_path} style={{ width: nWidthItem, height: nHeightItem }}>

                                <div style={{ display: "flex" }}>
                                  <img src={encodeURI(item.Table_Item.mini_title_path+"?tr=w-" + nWidthItem + ",h-" + nHeightItem + ",dpr-" + window.devicePixelRatio.toString())} width="100%" style={{ maxWidth: nWidthItem, maxHeight: nHeightItem, borderRadius: 12 }} alt={item.Table_Item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,"100%",null,item.Table_Item.title))}} />
                                  <h6 style={{ color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: -7 }}>{item.Table_Item.genreLib}</h6>
                                  {item.Table_Item.isExclusif == 1 && <div style={{width: '100%', maxWidth: nWidthItem, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 0, borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingBottom: 10}}><img src={exclusif} style={{height: 20, borderRadius: 0, marginTop: 5, marginRight: 5}} alt="Exclusif" /></div>}
                                  {item.Table_Item.isNewEpisode == 1 && <div style={{height: 25, background: "rgba(185,185,185,0.7)", position: "absolute", top: 0, left: 0, display: "flex", alignItems: "center", borderTopLeftRadius: 12, borderBottomRightRadius: 12}}><h6 style={{margin: "0 10px", color: "#FFF"}}>Nouvel épisode</h6></div>}
                                  {item.endTime / item.totalTime < 1 && <div style={{ position: "absolute", width: "100%", height: "100%", borderRadius: 12, overflow: "hidden", display: "flex", alignItems: "end" }}><div style={{ width: "100%", height: 5, backgroundColor: "rgba(0, 0, 0, 0.5)", overflow: "hidden" }}><div style={{ width: ( item.endTime / item.totalTime * 100 ) + "%", borderBottomLeftRadius: "12px", backgroundColor: "rgba(255, 104, 55, 0.7)", height: "100%" }}></div></div></div>}
                                </div>

                              </GridListTile>

                            </Link>
                          ))}
                        </GridList>
                      )
                    }
                  </div>
                  </GridItem>}

                  {listsFilmG.length > 0 && <GridItem xs={12} style={{ marginTop: 15 }}>


                    {listsFilmG.length > 0 &&
                      <h3 className={classesList.title}>Ma liste</h3>
                    }
                    <div className={classesList.root} style={{ justifyContent: 'flex-start', margin: "0 -15px 0 -32px" }}>
                      {listsFilmG.length >= getItemColumns() ?
                       <Carousel
                       show={getItemColumns()}
                     >
                       {listsFilmG.map((item) => (
                         <GridListTile style={{ padding: 8 }} className="item">
                           <Link style={{ margin: 8 }} to={{
                             pathname: "/detail/" + item.Id
                           }} >
                             <div style={{ display: "flex" }}>
                                <img src={encodeURI(item.mini_title_path+"?tr=w-" + nWidthItem + ",h-" + nHeightItem + ",dpr-" + window.devicePixelRatio.toString())} width="100%" style={{ maxWidth: nWidthItem, maxHeight: nHeightItem }} alt={item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,"100%",null,item.title))}} />
                                <h6 style={{ color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: 16 }}>{item.genreLib}</h6>
                                {item.isExclusif == 1 && (item.isFree == 1 || currentUser.statut == 20) && <div style={{width: '100%', maxWidth: nWidthItem, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 24, borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingBottom: 10}}><img src={exclusif} style={{height: 20, borderRadius: 0, marginTop: 5, marginRight: 5}} alt="Exclusif" /></div>}
                                {item.isNewEpisode == 1 && <div style={{height: 25, background: "rgba(185,185,185,0.7)", position: "absolute", top: 24, left: 0, display: "flex", alignItems: "center", borderTopLeftRadius: 12, borderBottomRightRadius: 12}}><h6 style={{margin: "0 10px", color: "#FFF"}}>Nouvel épisode</h6></div>}
                                {item.isFree != 1 && currentUser.statut != 20 && <div style={{color: "#ff6833", position: "absolute", top: 40, right: 10, display: "flex", alignItems: "center"}}><i class="fa-solid fa-lock fa-xl"></i></div>}
                             </div>
                           </Link>
                         </GridListTile>
                       ))}

                     </Carousel> : (
                          <GridList className={classesList.gridList} style={{ paddingLeft: 5, overflow: "hidden" }}>
                            {listsFilmG.map((item) => (
                              <Link style={{ margin: 8 }} to={{
                                pathname: "/detail/" + item.Id
                              }}>

                                <GridListTile class="item" key={item.mini_title_path} style={{ width: nWidthItem, height: nHeightItem }}>

                                  <div style={{ display: "flex" }}>
                                    <img src={encodeURI(item.mini_title_path+"?tr=w-" + nWidthItem + ",h-" + nHeightItem + ",dpr-" + window.devicePixelRatio.toString())} width="100%" style={{ maxWidth: nWidthItem, maxHeight: nHeightItem, borderRadius: 12 }} alt={item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,"100%",null,item.title))}} />
                                    <h6 style={{ color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: -7 }}>{item.genreLib}</h6>
                                    {item.isExclusif == 1 && (item.isFree == 1 || currentUser.statut == 20) && <div style={{width: '100%', maxWidth: nWidthItem, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 0, borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingBottom: 10}}><img src={exclusif} style={{height: 20, borderRadius: 0, marginTop: 5, marginRight: 5}} alt="Exclusif" /></div>}
                                    {item.isNewEpisode == 1 && <div style={{height: 25, background: "rgba(185,185,185,0.7)", position: "absolute", top: 0, left: 0, display: "flex", alignItems: "center", borderTopLeftRadius: 12, borderBottomRightRadius: 12}}><h6 style={{margin: "0 10px", color: "#FFF"}}>Nouvel épisode</h6></div>}
                                    {item.isFree != 1 && currentUser.statut != 20 && <div style={{color: "#ff6833", position: "absolute", top: 40, right: 10, display: "flex", alignItems: "center"}}><i class="fa-solid fa-lock fa-xl"></i></div>}
                                  </div>

                                </GridListTile>

                              </Link>
                            ))}
                          </GridList>
                        )
                      }
                    </div>
                  </GridItem>}
                  {favoritesFilmG.length > 0 && <GridItem xs={12} style={{ marginTop: 15 }}>


                    {favoritesFilmG.length == 1 &&
                      <h3 className={classesList.title}>Le contenu que j'aime</h3>
                    }

                    {favoritesFilmG.length > 1 &&
                      <h3 className={classesList.title}>Les contenus que j'aime</h3>
                    }
                    <div className={classesList.root} style={{ justifyContent: 'flex-start', margin: "0 -15px 0 -32px" }}>
                      {favoritesFilmG.length >= getItemColumns() ?

                        <Carousel
                          show={getItemColumns()}
                        >
                          {favoritesFilmG.map((item) => (
                            <GridListTile style={{ padding: 8 }} className="item">
                              <Link style={{ margin: 8 }} to={{
                                pathname: "/detail/" + item.Id
                              }} >
                                <div style={{ display: "flex" }}>
                                  <img src={encodeURI(item.mini_title_path+"?tr=w-" + nWidthItem + ",h-" + nHeightItem + ",dpr-" + window.devicePixelRatio.toString())} width="100%" style={{ maxWidth: nWidthItem, maxHeight: nHeightItem }} alt={item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,"100%",null,item.title))}} />
                                  <h6 style={{ color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: 16 }}>{item.genreLib}</h6>
                                  {item.isExclusif == 1 && (item.isFree == 1 || currentUser.statut == 20) && <div style={{width: '100%', maxWidth: nWidthItem, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 24, borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingBottom: 10}}><img src={exclusif} style={{height: 20, borderRadius: 0, marginTop: 5, marginRight: 5}} alt="Exclusif" /></div>}
                                  {item.isNewEpisode == 1 && <div style={{height: 25, background: "rgba(185,185,185,0.7)", position: "absolute", top: 24, left: 0, display: "flex", alignItems: "center", borderTopLeftRadius: 12, borderBottomRightRadius: 12}}><h6 style={{margin: "0 10px", color: "#FFF"}}>Nouvel épisode</h6></div>}
                                  {item.isFree != 1 && currentUser.statut != 20 && <div style={{color: "#ff6833", position: "absolute", top: 40, right: 10, display: "flex", alignItems: "center"}}><i class="fa-solid fa-lock fa-xl"></i></div>}
                                </div>
                              </Link>
                            </GridListTile>
                          ))}

                        </Carousel> : (
                          <GridList className={classesList.gridList} style={{ paddingLeft: 5, overflow: "hidden" }}>
                            {favoritesFilmG.map((item) => (
                              <Link style={{ margin: 8 }} to={{
                                pathname: "/detail/" + item.Id
                              }}>

                                <GridListTile class="item" key={item.mini_title_path} style={{ width: nWidthItem, height: nHeightItem }}>

                                  <div style={{ display: "flex" }}>
                                    <img src={encodeURI(item.mini_title_path+"?tr=w-" + nWidthItem + ",h-" + nHeightItem + ",dpr-" + window.devicePixelRatio.toString())} width="100%" style={{ maxWidth: nWidthItem, maxHeight: nHeightItem, borderRadius: 12 }} alt={item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,"100%",null,item.title))}} />
                                    <h6 style={{ color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: -7 }}>{item.genreLib}</h6>
                                    {item.isExclusif == 1 && (item.isFree == 1 || currentUser.statut == 20) && <div style={{width: '100%', maxWidth: nWidthItem, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 0, borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingBottom: 10}}><img src={exclusif} style={{height: 20, borderRadius: 0, marginTop: 5, marginRight: 5}} alt="Exclusif" /></div>}
                                    {item.isNewEpisode == 1 && <div style={{height: 25, background: "rgba(185,185,185,0.7)", position: "absolute", top: 0, left: 0, display: "flex", alignItems: "center", borderTopLeftRadius: 12, borderBottomRightRadius: 12}}><h6 style={{margin: "0 10px", color: "#FFF"}}>Nouvel épisode</h6></div>}
                                    {item.isFree != 1 && currentUser.statut != 20 && <div style={{color: "#ff6833", position: "absolute", top: 40, right: 10, display: "flex", alignItems: "center"}}><i class="fa-solid fa-lock fa-xl"></i></div>}
                                  </div>

                                </GridListTile>

                              </Link>
                            ))}
                          </GridList>
                        )
                      }
                    </div>
                  </GridItem>}

                  {listsPeopleG.length > 0 && <GridItem xs={12} style={{ marginTop: 15 }}>
                    {listsPeopleG.length == 1 &&
                      <h3 className={classesList.title}>Ma personne favorite</h3>
                    }

                    {listsPeopleG.length > 1 &&
                      <h3 className={classesList.title}>Mes personnes favorites</h3>
                    }
                    <div className={classesList.root} style={{ justifyContent: 'flex-start', margin: "0 -15px 0 -32px" }}>
                      {listsPeopleG.length >= getPeopleColumns() ?
                        <Carousel
                          show={getPeopleColumns()}
                        >
                          {listsPeopleG.map((people) => (
                            <div>
                              <div style={{ padding: 8 }} class="">
                                <Link style={{ margin: 8, textDecoration: "none" }} to={{
                                  pathname: "/people",
                                  state: people

                                }}>
                                  <GridListTile class="item" key={people.photo_path} style={{ width: 200, height: 220, marginTop: 40 }} >
                                    <img src={encodeURI(people.photo_path+"?tr=w-" + nWidthPeople + ",h-" + nWidthPeople + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nWidthPeople} style={{ height: "auto", borderRadius: "50%" }} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                    <h6 style={{ color: '#FFF', marginTop: 15, textAlign: 'center' }}>{people.name}</h6>
                                  </GridListTile>
                                </Link>
                              </div>
                            </div>
                          ))}

                        </Carousel> : (
                          <GridList className={classesList.gridList} style={{ overflowY: "hidden", overflowX: "hidden", paddingRight: 5 }}>
                            {listsPeopleG.map((people) => (
                              <Link to={{
                                pathname: "/people",
                                state: people
                              }} style={{ textDecoration: 'none' }}>

                                <GridListTile class="item" key={people.photo_path} style={{ width: 200, height: 220, marginTop: 40 }} >
                                  <img src={encodeURI(people.photo_path+"?tr=w-" + nWidthPeople + ",h-" + nWidthPeople + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nWidthPeople} style={{ height: "auto", borderRadius: "50%" }} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                  <h6 style={{ color: '#FFF', marginTop: 15, textAlign: 'center' }}>{people.name}</h6>
                                </GridListTile>

                              </Link>
                            ))}
                          </GridList>
                        )
                      }
                    </div>
                  </GridItem>}
                  {listsProdG.length > 0 && <GridItem xs={12} style={{ marginTop: 15, marginBottom: 40 }}>
                    {listsProdG.length == 1 &&
                      <h3 className={classesList.title}>Mon producteur favori</h3>
                    }

                    {listsProdG.length > 1 &&
                      <h3 className={classesList.title}>Mes producteurs favoris</h3>
                    }
                    <div className={classesList.root} style={{ justifyContent: 'flex-start', margin: "0 -15px 0 -32px" }}>
                      {listsProdG.length >= getPeopleColumns() ?
                        <Carousel
                          show={getPeopleColumns()}
                        >
                          {listsProdG.map((productor) => (
                            <div>
                              <div style={{ padding: 8 }} class="">
                                <Link style={{ margin: 8, textDecoration: "none" }} to={{
                                  pathname: "/productor",
                                  state: productor

                                }}>
                                  <GridListTile class="item" key={productor.photo_path} style={{ width: 200, height: 220, marginTop: 40 }} >
                                    <img src={encodeURI(productor.photo_path+"?tr=w-" + nWidthPeople + ",h-" + nWidthPeople + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nWidthPeople} style={{ height: "auto", borderRadius: "50%" }} alt={productor.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                    <h6 style={{ color: '#FFF', marginTop: 15, textAlign: 'center' }}>{productor.name}</h6>
                                  </GridListTile>
                                </Link>
                              </div>
                            </div>
                          ))}

                        </Carousel> : (
                          <GridList className={classesList.gridList} style={{ overflowY: "hidden", overflowX: "hidden", paddingRight: 5, paddingBottom: 20 }}>
                            {listsProdG.map((productor) => (
                              <Link to={{
                                pathname: "/productor",
                                state: productor
                              }} style={{ textDecoration: 'none' }}>

                                <GridListTile class="item" key={productor.photo_path} style={{ width: 200, height: 220, marginTop: 40 }} >
                                  <img src={encodeURI(productor.photo_path+"?tr=w-" + nWidthPeople + ",h-" + nWidthPeople + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nWidthPeople} style={{ height: "auto", borderRadius: "50%" }} alt={productor.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                  <h6 style={{ color: '#FFF', marginTop: 15, textAlign: 'center' }}>{productor.name}</h6>
                                </GridListTile>

                              </Link>
                            ))}
                          </GridList>
                        )
                      }
                    </div>
                  </GridItem>}
                  {listReprendreG.length == 0 && listsFilmG.length == 0 && favoritesFilmG.length == 0 && listsProdG.length == 0 && listsPeopleG.length == 0 && <GridItem xs={12} style={{ marginTop: 15, marginBottom: 40 }}>
                      <h3 className={classesList.title}>Suggestions de films sur SOFA</h3>
                      <div className={classesList.root} style={{ justifyContent: 'flex-start', margin: "0 -15px 0 -32px" }}>
                        <Carousel show={getItemColumns()}>
                          {sugg.map(item => <GridListTile style={{ padding: 8 }} className="item">
                           <Link style={{ margin: 8 }} to={{
                             pathname: "/detail/" + item.Id
                           }} >
                             <div style={{ display: "flex" }}>
                                <img src={encodeURI(item.mini_title_path+"?tr=w-" + nWidthItem + ",h-" + nHeightItem + ",dpr-" + window.devicePixelRatio.toString())} width="100%" style={{ maxWidth: nWidthItem, maxHeight: nHeightItem }} alt={item.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,"100%",null,item.title))}} />
                                <h6 style={{ color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: 16 }}>{item.genreLib}</h6>
                                {item.isExclusif == 1 && (item.isFree == 1 || currentUser.statut == 20) && <div style={{width: '100%', maxWidth: nWidthItem, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 24, borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingBottom: 10}}><img src={exclusif} style={{height: 20, borderRadius: 0, marginTop: 5, marginRight: 5}} alt="Exclusif" /></div>}
                                {item.isNewEpisode == 1 && <div style={{height: 25, background: "rgba(185,185,185,0.7)", position: "absolute", top: 24, left: 0, display: "flex", alignItems: "center", borderTopLeftRadius: 12, borderBottomRightRadius: 12}}><h6 style={{margin: "0 10px", color: "#FFF"}}>Nouvel épisode</h6></div>}
                                {item.isFree != 1 && currentUser.statut != 20 && <div style={{color: "#ff6833", position: "absolute", top: 40, right: 10, display: "flex", alignItems: "center"}}><i class="fa-solid fa-lock fa-xl"></i></div>}
                             </div>
                           </Link>
                         </GridListTile>)}
                        </Carousel>
                      </div>
                  </GridItem>}
                </GridContainer>
              </div>
            </div>




          </div>
        )}
        <Footer />
      </div>
    );
  }
}
