/* eslint-disable no-unused-expressions */
import React, {useState,useEffect,useRef} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Link,
  useParams,
  useHistory
} from "react-router-dom";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import ParallaxBlur from "components/Parallax/ParallaxBlur.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import { Redirect } from "react-router-dom";
import AuthService from "../../services/auth.service.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import GridList from '@material-ui/core/GridList';
import useWindowSize from 'components/useWindowSize';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
//import { ReactVideo } from '../../components/Video/Video.js';
import  ReactHlsPlayer  from '../../components/Video/ReactHlsPlayer.js';
import { Popover } from "@material-ui/core";
import {
  isMobile,
  isIOS, // ------ utiliser pour choisir le lien via Apple Store 
  isAndroid // ------ utiliser pour choisir le lien via Google Play
} from "react-device-detect";
import { getCategoryAll } from '../../API/CategoryAPI.js'
import { getItemById } from '../../API/ItemByIdAPI';
import { getItemSuggestions } from '../../API/ItemsSuggestionsAPI';
import { getUserItemById } from '../../API/GetUserItemAPI';
import { addUserItem } from '../../API/AddUserItemAPI';
import { upUserItem } from '../../API/UpUserItemAPI';
import exclusif from '../../assets/img/exclusif.png';
import Carousel from "components/CarouselMulti/Carousel.js";
import { ReactVideo } from "components/Video/Video.js";
import noImageAVIF from "assets/img/Fond_lampadaire.avif";
import noImage from "assets/img/Fond_lampadaire.jpg";


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function PlayerPage(props) {
  const classes = useStyles();
  const history = useHistory();
  const currentUser = AuthService.getCurrentUser();
  const [item, setItem] = useState(props.location.state);
  const playerRef = React.useRef();
  const { width, height } = useWindowSize();

  if (currentUser == null) {
    history.push('/login');
  }

  React.useEffect(() => {
    if (currentUser != null) {
      function fireOnVideoStart() {
      }

      playerRef.current.addEventListener('play', fireOnVideoStart);

      return playerRef.current.removeEventListener('play', fireOnVideoStart);
    }
  }, []);

  function fireOnVideoEnd() {
    if (playerRef.current.ended) {
      if (item.item) {
        let nextNumberLink = null;
        let nextSeasonLink = null;
        let newEpisodeNumberN = null;
        let newEpisodeSeasonN = null;
        let newEpisodeNumberS = null;
        let newEpisodeSeasonS = null;
        let newIdN = null;
        let newIdS = null;
        let minN = null;
        let minS = null;
        item.item.Table_Seasons.forEach(element => {
          if (element.number == numberRef.current + 1 && element.season == seasonRef.current) {
            newEpisodeNumberN = element.number;
            newEpisodeSeasonN = element.season;
            minN = element.image_path;
            newIdN = element.id;
            if (isMobile) {
              nextNumberLink = element.episode_path;
            } else {
              nextNumberLink = element.episode_path;
            }
          }
          if (element.number == 1 && element.season == seasonRef.current + 1) {
            newEpisodeNumberS = element.number;
            newEpisodeSeasonS = element.season;
            minS = element.image_path;
            newIdS = element.id;
            if (isMobile) {
              nextSeasonLink = element.episode_path;
            } else {
              nextSeasonLink = element.episode_path;
            }
          }
        })
        if (!nextNumberLink && !nextSeasonLink) {
          if (item.item && (currentUser.statut == 20 || item.item.idType != 1 || item.item.isFree == 1)) {
            item.item = null;
          }
          setIsSugg(true);
          if (suggRef.current.length == 0) {
            setTimeout(() => {
              history.goBack();
            }, 2000)
          } else if (!item.item) {
            let itemS = suggRef.current[0];
            setCountDown(30);
            setTimerNext(setTimeout(() => {
              setItem(itemS);
              setIdItem(itemS.Id);
              if (itemS.idType != 1) {
                item.idType = 2;
                if (isMobile) {
                  setLink(itemS.movie_path);
                } else {
                  setLink(itemS.movie_path);
                }
                setMin(itemS.mini_path);
                getItemSuggestions(currentUser.accessToken, itemS.Id).then(
                  (response) => {
                    let suggestion = [];
                    response.data.map(function (item) {
                      if (suggestion.length < 5) {
                        suggestion.push(item.Table_Item);
                      }
                    })
                    setSugg(suggestion);
                  }
                )
                setIsSugg(false);
                setTimeout(() => {
                  playerRef.current.addEventListener('ended', fireOnVideoEnd);
                })
              } else {
                item.idType = 1;
                getItemById(currentUser.accessToken, itemS.Id, currentUser.id).then(
                  (response) => {
                    const season = response.data[0].Table_Seasons;
                    item.Table_Seasons = season;
                    season.forEach(element => {
                      if (element.number == 1 && element.season == 1) {
                        setNumber(1);
                        setSeason(1);
                        setIdEpisode(element.id);
                        if (isMobile) {
                          setLink(element.episode_path);
                        } else {
                          setLink(element.episode_path);
                        }
                        setMin(itemS.mini_path);
                        if (itemS.logo_path) {
                          setLogo(itemS.logo_path);
                        }
                      }                        
                    })
                    getItemSuggestions(currentUser.accessToken, itemS.Id).then(
                      (response) => {
                        let suggestion = [];
                        response.data.map(function (item) {
                          if (suggestion.length < 5) {
                            suggestion.push(item.Table_Item);
                          }
                        })
                        setSugg(suggestion);
                      }
                    )
                    setIsSugg(false);
                    setTimeout(() => {
                      playerRef.current.addEventListener('ended', fireOnVideoEnd);
                    })
                  }
                )
              }
              let favController = false;
              userItems.forEach(element => {
                if (element.IdItem == itemS.Id && element.idMyBanner == 3) {
                  setFavorite(true);
                  favController = true;
                }
              })
              if (!favController) {
                setFavorite(false);
              }
            }, 30000))
          }
        } else if (nextNumberLink) {
          setNextEp(true);
          setMinEp(minN);
          setNextEpN(newEpisodeNumberN);
          setNextEpS(newEpisodeSeasonN);
          setCountDown(5);
          setTimerNext(setTimeout(() => {
            setIdEpisode(newIdN);
            setNumber(newEpisodeNumberN);
            setSeason(newEpisodeSeasonN);
            setLink(nextNumberLink);
            setNextEp(false);
            setTimeout(() => {
              playerRef.current.addEventListener('ended', fireOnVideoEnd);
            })
          }, 5000))
        } else if (nextSeasonLink) {
          setNextEp(true);
          setMinEp(minS);
          setNextEpN(newEpisodeNumberS);
          setNextEpS(newEpisodeSeasonS);
          setCountDown(5);
          setTimerNext(setTimeout(() => {
            setIdEpisode(newIdS);
            setNumber(newEpisodeNumberS);
            setSeason(newEpisodeSeasonS);
            setLink(nextSeasonLink);
            setNextEp(false);
            setTimeout(() => {
              playerRef.current.addEventListener('ended', fireOnVideoEnd);
            })
          }, 5000))
        }
      } else if (item.idType == 1) {
        let nextNumberLink = null;
        let nextSeasonLink = null;
        let newEpisodeNumberN = null;
        let newEpisodeSeasonN = null;
        let newEpisodeNumberS = null;
        let newEpisodeSeasonS = null;
        let newIdN = null;
        let newIdS = null;
        let minN = null;
        let minS = null;
        item.Table_Seasons.forEach(element => {
          if (element.number == numberRef.current + 1 && element.season == seasonRef.current) {
            newEpisodeNumberN = element.number;
            newEpisodeSeasonN = element.season;
            minN = element.image_path;
            newIdN = element.id;
            if (isMobile) {
              nextNumberLink = element.episode_path;
            } else {
              nextNumberLink = element.episode_path;
            }
          }
          if (element.number == 1 && element.season == seasonRef.current + 1) {
            newEpisodeNumberS = element.number;
            newEpisodeSeasonS = element.season;
            minS = element.image_path;
            newIdS = element.id;
            if (isMobile) {
              nextSeasonLink = element.episode_path;
            } else {
              nextSeasonLink = element.episode_path;
            }
          }
        })
        if (!nextNumberLink && !nextSeasonLink) {
          if (item.item && (currentUser.statut == 20 || item.item.idType != 1 || item.item.isFree == 1)) {
            item.item = null;
          }
          setIsSugg(true);
          if (suggRef.current.length == 0) {
            setTimeout(() => {
              history.goBack();
            }, 2000)
          } else if (!item.item) {
            let itemS = suggRef.current[0];
            setCountDown(30);
            setTimerNext(setTimeout(() => {
              setItem(itemS);
              setIdItem(itemS.Id);
              if (itemS.idType != 1) {
                item.idType = 2;
                if (isMobile) {
                  setLink(itemS.movie_path);
                } else {
                  setLink(itemS.movie_path);
                }
                setMin(itemS.mini_path);
                getItemSuggestions(currentUser.accessToken, itemS.Id).then(
                  (response) => {
                    let suggestion = [];
                    response.data.map(function (item) {
                      if (suggestion.length < 5) {
                        suggestion.push(item.Table_Item);
                      }
                    })
                    setSugg(suggestion);
                  }
                )
                setIsSugg(false);
                setTimeout(() => {
                  playerRef.current.addEventListener('ended', fireOnVideoEnd);
                })
              } else {
                item.idType = 1
                getItemById(currentUser.accessToken, itemS.Id, currentUser.id).then(
                  (response) => {
                    const season = response.data[0].Table_Seasons;
                    item.Table_Seasons = season;
                    season.forEach(element => {
                      if (element.number == 1 && element.season == 1) {
                        setNumber(1);
                        setSeason(1);
                        setIdEpisode(element.id);
                        if (isMobile) {
                          setLink(element.episode_path);
                        } else {
                          setLink(element.episode_path);
                        }
                        setMin(itemS.mini_path);
                        if (itemS.logo_path) {
                          setLogo(itemS.logo_path);
                        }
                      }                        
                    })
                    getItemSuggestions(currentUser.accessToken, itemS.Id).then(
                      (response) => {
                        let suggestion = [];
                        response.data.map(function (item) {
                          if (suggestion.length < 5) {
                            suggestion.push(item.Table_Item);
                          }
                        })
                        setSugg(suggestion);
                      }
                    )
                    setIsSugg(false);
                    setTimeout(() => {
                      playerRef.current.addEventListener('ended', fireOnVideoEnd);
                    })
                  }
                )
              }
              let favController = false;
              userItems.forEach(element => {
                if (element.IdItem == itemS.Id && element.idMyBanner == 3) {
                  setFavorite(true);
                  favController = true;
                }
              })
              if (!favController) {
                setFavorite(false);
              }
            }, 30000))
          }
        } else if (nextNumberLink) {
          setNextEp(true);
          setMinEp(minN);
          setNextEpN(newEpisodeNumberN);
          setNextEpS(newEpisodeSeasonN);
          setCountDown(5);
          setTimerNext(setTimeout(() => {
            setIdEpisode(newIdN);
            setNumber(newEpisodeNumberN);
            setSeason(newEpisodeSeasonN);
            setLink(nextNumberLink);
            setNextEp(false);
            setTimeout(() => {
              playerRef.current.addEventListener('ended', fireOnVideoEnd);
            })
          }, 5000))
        } else if (nextSeasonLink) {
          setNextEp(true);
          setMinEp(minS);
          setNextEpN(newEpisodeNumberS);
          setNextEpS(newEpisodeSeasonS);
          setCountDown(5);
          setTimerNext(setTimeout(() => {
            setIdEpisode(newIdS);
            setNumber(newEpisodeNumberS);
            setSeason(newEpisodeSeasonS);
            setLink(nextSeasonLink);
            setNextEp(false);
            setTimeout(() => {
              playerRef.current.addEventListener('ended', fireOnVideoEnd);
            })
          }, 5000))
        }
      } else {
        if (item.item && (currentUser.statut == 20 || item.item.idType != 1 || item.item.isFree == 1)) {
          item.item = null;
        }
        setIsSugg(true);
        if (suggRef.current.length == 0) {
          setTimeout(() => {
            history.goBack();
          }, 2000)
        } else if (!item.item) {
          let itemS = suggRef.current[0];
          setCountDown(30);
          setTimerNext(setTimeout(() => {
            setItem(itemS);
            setIdItem(itemS.Id);
            if (itemS.idType != 1) {
              item.idType = 2;
              if (isMobile) {
                setLink(itemS.movie_path);
              } else {
                setLink(itemS.movie_path);
              }
              setMin(itemS.mini_path);
              getItemSuggestions(currentUser.accessToken, itemS.Id).then(
                (response) => {
                  let suggestion = [];
                  response.data.map(function (item) {
                    if (suggestion.length < 5) {
                      suggestion.push(item.Table_Item);
                    }
                  })
                  setSugg(suggestion);
                }
              )
              setIsSugg(false);
              setTimeout(() => {
                playerRef.current.addEventListener('ended', fireOnVideoEnd);
              })
            } else {
              item.idType = 1
              getItemById(currentUser.accessToken, itemS.Id, currentUser.id).then(
                (response) => {
                  const season = response.data[0].Table_Seasons;
                  item.Table_Seasons = season;
                  season.forEach(element => {
                    if (element.number == 1 && element.season == 1) {
                      setNumber(1);
                      setSeason(1);
                      setIdEpisode(element.id);
                      if (isMobile) {
                        setLink(element.episode_path);
                      } else {
                        setLink(element.episode_path);
                      }
                      setMin(itemS.mini_path);
                      if (itemS.logo_path) {
                        setLogo(itemS.logo_path);
                      }
                    }                        
                  })
                  getItemSuggestions(currentUser.accessToken, itemS.Id).then(
                    (response) => {
                      let suggestion = [];
                      response.data.map(function (item) {
                        if (suggestion.length < 5) {
                          suggestion.push(item.Table_Item);
                        }
                      })
                      setSugg(suggestion);
                    }
                  )
                  setIsSugg(false);
                  setTimeout(() => {
                    playerRef.current.addEventListener('ended', fireOnVideoEnd);
                  })
                }
              )
            }
            let favController = false;
            userItems.forEach(element => {
              if (element.IdItem == itemS.Id && element.idMyBanner == 3) {
                setFavorite(true);
                favController = true;
              }
            })
            if (!favController) {
              setFavorite(false);
            }
          }, 30000))
        }
      }
    }
  }

  React.useEffect(() => {
    if (currentUser != null) {
      playerRef.current.addEventListener('ended', fireOnVideoEnd);
    }
  }, []);

  const [User, setUser] = useState({});
  const [link, setLink] = useState("");
  const [idItem, setIdItem] = useState(0);
  const [idEpisode, setIdEpisode] = useState(0);
  const [Poster, setPoster] = useState("");
  //const [data, setData] = useState([]);
  const [sugg, setSugg] = useState([]);
  const [isSugg, setIsSugg] = useState(false);
  const [nextEp, setNextEp] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const [number, setNumber] = useState(0);
  const [season, setSeason] = useState(0);
  const [minEp, setMinEp] = useState("");
  const [nextEpN, setNextEpN] = useState(0);
  const [nextEpS, setNextEpS] = useState(0);
  const [min, setMin] = useState("");
  const [logo, setLogo] = useState("");
  const [timerNext, setTimerNext] = useState();
  const [userItems, setUserItems] = useState([]);
  const [isFavorite, setFavorite] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const numberRef = useRef();
  const seasonRef = useRef();
  const suggRef = useRef();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const share = open ? 'simple-popover' : undefined;
  const copied = open2 ? 'simple-popover' : undefined;
 

  const [isLoading, setLoading] = useState(false);

  numberRef.current = number;
  seasonRef.current = season;
  suggRef.current = sugg;

  useEffect(() => {
    if (countDown > 0) {
      const timer = setTimeout(() => {
        setCountDown(countDown-1);
      }, 1000);
      // Clear timeout if the component is unmounted
      return () => clearTimeout(timer);
    }
  }, [countDown]);

  useEffect(() => {
    return () => clearTimeout(timerNext);
  }, [timerNext])

  const loadLinks = async () => {
    if (item.item) {
      setIdItem(item.item.Id);
      setNumber(item.episode.number);
      setSeason(item.episode.season);
      setIdEpisode(item.episode.id);
      setMin(item.item.mini_path);
      if (item.episode.start) {
        setStartTime(item.episode.start);
      } else if (item.item.start) {
        setStartTime(item.item.start);
      }
      if (item.item.logo_path) {
        setLogo(item.item.logo_path);
      }
      if (isMobile) {
        setLink(item.episode.episode_path);
      } else {
        setLink(item.episode.episode_path);
      }
      if (item.item.Table_Suggestions) {
        setSugg(item.item.Table_Suggestions);
      } else {
        getItemSuggestions(currentUser.accessToken, item.item.Id).then(
          (response) => {
            let suggestion = [];
            response.data.map(function (item) {
              if (suggestion.length < 5) {
                suggestion.push(item.Table_Item);
              }
            })
            setSugg(suggestion);
          }
        )
      }
    } else {
      setMin(item.mini_path);
      if (item.Table_Suggestions) {
        setSugg(item.Table_Suggestions);
      } else {
        getItemSuggestions(currentUser.accessToken, item.Id).then(
          (response) => {
            let suggestion = [];
            response.data.map(function (item) {
              if (suggestion.length < 5) {
                suggestion.push(item.Table_Item);
              }
            })
            setSugg(suggestion);
          }
        )
      }
      if (item.idType == 1) {
        setIdItem(item.Id);
        if (item.logo_path) {
          setLogo(item.logo_path);
        }
        if (!item.Table_Seasons || !item.Table_Seasons[0].episode_path) {
          const respItemC = await getItemById(currentUser.accessToken, item.Id, currentUser.id);
          const loadItem = respItemC.data;
          let season = loadItem[0].Table_Seasons;
          item.Table_Seasons = season;
          season.forEach(element => {
            if (element.number == 1 && element.season == 1) {
              setNumber(1);
              setSeason(1);
              setIdEpisode(element.id);
              if (isMobile) {
                setLink(element.episode_path);
              } else {
                setLink(element.episode_path);
              }
            }
          })
        } else {
          let season = item.Table_Seasons;
          let listStarted = [];
          season.forEach(element => {
            if (element.start || element.start === 0) {
              listStarted.push(element);
            }
            // if (element.number == 1 && element.season == 1) {
            //   setNumber(1);
            //   setSeason(1);
            //   setIdEpisode(element.id);
            //   if (isMobile) {
            //     setLink(element.episode_path_web);
            //   } else {
            //     setLink(element.episode_path);
            //   }
            // }
          })
          let selectedEpisode;
          if (listStarted.length > 0) {
            listStarted.forEach(element => {
              if (!selectedEpisode) {
                selectedEpisode = element;
              } else if (element.season > selectedEpisode.season) {
                selectedEpisode = element;
              } else if (element.season === selectedEpisode.season && element.number > selectedEpisode.number) {
                selectedEpisode = element;
              }
            })
            if (selectedEpisode.start == 0) {
              let index = season.findIndex(x => x.number == selectedEpisode.number + 1 && x.season == selectedEpisode.season);
              if (index != -1) {
                selectedEpisode = season[index];
                selectedEpisode.start = 0;
              } else {
                let index2 = season.findIndex(x => x.number == 1 && x.season == selectedEpisode.season + 1);
                if (index2 != -1) {
                  selectedEpisode = season[index2];
                  selectedEpisode.start = 0;
                } else {
                  selectedEpisode = season.find(x => x.number == 1 && x.season == 1);
                  selectedEpisode.start = 0;
                }
              }
            }
            setStartTime(selectedEpisode.start);
            setNumber(selectedEpisode.number);
            setSeason(selectedEpisode.season);
            setIdEpisode(selectedEpisode.id);
            if (isMobile) {
              setLink(selectedEpisode.episode_path);
            } else {
              setLink(selectedEpisode.episode_path);
            }
          } else {
            selectedEpisode = season.find(x => x.number == 1 && x.season == 1);
            setNumber(selectedEpisode.number);
            setSeason(selectedEpisode.season);
            setIdEpisode(selectedEpisode.id);
            if (isMobile) {
              setLink(selectedEpisode.episode_path);
            } else {
              setLink(selectedEpisode.episode_path);
            }
          }
        }
      } else {
        if (item.start) {
          setStartTime(item.start);
        }
        if (item.movie_path) {
          if (isMobile) {
            if (item.movie_path_web) {
              setLink(item.movie_path);
              setIdItem(item.Id);
            }
          } else {
            if (item.movie_path) {
              setLink(item.movie_path);
              setIdItem(item.Id);
            }
          }
        } else {
          setIdItem(item.Id);
          const respItemC = await getItemById(currentUser.accessToken, item.Id, currentUser.id);
          const loadItem = respItemC.data;
          if (isMobile) {
            if (loadItem[0].movie_path) {
              setLink(loadItem[0].movie_path);
            }
          } else {
            if (loadItem[0].movie_path) {
              setLink(loadItem[0].movie_path);
            }
          }
        }
      }
    }
    
    getUserItemById(currentUser.accessToken, currentUser.id).then(
      (response) => {
        setUserItems(response.data);
        response.data.forEach(element => {
          if (item.item) {
            if (element.IdItem == item.item.Id && element.idMyBanner == 3) {
              setFavorite(true);
            }
          } else {
            if (element.IdItem == item.Id && element.idMyBanner == 3) {
              setFavorite(true);
            }
          }
        })
      }
    )
    
      /* if(isMobile){
      if (item.movie_path_web) {
        setLink(item.movie_path_web);
        setIdItem(item.Id);
      } else if (item.episode_path_web) {
        setLink(item.episode_path_web);
        setIdItem(item.idItem);
        setIdEpisode(item.id);
      }
      }else{
      if (item.movie_path) {
        setLink(item.movie_path);
        setIdItem(item.Id);
      } else if (item.episode_path) {
        setLink(item.episode_path);
        setIdItem(item.idItem);
        setIdEpisode(item.id);
      }
      } */
  }
  //     if (item.mini_path) {
  //       setPoster(item.mini_path);
  //     } else if (item.image_path) {
  //       setPoster(item.image_path);
  //     }
  //   }
  
  useEffect(() => {
  if (currentUser != null) {
    loadLinks();
  }
  }, []);

  const getNumberItems = () => {
  let nColumn = width / 440;
  return Math.trunc(nColumn);
  }

  const addFavorite = () => {
  addUserItem(currentUser.accessToken, currentUser.id, idItem, 0, 0, 3).then(
    (response) => {
      setFavorite(true);
      let UserItem = userItems;
      UserItem.push({ id: response.data.id, idUser: currentUser.id, IdItem: idItem, idMyBanner: 3 });
      setUserItems(UserItem);
    }
  )
  }

  const removeFavorite = () => {
  let idUserItem;
  userItems.forEach(element => {
    if (element.IdItem == idItem && element.idMyBanner == 3) {
      idUserItem = element.id;
    }
  })

  upUserItem(currentUser.accessToken, idUserItem).then(
    (reponse) => {
      setFavorite(false);
      let UserItem = userItems;
      UserItem.forEach((element, index) => {
        if (element.IdItem == idItem && element.idMyBanner == 3) {
          UserItem.splice(index, 1);
        }
      })
      setUserItems(UserItem);
    }
  )
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClick2 = (event) => {
    const text = "https://sofavod.com/itemShare/" + idItem;
    navigator.clipboard.writeText(text).then(
      () => {
        setAnchorEl2(event.target);
        setTimeout(() => {
          handleClose2();
        }, 500)
      }
    );
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleClose2 = () => {
    setAnchorEl2(null);
  }

  const defaultImage = (style, width, height, title) => {
    let picture = document.createElement("picture");
    picture.setAttribute("style", "position: relative");
    let source = document.createElement("source");
    source.setAttribute("srcSet", noImageAVIF);
    source.setAttribute("type", "image/avif");
    let img = document.createElement("img");
    img.setAttribute("src", noImage);
    let jsonStyle = {};
    Object.values(JSON.parse(JSON.stringify(style))).forEach(element => {
      jsonStyle[element] = style[element];
    })
    img.setAttribute("style", JSON.stringify(jsonStyle).replace(/","/g, ";").replace(/["'{}]/g, ""));
    img.setAttribute("width", width);
    img.setAttribute("height", height);
    let div = document.createElement("div");
    div.setAttribute("style", "position: absolute; top: 0; width: 100%; height: 100%; padding: 2.5%; display: grid; align-items: center; justify-content: center");
    let text = document.createElement("h3");
    text.setAttribute("style", "color: #FFF; font-size: 20px; font-family: Bubblebody Light; text-align: center");
    text.appendChild(document.createTextNode(title));
    div.appendChild(text);
    picture.appendChild(source);
    picture.appendChild(img);
    picture.appendChild(div);
    return picture;
  }

  const titleFilm = () => {
    let title = document.createElement("h2");
    title.setAttribute('style', 'color: #FFF');
    title.appendChild(document.createTextNode(item.title));
    return title;
  }
   
  const { ...rest } = props;
  if (currentUser == null){
  
    return ""
  }else{
  return (
    
 
    <div style={{ backgroundColor: "#000",}}>
      {nextEp ? <div style={{ height: height, display: "flex", backgroundImage: "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(" + encodeURI(min + "?tr=w-" + width + ",h-" + ((width*1080)/1920)) + ",dpr-" + window.devicePixelRatio.toString() + ")" }}>
        <Header
            color="transparent"
            routes={dashboardRoutes}
            brand="SOFA vod"
          
            fixed
            changeColorOnScroll={{
              height: 100,
              color: "#1B1B1B" 
            }}
            {...rest}
          />
          <div style={{ alignSelf: "center", margin: "auto", display: "flex", flexDirection: "column", marginBottom: 10 }}>
            {logo.length > 0 ? <img src={logo + "?tr=w-200,h-" + (200*9/16).toString() + ",dpr-" + window.devicePixelRatio.toString()} width={200} height={200*9/16} style={{ margin: "0 auto" }} alt={item.title} onError={(e) => {e.target.replaceWith(titleFilm())}} /> : (
              <h2 style={{ color: "#FFF" }}>{item.title}</h2>
            )}
            <img src={minEp + "?tr=w-400,h-" + (400*9/16).toString() + ",dpr-" + window.devicePixelRatio.toString()} width={400} height={400*9/16} style={{ borderRadius: 10 }} onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,400,(400*9/16),""))}} />
            {nextEpS > 1 ? <h3 style={{ color: "#FFF" }}>Épisode {nextEpN} Saison {nextEpS} dans : {countDown}</h3> : (
              <h3 style={{ color: "#FFF" }}>Épisode {nextEpN} dans : {countDown}</h3>
            )}
        </div>
      </div> : item.item && item.item.idType == 1 && currentUser.statut != 20 && item.item.isFree != 1 && isSugg ? <div style={{ height: height, display: "flex", backgroundImage: "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(" + encodeURI(min + "?tr=w-" + width + ",h-" + ((width*1080)/1920)) + ",dpr-" + window.devicePixelRatio.toString() + ")" }}>
        <Header
            color="transparent"
            routes={dashboardRoutes}
            brand="SOFA vod"
          
            fixed
            changeColorOnScroll={{
              height: 100,
              color: "#1B1B1B" 
            }}
            {...rest}
          />
          <div style={{ alignSelf: "center", margin: "auto", display: "flex", flexDirection: "column" }}>
            <h2 style={{ color: "#FFF" }}>Pour regarder la suite de cette série, abonnez-vous.</h2>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a style={{ margin: 8, textDecoration: "none" }} href="https://moncompte.sofavod.com/shop" rel="noopener noreferrer"><Button
                color="sofa"
                size="lg"
                rel="noopener noreferrer"
                >Je m'abonne</Button>
              </a>
            </div>
        </div>
      </div> :
      <div>
       {isSugg ? <div>
        {sugg.length > 0 ? 
        <div style={{ height: "100%", minHeight: height, display: "flex", backgroundImage: "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(" + encodeURI(min + "?tr=w-" + width + ",h-" + ((width*1080)/1920)) + ",dpr-" + window.devicePixelRatio.toString() + ")" }}>
          <Header
            color="transparent"
            routes={dashboardRoutes}
            brand="SOFA vod"
          
            fixed
            changeColorOnScroll={{
              height: 100,
              color: "#1B1B1B" 
            }}
            {...rest}
          />
        <div style={{ alignSelf: "center", margin: "auto", overflow: "hidden" }}>
          {sugg[0].teaser_path ? <div className={classes.container} style={{ display: "flex", justifyContent: "space-between" }}><div style={{ width: "50%", margin: "auto" }}><h3 style={{ color: "#FFF", textAlign: "center" }}>Cette œuvre vous a plu ?</h3>
          <div style={{ display: "flex", justifyContent: "space-around", maxWidth: 600, margin: "50px auto", }}>
            {isFavorite ?
              <div>
                <button onClick={removeFavorite} ><i style={{ color: "#ff600f" }} class="fas fa-heart fa-3x"></i><h3 style={{ marginTop: 5, color: "#FFF" }}>J'aime</h3></button>
              </div>
              : (
              <div>
                <button onClick={addFavorite} ><i style={{ color: "#ff600f" }} class="far fa-heart fa-3x"></i><h3 style={{ marginTop: 5, color: "#FFF" }}>J'aime</h3></button>
              </div>
              )
            }
            <div>
              <button aria-describedby={share} onClick={handleClick} variant="contained"><i style={{ color: "#ff600f" }} class="fas fa-share fa-3x"></i><h3 style={{ marginTop: 5, whiteSpace: "nowrap", color: "#FFF" }}>Je partage</h3></button>
              <Popover
                id={share}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
              >
                <div style={{ width: 300 }}>
                  <p style={{ margin: 10 }}>Lien de partage :</p>
                  <input style={{ width: 280, margin: 10, paddingLeft: 4, paddingRight: 4, paddingTop: 2, paddingBottom: 2 }} type="text" value={"https://sofavod.com/itemShare/" + idItem} autoFocus readOnly></input>
                  <button aria-describedby={copied} variant="contained" className={classes.copyButton} onClick={handleClick2}>Copier</button>
                  <Popover 
                    id={copied}
                    open={open2}
                    anchorEl={anchorEl2}
                    onClose={handleClose2}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left"
                    }}
                  >
                    <p style={{ margin: 10 }}>Lien copié !</p>
                  </Popover>
                </div>
              </Popover>
            </div>
          </div></div><div style={{ maxWidth: 500, overflow: "hidden", borderRadius: 10, height: "fit-content" }}>
            <ReactVideo
              autoPlay={true}
              width='100%'
              //  height="300"
              // poster={item.mini_path}
              controls={true}
              idEpisode={idEpisode}
              idItem={idItem}
              user={currentUser}
              src={sugg[0].teaser_path}
            />
          </div></div>
          : (<div><h3 style={{ color: "#FFF", textAlign: "center" }}>Cette œuvre vous a plu ?</h3>
          <div style={{ display: "flex", justifyContent: "space-around", maxWidth: 600, margin: "50px auto", }}>
            {isFavorite ?
              <div>
                <button onClick={removeFavorite} ><i style={{ color: "#ff600f" }} class="fas fa-heart fa-3x"></i><h3 style={{ marginTop: 5, color: "#FFF" }}>J'aime</h3></button>
              </div>
              : (
              <div>
                <button onClick={addFavorite} ><i style={{ color: "#ff600f" }} class="far fa-heart fa-3x"></i><h3 style={{ marginTop: 5, color: "#FFF" }}>J'aime</h3></button>
              </div>
              )
            }
            <div>
              <button aria-describedby={share} onClick={handleClick} variant="contained"><i style={{ color: "#ff600f" }} class="fas fa-share fa-3x"></i><h3 style={{ marginTop: 5, whiteSpace: "nowrap", color: "#FFF" }}>Je partage</h3></button>
              <Popover
                id={share}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
              >
                <div style={{ width: 300 }}>
                  <p style={{ margin: 10 }}>Lien de partage :</p>
                  <input style={{ width: 280, margin: 10, paddingLeft: 4, paddingRight: 4, paddingTop: 2, paddingBottom: 2 }} type="text" value={"https://sofavod.com/itemShare/" + idItem} autoFocus readOnly></input>
                  <button aria-describedby={copied} variant="contained" className={classes.copyButton} onClick={handleClick2}>Copier</button>
                  <Popover 
                    id={copied}
                    open={open2}
                    anchorEl={anchorEl2}
                    onClose={handleClose2}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left"
                    }}
                  >
                    <p style={{ margin: 10 }}>Lien copié !</p>
                  </Popover>
                </div>
              </Popover>
            </div>
          </div></div>)}
          <h3 style={{ color: "#FFF", marginLeft: 60 }}>Regardez ensuite :</h3>
        <Carousel show={getNumberItems()}>
          {sugg.map((itemS) => (
            <GridListTile class="item" key={itemS.Id} style={{ minHeight: 250, marginTop: 10, width: "fit-content" }}>
              {currentUser.statut == 20 || itemS.isFree == 1 ? <Link style={{ margin: 8, display: "flex", justifyContent: "center" }} to={{
                              pathname: "/player",
                              state: itemS
                            }}
                            onClick={() => {
                              if (item.item) {
                                item.item = null;
                              }
                              clearTimeout(timerNext);
                              setItem(itemS);
                              setIdItem(itemS.Id);
                              if (itemS.idType != 1) {
                                item.idType = 2;
                                if (isMobile) {
                                  setLink(itemS.movie_path);
                                } else {
                                  setLink(itemS.movie_path);
                                }
                                setMin(itemS.mini_path);
                                getItemSuggestions(currentUser.accessToken, itemS.Id).then(
                                  (response) => {
                                    let suggestion = [];
                                    for (let i = 0 ; i < 5 && i < response.data.length ; i++) {
                                      suggestion.push(response.data[i].Table_Item);
                                    }
                                    setSugg(suggestion);
                                  }
                                )
                                setIsSugg(false);
                                setTimeout(() => {
                                  playerRef.current.addEventListener('ended', fireOnVideoEnd);
                                })
                              } else {
                                item.idType = 1
                                getItemById(currentUser.accessToken, itemS.Id, currentUser.id).then(
                                  (response) => {
                                    const season = response.data[0].Table_Seasons;
                                    item.Table_Seasons = season;
                                    const episode = season.find(x => x.number == 1 && x.season == 1);
                                    if (episode) {
                                      setNumber(1);
                                      setSeason(1);
                                      setIdEpisode(episode.id);
                                      if (isMobile) {
                                        setLink(episode.episode_path);
                                      } else {
                                        setLink(episode.episode_path);
                                      }
                                      setMin(itemS.mini_path);
                                    }
                                    getItemSuggestions(currentUser.accessToken, itemS.Id).then(
                                      (response) => {
                                        let suggestion = [];
                                        for (let i = 0 ; i < 5 && i < response.data.length ; i++) {
                                          suggestion.push(response.data[i].Table_Item);
                                        }
                                        setSugg(suggestion);
                                      }
                                    )
                                    setIsSugg(false);
                                    setTimeout(() => {
                                      playerRef.current.addEventListener('ended', fireOnVideoEnd);
                                    })      
                                  }
                                )
                              }
                              if (userItems.findIndex(x => x.IdItem == itemS.Id && x.idMyBanner == 3) != -1) {
                                setFavorite(true);
                              } else {
                                setFavorite(false);
                              }
                            }}>
                <div style={{ display: "flex", position: "relative" }}>
                  <img src={encodeURI(itemS.mini_title_path + "?tr=w-400,h-225,dpr-" + window.devicePixelRatio.toString())} style={{ borderRadius: 12 }} width={400} height={225} alt={itemS.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,400,225,itemS.title))}} />
                  <h6 style={{ color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, marginBottom: 0, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: 0 }}>{itemS.genreLib}</h6>
                  {itemS.isExclusif == 1 && (itemS.isFree == 1 || currentUser.statut == 20) && <div style={{ width: "100%", maxWidth: 400, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 0, borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingBottom: 10 }}><img src={exclusif} style={{ height: 20, borderRadius: 0, marginTop: 5, marginRight: 5 }} alt="Exclusif" /></div>}
                  {itemS.isNewEpisode == 1 && <div style={{height: 25, background: "rgba(185,185,185,0.7)", position: "absolute", top: 0, display: "flex", alignItems: "center", borderTopLeftRadius: 12, borderBottomRightRadius: 12}}><h6 style={{margin: "0 10px", color: "#FFF", fontWeight: "normal"}}>Nouvel épisode</h6></div>}
                  {itemS.isFree != 1 && currentUser.statut != 20 && <div style={{color: "#ff6833", position: "absolute", top: 15, right: 10, display: "flex", alignItems: "center"}}><i class="fa-solid fa-lock fa-xl"></i></div>}
                </div>
              </Link> : <Link style={{ margin: 8, display: "flex", justifyContent: "center" }} to={{
                pathname: "/detail/" + itemS.Id,
                state: itemS
              }}>
                <div style={{ display: "flex", position: "relative" }}>
                  <img src={encodeURI(itemS.mini_title_path + "?tr=w-400,h-225,dpr-" + window.devicePixelRatio.toString())} style={{ borderRadius: 12 }} width={400} height={225} alt={itemS.title} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,400,225,itemS.title))}} />
                  <h6 style={{ color: "white", backgroundColor: "#ff6833", paddingLeft: 7, paddingRight: 7, paddingTop: 3, paddingBottom: 3, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 0, marginRight: 10, marginBottom: 0, fontSize: 15, fontWeight: 400, position: "absolute", whiteSpace: "nowrap", bottom: 0 }}>{itemS.genreLib}</h6>
                  {itemS.isExclusif == 1 && (itemS.isFree == 1 || currentUser.statut == 20) && <div style={{ width: "100%", maxWidth: 400, position: 'absolute', display: "flex", justifyContent: "flex-end", top: 0, borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingBottom: 10 }}><img src={exclusif} style={{ height: 20, borderRadius: 0, marginTop: 5, marginRight: 5 }} alt="Exclusif" /></div>}
                  {itemS.isNewEpisode == 1 && <div style={{height: 25, background: "rgba(185,185,185,0.7)", position: "absolute", top: 0, display: "flex", alignItems: "center", borderTopLeftRadius: 12, borderBottomRightRadius: 12}}><h6 style={{margin: "0 10px", color: "#FFF", fontWeight: "normal"}}>Nouvel épisode</h6></div>}
                  {itemS.isFree != 1 && currentUser.statut != 20 && <div style={{color: "#ff6833", position: "absolute", top: 15, right: 10, display: "flex", alignItems: "center"}}><i class="fa-solid fa-lock fa-xl"></i></div>}
                </div>
              </Link>}
            </GridListTile>
          ))}
        </Carousel>
        <h3 style={{ color: "#FFF", marginLeft: 30 }}>Lancement de {sugg[0].title} dans : {countDown}</h3>
         </div>
         </div>
         : (
           <div style={{ height: "100%", minHeight: height, display: "flex", backgroundImage: "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(" + encodeURI(min + "?tr=w-" + width + ",h-" + ((width*1080)/1920)) + ",dpr-" + window.devicePixelRatio.toString() + ")" }}>
            <Header
              color="transparent"
              routes={dashboardRoutes}
              brand="SOFA vod"
            
              fixed
              changeColorOnScroll={{
                height: 100,
                color: "#1B1B1B" 
              }}
              {...rest}
            />
            <div style={{ alignSelf: "center", margin: "auto", overflow: "hidden" }}>
              <h3 style={{ color: "#FFF", marginLeft: 5 }}>Merci de votre visionage.</h3>
            </div>
           </div>
         )
          } </div> : (
      <div>
          <div style={{ backgroundColor: "#000",}}>
    {//!isMobile && 
    <Header
      color="transparent"
      routes={dashboardRoutes}
      brand="SOFA vod"
     
      fixed
      changeColorOnScroll={{
        height: 100,
        color: "#1B1B1B" 
      }}
      {...rest}
    />
    }
    {item.item ? <div style={{ position: "absolute", top: 80, left: 75, zIndex: 10, display: "flex", alignItems: "flex-end" }}>
      <h4 style={{ color: "#FFF", margin: 0 }}>{item.item.title}</h4>
      {item.item.idType == 1 && <h5 style={{ color: "#FFF", margin: "0 10px" }}>Épisode {numberRef.current} Saison {seasonRef.current}</h5>}
    </div> : <div style={{ position: "absolute", top: 80, left: 75, zIndex: 10, display: "flex", alignItems: "flex-end" }}>
      <h4 style={{ color: "#FFF", margin: 0 }}>{item.title}</h4>
      {item.idType == 1 && <h5 style={{ color: "#FFF", margin: "0 10px" }}>Épisode {numberRef.current} Saison {seasonRef.current}</h5>}
    </div>}
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh', overflow: "hidden"}}>
     {/* <ReactVideo
  
                src={Link}
                autoPlay={true}
                poster={Poster}
                primaryColor="#ff600f"
            /> */}
          <ReactHlsPlayer
            playerRef={playerRef}
            autoPlay={true}
            width='100%'
            height="100%"
            // poster={item.mini_path}
            controls={true}
            idEpisode={idEpisode}
            idItem={idItem}
            user={currentUser}
            src={link}
            start={startTime}
          />
        </div>
      </div>
    </div>
    )}
    </div>
}
  </div>
);
          }
        
}


